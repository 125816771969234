import { RootStore } from './rootStore';
import { observable, action, computed, runInAction } from 'mobx';
import agent from '../api/agent';
import { history } from '../..';
import { toast } from 'react-toastify';
import { IJuanUser } from '../models/juanUser';
import { AllCategory } from '../common/constants/categories';

export default class JuanUserStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @observable loadingInitial = true;
    @observable submitting = false;
    @observable errorMessage: string = '';

    @observable bio: string = '';

    @observable juanUser: IJuanUser | null = null;
    @observable deleteErrorMessage: string ='';

    // list
    @observable juanUserRegistry = new Map();
    @observable juanUserCount = 0;
    @observable currentPage = 1;
    @observable totalPage = 1;
    @observable pageSize: number = 7;

    @computed get getJuanUsers() {
        if (this.juanUserRegistry.size >= 1) {
            return Array.from(this.juanUserRegistry.values());
        }
        return [];
    }
    
    @action setPageSize = (pageSize: number) => {
        this.pageSize = pageSize;
    }

    @action setCurrentPage = (page: number) => {
        this.currentPage = page;
    }

    //filter
    @observable category = AllCategory;

    @action setCategory = (category: string) => {
        this.category = category;
        this.currentPage = 1;
        this.juanUserRegistry.clear();
        this.loadJuanUsers(category);
    }
    


    @action createJuanUser = async (JuanUser: IJuanUser) => {
        this.submitting = true;
        try {
            await agent.JuanUsers.create(JuanUser);

            runInAction('creating JuanUser', () => {
                this.submitting = false;
                this.bio = '';
                toast.info(JuanUser.displayName + 'is created.');
                history.push(`/JuanUsers`);
            });

        } catch (error) {
            runInAction('create JuanUsers error ', () => {
                this.submitting = false;
                console.log(error);
                // this.errorMessage =error.statusText;
            });
            toast.error('Problem submitting data');
        }
    };

    @action editJuanUser = async (JuanUser: IJuanUser) => {
        this.submitting = true;
        try {
            await agent.JuanUsers.update(JuanUser);

            runInAction('editing JuanUser', () => {
                this.submitting = false;
            });
            history.push(`/JuanUsers`);
        } catch (error) {
            runInAction('create JuanUser error ', () => {
                this.submitting = false;
                console.log(error);
                // this.errorMessage =error.statusText;
            });
            toast.error('Problem submitting data');
        }
    };

    @action loadJuanUsers = async (role:string) => {
        try {
            const params = new URLSearchParams();
            params.append('currentPage', `${this.currentPage}`);
            params.append('pageSize', `${this.pageSize}`);
            params.append('role', role);

            let juanUsersResourceModel = await agent.JuanUsers.list(params);

            runInAction('loading juanUsers', () => {
                if (juanUsersResourceModel) {
                    this.juanUserRegistry.clear();
                    const { currentPage, count, totalPage, data } = juanUsersResourceModel;
                    data.forEach((juanUser: any) => {
                        this.juanUserRegistry.set(juanUser.id, juanUser);
                    });
                    this.currentPage = currentPage;
                    this.totalPage = totalPage;
                    this.juanUserCount = count;
                    this.loadingInitial = false;
                }
            });
        } catch (error) {
            runInAction('load juanUsers error', () => {
                this.loadingInitial = false;
            });
            throw error;
        }
    };

    @action loadJuanUser = async (id: string) => {
        try {
            let juanUser = await agent.JuanUsers.details(id);

            runInAction('loading juanUser', () => {
                this.loadingInitial = false;
                this.juanUser = juanUser;
                this.bio = juanUser.bio;
                this.errorMessage ='';
                this.deleteErrorMessage='';    
            });
            return juanUser;

        } catch (error) {
            runInAction('load juanUser error', () => {
                this.loadingInitial = false;
            });
            throw error;
        }
    };

    @action deleteById = async (id: string) => {
        try {
            await agent.JuanUsers.delete(id);
            runInAction('deleting juanUser', () => {
                this.juanUserRegistry.delete(id);
            });
        } catch (error) {
            runInAction('delete juanUser error', () => {
            });
            console.log(error);
        }
    };
}