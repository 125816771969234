import React, { Fragment } from 'react';
import JuanUserListItem from './JuanUserListItem';
import { IJuanUser } from '../../../app/models/juanUser';

const JuanUserList: React.FC<{ juanUsers : IJuanUser[] }> = ({juanUsers}) => {
    return (
        <Fragment>
            {juanUsers.map((juanUser) => {
                return <JuanUserListItem key={juanUser.id} juanUser={juanUser} />
            })}
        </Fragment>
    );
};

export default JuanUserList;