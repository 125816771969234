export const logoStyle = {
	border: '0px', backgroundColor: 'white', margin: '0px', padding: '0px'
};

export const logoStyleMobile = {
	backgroundColor: 'white'
};

export const menuItemStyle = {
	border: '0px', backgroundColor: 'white', fontSize: '15px', fontWeight: 'bold',
	borderStyle: 'none',
	borderBottom: '0px',
	borderColor: 'black',
};

export const activeMenuItemStyle = {
	border: '0px', backgroundColor: 'white', fontSize: '15px', fontWeight: 'bolder',
	borderBottom: '3px',
	borderStyle: 'solid',
	borderColor: '#6495ED',
};

export const subMenuItemStyle = {
	border: '0px',
	borderStyle: 'none',
	borderBottom: '0px',
	borderColor: 'black',
};

export const activeSubMenuItemStyle = {
	border: '0px',
	borderBottom: '2px',
	borderStyle: 'solid',
	borderColor: '#6495ED',
};