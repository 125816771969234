import React, { useContext } from 'react';
import { Item, Button, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { INewComer } from '../../../app/models/newComer';
import ConfirmationModal from '../../../app/common/modals/ConfirmationModal';
import {format} from 'date-fns';

interface IProps {
    newComer : INewComer,
    isMobile :boolean,
}

const NewComerListItem: React.FC<IProps> = ({ newComer, isMobile }) => {
    const rootStore = useContext(RootStoreContext);
    const { deleteById } = rootStore.newComerStore;
    const { openModal } = rootStore.modalStore;

    return (
        <Segment.Group>
            <Segment>
                <Item.Group>
                    <Item>
                        <Item.Content>
                            <Item.Header>
                                {newComer.name} - {format(new Date(newComer.date),'dd MMM yyyy')}
                            </Item.Header>
                            <Item.Description>                                 
                            </Item.Description>
                        </Item.Content>
                    </Item>
                </Item.Group>
            </Segment>
            <Segment clearing>
                <Button floated="right" content="Delete" color="red"
                    onClick={() => openModal(<ConfirmationModal
                        id={newComer.id}
                        subject={'Delete your NewComer'}
                        message={'Are you sure you want to delete it?'}
                        confirmById={deleteById}
                    />)}
                />
                <Button as={Link} to={ isMobile ? `/manageNewComerMobile/${newComer.id}` : `/manageNewComer/${newComer.id}`} 
                        floated="right" content="Edit" color="blue" />
                <Button as={Link} to={ isMobile ? `/createNewBelieverMobile/${newComer.id}` : `/createNewBeliever/${newComer.id}`} 
                        floated="right" content="Add 새가족" color="green" />
                <Button as={Link} to={ isMobile ? `/newComersMobile/${newComer.id}` : `/newComers/${newComer.id}`} 
                        floated="right" content="View" color="grey" />
            </Segment>
        </Segment.Group>
    );
};

export default NewComerListItem;