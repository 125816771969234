import React, { Fragment } from 'react';
import { Menu, Header } from 'semantic-ui-react';
import { getRoleText } from '../constants/roles';

interface IProps {
    menuItems : string[],
    selectMenuItem: (menuItem:string)=>void;
    currentCategory:string;
    name: string;
}

const ListFilter = ({menuItems, selectMenuItem, currentCategory, name}: IProps) => {
    return (
        <Fragment>
            <Menu vertical size={'large'} style={{ width: '100%', marginTop: 5 }}>
                <Header icon={'filter'} attached color={'blue'} content={name} />
                {   menuItems.map((menuItem)=>(
                        <Menu.Item key ={menuItem}
                            active= {menuItem === currentCategory }
                            onClick={() => selectMenuItem(menuItem)}
                            color={'blue'}
                            name={menuItem}
                            content={getRoleText(menuItem)!='' ? getRoleText(menuItem) : menuItem } />))
                }
            </Menu>
        </Fragment>
    );
};

export default ListFilter;
