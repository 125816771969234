import { IOrganisation } from "./organisation";

export interface IGallery {
    id: string;
    title: string;
    date: Date;
    description: string;
    organisationId:string;
    organisation: IOrganisation;
    photos:IGalleryPhoto[]
}

export interface IGalleryPhoto {
    id: string;
    title: string;
    url: string;
    publicId: string;
    order: number;
}

export class GalleryFormValues {
    id?: string = undefined;
    title: string = '';
    date?: Date = undefined;
    description: any = '';
    organisationId:string='';
}
 
export interface IGalleryResouceModel {
    data: IGallery[];
    count: number;
    currentPage: number;
    totalPage: number;
    pageSize: number;
}