import React, { Fragment, useContext, useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import { RootStoreContext } from '../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import { Pagination } from 'semantic-ui-react'
import BulletinTableItemPlaceHolder from './dashboard/BulletinTableItemPlaceHolder';
import { BulletinCategories } from '../../app/common/constants/categories';
import TwoColumnTable from '../../app/common/tables/TwoColumnTable';
import TwoColumnTableRowWithDownload from '../../app/common/tables/TwoColumnTableRowWithDownload';

const CityBulletinPage: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const { loadBulletins, currentPage, setCurrentPage, 
        totalPage, loadingInitial, getBulletinsByDate } = rootStore.bulletinStore;

    useEffect(() => {
        loadBulletins(BulletinCategories.City);
    }, []);

    const pageChangHandler = (event: React.MouseEvent<HTMLAnchorElement>, data: any) => {
        setCurrentPage(data.activePage);
        loadBulletins(BulletinCategories.City);
    }

    return (
        <Grid style={{ marginTop: '15px' }}>
            <Grid.Column width={16}>
                {loadingInitial ? <BulletinTableItemPlaceHolder />
                    : <Fragment>
                        <TwoColumnTable title="City 주보">
                                <Fragment>
                                    {getBulletinsByDate.map((bulletin) => {
                                        return <TwoColumnTableRowWithDownload id={bulletin.id}
                                                    title={bulletin.title}
                                                    link=''
                                                    filePath={bulletin.filePath}  />
                                    })}
                                </Fragment>
                        </TwoColumnTable>
                        <Pagination
                            firstItem={null}
                            lastItem={null}
                            activePage={currentPage} totalPages={totalPage}
                            onPageChange={pageChangHandler}
                        />
                    </Fragment>
                }
            </Grid.Column>
        </Grid>
    );
};

export default observer(CityBulletinPage);


