import React from 'react';
import { Tab, Grid, Header, List } from 'semantic-ui-react';

const WestRydeServiceTab = () => {

    return (
        <Tab.Pane style={{ backgroundColor: '#90EE90' }}>
            <Grid>
                <Grid.Column width={16}>
                    <Header content='주일예배 ' />
                    <List>
                        <List.Item><b>West Ryde 주안교회당</b></List.Item>
                        <List.Item>주일 1부 예배 오전 8시 30분</List.Item>
                        <List.Item>주일 2부 예배 오전 10시 00분</List.Item>
                        <List.Item>주일 3부 예배 오전 11시 30분</List.Item>
                    </List>
                </Grid.Column>
            </Grid>
        </Tab.Pane>
    );
};

export default WestRydeServiceTab;