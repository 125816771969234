import React, { useContext, useEffect, useState } from 'react';
import { Grid, Button } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { NavLink } from 'react-router-dom';
import GroupListItemPlaceholder from './GroupListItemPlaceholder';
import InfiniteScroll from 'react-infinite-scroller';
import GroupList from './GroupList';
import GroupFilters from './GroupFilters';

const GroupDashboard: React.FC = () => {
	const rootStore = useContext(RootStoreContext);
	const { loadGroups, loadingInitial, setPage, page, totalPages } = rootStore.groupStore;
	const [loadingNext, setLoadingNext] = useState(false);

	const handleGetNext = () => {
		setLoadingNext(true);
		setPage(page + 1);
		loadGroups().then(() => setLoadingNext(false));
	};

	useEffect(() => {
		loadGroups();
	}, [loadGroups]);

	return (
		<Grid>
			<Grid.Column width={10}>
				{loadingInitial ? (
					<GroupListItemPlaceholder />
				) : (
						<InfiniteScroll
							pageStart={0}
							loadMore={handleGetNext}
							hasMore={!loadingNext && page + 1 < totalPages}
							initialLoad={false}
						>
							<GroupList />
						</InfiniteScroll>
					)}
			</Grid.Column>
			<Grid.Column width={6}>
				<Button as={NavLink} to="/createGroup" primary content="목장 / 셀 만들기" />
				<GroupFilters />
			</Grid.Column>
		</Grid>
	);
};

export default observer(GroupDashboard);
