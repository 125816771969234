// reference : https://react.semantic-ui.com/modules/search/#types-standard
import React, {useRef, useEffect, useState} from 'react'
import { Search } from 'semantic-ui-react';

interface IProps {
    source:any[];
    intialSearchValue:string;
    forwardedRef:any;
    name : string; 
}

class SearchDropDownValues {
    title: string = '';    
}

const SearchField: React.FC<IProps>  = ({  source, intialSearchValue, forwardedRef, name }: IProps)=>{
  
    const timeoutRef = useRef<any>()

    const [loading, setLoading] = useState<boolean>(false);
    const [results, setResults] = useState<any[]>([]);
    const [searchValue, setSearchValue] = useState<string>(intialSearchValue);

    const cleanQuery = () => {
        setLoading(false);
        setSearchValue('');
    }

    const search = (value:string) => {
        setLoading(true);
        setSearchValue(value);
    }

    const updateQuery = (value:string) => {
        setSearchValue(value);
    }

    const finishQuery = (results:any) => {
        setLoading(false);
        setResults(results);
    }

    const handleSearchChange = React.useCallback((e: any, data: any) => {
        clearTimeout(timeoutRef.current);

        search(data.value);
        timeoutRef.current = setTimeout(() => {
        if (data.value.length === 0) {
            cleanQuery();
        return
        }        

            
        const results = source.filter(({ name }) => name.indexOf(data.value) > -1)
            .map((value, i) => { 
                var organisation = new SearchDropDownValues();
                organisation.title = value.name;
                return (organisation); });
            finishQuery(results);
        }, 300);
    }, []);

    useEffect(() => {
      return () => {        
        clearTimeout(timeoutRef.current);

        if (forwardedRef.current && intialSearchValue){
            forwardedRef.current.state.value = intialSearchValue
        }
      }
    }, [intialSearchValue])
  
    return (
        <Search
            ref= {forwardedRef}
            loading={loading}
            onResultSelect={(e, data) =>
                updateQuery(data.result.name)
            }
            onSearchChange={handleSearchChange}
            results={results}
            value ={searchValue}
            name ={name}
            />
        )
  }
  
  export default SearchField;
