import { useField } from 'formik';
import React from 'react';
import { Form, Label } from 'semantic-ui-react';
import {ReactDatePickerProps} from 'react-datepicker';
import { DateTimePicker } from 'react-widgets';

const DateInputField = (props: Partial<ReactDatePickerProps>) => {
    const [field, meta, helpers] = useField(props.name!)
    return (
        <Form.Field error ={meta.touched && !!meta.error}>
            <DateTimePicker
                value = {field.value}
                onChange = {value =>helpers.setValue(value)}
                placeholder={props.placeholderText}
                date={true} time ={false} format='dd MMM yyyy' />
            {meta.touched && meta.error ? (
                <Label basic color='red'>{meta.error}</Label>
            ) : null}
        </Form.Field>
    )
}
//onKeyDown={(e) => e.preventDefault()}


export default DateInputField;

// !! means cast it to boolean.

            /* <DatePicker
                {...field}
                {...props}
                selected={(field.value && new Date(field.value))|| null}
                onChange = {value =>helpers.setValue(value)}
            /> */

