import React from "react";
import { Table } from "semantic-ui-react";

interface IProps {
    name: string,
    date: string,
    place: string
}

const TableRow3: React.FC<IProps> = ({ name, date, place }) => {

    return (
        <Table.Row>
            <Table.Cell width={4}>{name}</Table.Cell>
            <Table.Cell width={5}>{date}</Table.Cell>
            <Table.Cell width={7}>{place}</Table.Cell>
        </Table.Row>
    );
}

export default TableRow3;