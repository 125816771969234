
import { IVideo } from '../models/video';
import { RootStore } from './rootStore';
import { observable, action, computed, runInAction } from 'mobx';
import agent from '../api/agent';
import { history } from '../..';
import { toast } from 'react-toastify';


export default class VideoStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @observable video: IVideo | null = null;
    @observable videoRegistry = new Map();
    @observable videoCount = 0;

    @observable submitting = false;
    @observable loadingInitial = true;
    @observable category = 'All';

    @observable currentPage = 1;
    @observable totalPage = 1;
    @observable pageSize: number = 7;

    @action setPageSize = (pageSize: number) => {
        this.pageSize = pageSize;
    }

    @action setCurrentPage = (page: number) => {
        this.currentPage = page;
    }

    @action setCategory = (category: string) => {
        this.category = category;
        this.currentPage = 1;
        this.videoRegistry.clear();
        this.loadVideos();
    }

    @computed get axiosParams() {
        const params = new URLSearchParams();
        params.append('currentPage', `${this.currentPage}`);
        params.append('pageSize', `${this.pageSize}`);
        params.append('category', this.category);
        return params;
    }

    @computed get getVideo() {
        return Array.from(this.videoRegistry.values());
    }

    @computed get getVideosByDate() {
        if (this.videoRegistry.size >= 1) {
            return Array.from(this.videoRegistry.values()).sort(video => video.date);
        }
        return [];
    }

    @action loadVideos = async () => {
        try {
            let videosResourceModel = await agent.Videos.list(this.axiosParams);

            runInAction('loading videos', () => {
                if (videosResourceModel) {
                    this.videoRegistry.clear();
                    const { currentPage, count, totalPage, data } = videosResourceModel;
                    data.forEach((video: any) => {
                        this.videoRegistry.set(video.id, video);
                    });
                    this.currentPage = currentPage;
                    this.totalPage = totalPage;
                    this.videoCount = count;
                    this.loadingInitial = false;
                }
            });
        } catch (error) {
            runInAction('load videos error', () => {
                this.loadingInitial = false;
            });
            throw error;
        }
    };

    @action createVideo = async (video: IVideo) => {
        this.submitting = true;
        try {
            await agent.Videos.create(video);

            runInAction('creating video', () => {
                this.videoRegistry.set(video.id, video);
                this.submitting = false;
            });
            history.push(`/videos`);
        } catch (error) {
            runInAction('create videos error ', () => {
                this.submitting = false;
            });
            toast.error('Problem submitting data');
        }
    };

    @action editVideo = async (video: IVideo) => {
        this.submitting = true;
        try {
            await agent.Videos.update(video);

            runInAction('editing video', () => {
                this.videoRegistry.set(video.id, video);
                this.submitting = false;
            });
            history.push(`/videos`);
        } catch (error) {
            runInAction('create activities error ', () => {
                this.submitting = false;
            });
            toast.error('Problem submitting data');
        }
    };

    @action loadVideo = async (id: string) => {
        try {
            let video = await agent.Videos.details(id);
            runInAction('getting video', () => {
                video.date = new Date(video.date);
                this.videoRegistry.set(video.id, video);

                this.loadingInitial = false;
                this.video = video;
            });
            return video;
        } catch (error) {
            runInAction('getting video error', () => {
                this.loadingInitial = false;
            });
            console.log(error);
        }
    };

    @action deleteById = async (id: string) => {
        try {
            await agent.Videos.delete(id);
            runInAction('deleting video', () => {
                this.videoRegistry.delete(id);
            });
        } catch (error) {
            runInAction('delete video error', () => {
            });
            console.log(error);
        }
    };
}