export interface IGroup {
    id: string;
    title: string;
    description: string;
    category: string;
    members: IMember[];
    isMember: boolean;
}

export interface IGroupResouceModel {
    groups: IGroup[];
    groupCount: number;
}

export interface IMember {
    userName: string;
    displayName: string;
    image: string;
}

export class GroupFormValues {
    id?: string = undefined;
    title: string = '';
    category: string = '';
    description: string = '';
}

