import { useField } from 'formik';
import React from 'react';
import { Form, Label } from 'semantic-ui-react';

interface IProps {
    placeholder:string;
    name:string;
    label?:string;
    type:string;
}

const TextAreaField = (props: IProps) => {
    const [field, meta] = useField(props.name)
    return (
        <Form.Field error ={meta.touched && !!meta.error}>
            <label>{props.label}</label>
            <textarea rows={12}   {...field} {...props}/>
            {meta.touched && meta.error ? (
                <Label basic color='red'>{meta.error}</Label>
            ) : null}
        </Form.Field>
    )
}

export default TextAreaField;

// !! means cast it to boolean.