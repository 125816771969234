import { RootStore } from './rootStore';
import { observable, action, computed, runInAction } from 'mobx';
import agent from '../api/agent';
import { history } from '../..';
import { toast } from 'react-toastify';
import { IGallery, IGalleryPhoto } from '../models/gallery';

export default class GalleryStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    // create, edit
    @observable optionsRegistry = new Map();
    @observable loadingInitial = true;
    @observable submitting = false;
    @observable errorMessage: string = '';
    @observable gallery: IGallery | null = null;

    @observable deleteErrorMessage: string ='';
    // 
    @observable uploadingGalleryPhoto: boolean =false;
    @observable galleryPhotoRegistry = new Map();

    // list
    @observable galleryRegistry = new Map();
    @observable galleryCount = 0;
    @observable currentPage = 1;
    @observable totalPage = 1;
    @observable pageSize: number = 7;

    @computed get getGalleries() {
        if (this.galleryRegistry.size >= 1) {
            return Array.from(this.galleryRegistry.values());
        }
        return [];
    }

    @computed get getGalleryPhotos() {
        let galleryPhotos = Array.from(this.galleryPhotoRegistry.values());
        return galleryPhotos.sort(x => x.order);
    }

    @action setPageSize = (pageSize: number) => {
        this.pageSize = pageSize;
    }

    @action setCurrentPage = (page: number) => {
        this.currentPage = page;
    }

    @action initGallery = () => {
        this.galleryRegistry.clear();
    }
    
    @action createGallery = async (gallery: IGallery) => {
        this.submitting = true;
        try {
            await agent.Galleries.create(gallery);

            runInAction('creating gallery', () => {
                this.submitting = false;
                toast.info(gallery.title + 'is created.');
                history.push(`/galleryDashboard`);
            });

        } catch (error) {
            runInAction('create gallerys error ', () => {
                this.submitting = false;
                console.log(error);
                // this.errorMessage =error.statusText;
            });
            toast.error('Problem submitting data');
        }
    };

    @action editGallery = async (gallery: IGallery) => {
        this.submitting = true;
        try {
            await agent.Galleries.update(gallery);

            runInAction('editing gallery', () => {
                this.submitting = false;
            });
            history.push(`/galleryDashboard`);
        } catch (error) {
            runInAction('create gallery error ', () => {
                this.submitting = false;
                console.log(error);
                // this.errorMessage =error.statusText;
            });
            toast.error('Problem submitting data');
        }
    };

    @action loadGalleries = async (organisationId:string, sortOrder:string, search:string) => {
        try {
            const params = new URLSearchParams();
            params.append('currentPage', `${this.currentPage}`);
            params.append('pageSize', `${this.pageSize}`);
            params.append('sortOrder', sortOrder);
            params.append('search', search);
        
            if (organisationId)
                params.append('organisationId', organisationId);

            let gallerysResourceModel = await agent.Galleries.list(params);

            runInAction('loading gallerys', () => {
                if (gallerysResourceModel) {
                    this.galleryRegistry.clear();
                    const { currentPage, count, totalPage, data } = gallerysResourceModel;
                    data.forEach((gallery: any) => {
                        this.galleryRegistry.set(gallery.id, gallery);
                    });
                    this.currentPage = currentPage;
                    this.totalPage = totalPage;
                    this.galleryCount = count;
                    this.loadingInitial = false;
                }
            });
        } catch (error) {
            runInAction('load gallerys error', () => {
                this.loadingInitial = false;
            });
            throw error;
        }
    };

    @action loadGallery = async (id: string, includePhoto: boolean = false) => {
        try {
            let gallery = await agent.Galleries.details(id, includePhoto);

            runInAction('loading gallery', () => {
                this.loadingInitial = false;
                this.gallery = gallery;
                if (includePhoto){
                    this.galleryPhotoRegistry.clear();
                    gallery.photos.forEach((photo: any) => {
                        this.galleryPhotoRegistry.set(photo.id, photo);
                    });
                }
                this.errorMessage ='';
                this.deleteErrorMessage='';    
            });
            return gallery;

        } catch (error) {
            runInAction('load gallery error', () => {
                this.loadingInitial = false;
            });
            throw error;
        }
    };

    @action uploadPhoto = async (formData: FormData) => {
        this.uploadingGalleryPhoto = true;
        try {
            const photo = await agent.Galleries.uploadPhoto(formData);
            runInAction(() => {
                this.uploadingGalleryPhoto = false;
                this.galleryPhotoRegistry.set(photo.id, photo);
                toast.success('A photo is added.')
            });
        } catch (error) {
            console.log(error);
            toast.error('Problem uploading gallery photo');
            runInAction(() => {
                this.uploadingGalleryPhoto = false;
            });
        }
    };

    @action deletePhoto = async (photo: IGalleryPhoto) => {
        this.uploadingGalleryPhoto = true;
        try {
            await agent.Galleries.deletePhoto(photo.id);
            runInAction(() => {
                this.galleryPhotoRegistry.delete(photo.id);
                this.uploadingGalleryPhoto = false;
            });
        } catch (error) {
            toast.error('Problem deleting the photo');
            runInAction(() => {
                this.uploadingGalleryPhoto = false;
            });
        }
    };

    @action deleteById = async (id: string) => {
        try {
            await agent.Galleries.delete(id);
            runInAction('deleting gallery', () => {
                this.galleryRegistry.delete(id);
            });
        } catch (error) {
            runInAction('delete gallery error', () => {
            });
            console.log(error);
        }
    };
}