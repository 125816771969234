import React, { useState, useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { RouteComponentProps } from 'react-router-dom';
import { Segment, Header, Button, Grid } from 'semantic-ui-react';
import { v4 as uuid } from 'uuid';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import TextInputField from '../../../app/common/formikForm/TextInputField';
import SelectInputField from '../../../app/common/formikForm/SelectInputField';
import { JuanUserFormValues } from '../../../app/models/juanUser';
import * as Roles from '../../../app/common/constants/roles';
import TextAreaField from '../../../app/common/formikForm/TextAreaField';

const validationSchema = Yup.object({
    displayName:Yup.string().required('name is required').max(100, 'max length is 100 characters'),
    role:Yup.string().required('role is required')
})

interface iFormParams {
    id: string;
}

const roleOptions = [
    {  text: Roles.getRoleText(Roles.ROLE_MEMBER), value:Roles.ROLE_MEMBER },
    {  text: Roles.getRoleText(Roles.ROLE_ADMIN), value:Roles.ROLE_ADMIN },
    {  text: Roles.getRoleText(Roles.ROLE_APP_MANAGER), value:Roles.ROLE_APP_MANAGER },
    {  text: Roles.getRoleText(Roles.NEW_BELIEVER_MANAGER), value:Roles.NEW_BELIEVER_MANAGER },
];

const JuanUserForm: React.FC<RouteComponentProps<iFormParams>> = ({ match, history }) => {

    const rootStore = useContext(RootStoreContext);
 
    const { createJuanUser, editJuanUser, loadJuanUser, submitting, bio } = rootStore.juanUserStore;
    
    const [juanUser, setJuanUser] = useState(new JuanUserFormValues());

    useEffect(()=>{
        if (match.params.id) {
            loadJuanUser(match.params.id)
                .then((juanUser) => {
                    setJuanUser(juanUser);
                })
                .finally();
        }
    },[match.params.id,loadJuanUser]);

    const handleFormSubmit =(juanUser:any)=>{

        if (!juanUser.id) {
            juanUser.id = uuid();
            createJuanUser(juanUser);
        } else {
            editJuanUser(juanUser);
        }
    }

    return (<Grid style={{ marginTop: '15px' }}>
        <Grid.Column width={10}>
            <Segment clearing>
                    <Formik enableReinitialize validationSchema = {validationSchema}
                    onSubmit={values=> handleFormSubmit(values)}
                    initialValues={juanUser}>
                    {( { handleSubmit, isValid, isSubmitting, dirty})=>(
                        <Form className='ui form' onSubmit={handleSubmit} autoComplete ='off'>
                            <TextInputField type='text' name="displayName" placeholder="이름"/>                               
                            <SelectInputField options={roleOptions} name='role' placeholder='역할' />
                            <TextAreaField type='textarea' name="description" placeholder=""/>                            

                                <Button
                                    floated="right"
                                    loading={submitting}
                                    disabled={!isValid || isSubmitting || !dirty}
                                    positive
                                    type="submit"
                                    content="Save" />
                                <Button
                                    onClick={() => history.push('/juanUsers')}
                                    floated="right"
                                    type="button"
                                    content="Cancel" />
                        </Form>
                    )}
                </Formik>
            </Segment>
        </Grid.Column>
        <Grid.Column width={6}>
                {
                    bio && (
                        <Header as='h2'>
                            간략한 소개글
                        <Header.Subheader>
                          {bio}
                        </Header.Subheader>
                      </Header>)
                }
        </Grid.Column>
    </Grid>);
};

export default observer(JuanUserForm);


// <TextInputField name="phoneNumber" placeholder="전화번호"/>                               
//<DateInputField placeholderText='생일' name ='birthday'                                 
//    showTimeSelect={false} timeCaption='time' 
//    dateFormat='dd MMM yyyy' /> 
