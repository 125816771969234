import React, { Fragment, useContext, useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import { RootStoreContext } from '../../../app/stores/rootStore';
import VideoList from './VideoList';
import { observer } from 'mobx-react-lite';
import { Pagination } from 'semantic-ui-react'
import VideoListItemPlaceHolder from './VideoListItemPlaceHolder';
import VideoFilters from './VideoFilters';

const VideoDashboard: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const { loadVideos, currentPage, setCurrentPage, totalPage, loadingInitial, getVideosByDate } = rootStore.videoStore;

    useEffect(() => {
        loadVideos();
    }, []);

    const pageChangHandler = (event: React.MouseEvent<HTMLAnchorElement>, data: any) => {
        setCurrentPage(data.activePage);
        loadVideos();
    }

    return (
        <Grid style={{ marginTop: '15px' }}>
            <Grid.Column width={10}>
                {
                    loadingInitial ? <VideoListItemPlaceHolder /> :
                        (
                            <Fragment>
                                <VideoList videos ={getVideosByDate} />
                                <Pagination
                                    firstItem={null}
                                    lastItem={null}
                                    activePage={currentPage} totalPages={totalPage}
                                    onPageChange={pageChangHandler}
                                />
                            </Fragment>
                        )
                }
            </Grid.Column>
            <Grid.Column width={6}>
                <VideoFilters />
            </Grid.Column>
        </Grid>
    );
};

export default observer(VideoDashboard);


