import React, { Fragment, useContext, useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import { RootStoreContext } from '../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import { Pagination } from 'semantic-ui-react'
import AnnouncementTable from './AnnouncementTable';
import BulletinTableItemPlaceHolder from './dashboard/BulletinTableItemPlaceHolder';

const AnnouncementPage: React.FC = () => {
    const bulletinType = 'announcement';
    const rootStore = useContext(RootStoreContext);
    const { loadBulletins, currentPage, setPageSize, setCurrentPage, totalPage, loadingInitial } = rootStore.bulletinStore;

    useEffect(() => {
        setPageSize(10);
        loadBulletins(bulletinType);
    }, []);

    const pageChangHandler = (event: React.MouseEvent<HTMLAnchorElement>, data: any) => {
        setPageSize(10);
        setCurrentPage(data.activePage);
        loadBulletins(bulletinType);
    }

    return (
        <Grid style={{ marginTop: '15px' }}>
            <Grid.Column width={16}>
                {loadingInitial ? <BulletinTableItemPlaceHolder />
                    : <Fragment>
                        <AnnouncementTable />
                        <Pagination
                            firstItem={null}
                            lastItem={null}
                            defaultActivePage={currentPage} totalPages={totalPage}
                            onPageChange={pageChangHandler}
                        />
                    </Fragment>
                }


            </Grid.Column>
        </Grid>
    );
};

export default observer(AnnouncementPage);


