
const windowHeight = window.innerHeight*0.8 + 'px';

export const tree = {
	border: '1px solid',
    padding: '0.01em 16pm',
    borderRadius: '16px',
    borderColor:'lightblue',
    paddingTop:'10px',
    paddingLeft:'10px',
    overflowY:'auto',
    display: 'block',
    height:windowHeight
};

export const plusMinusIcon ={
    paddingLeft: '50px'
}
