import React, { Fragment } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Menu, Dropdown } from 'semantic-ui-react';
import * as Styles from '../styles'
import logo from '../../../assets/images/logo.png';

interface IProp {
    pathName:string,
    children:JSX.Element,
    menuItemClick: ()=>void;
}

const NewComerManagerMobile = ({pathName, menuItemClick, children}:IProp) => {
    return (
        <Fragment>
            <Menu.Item header as={NavLink} exact to="/" style={Styles.logoStyleMobile} onClick={menuItemClick} >
                <img src={logo} alt="logo" style={{ margin: '0px', width: '110px' }} />
            </Menu.Item>

            <Menu.Item name="새가족 목록" as={NavLink} to="/newBelieverCards" onClick={menuItemClick}
                style={pathName === '/newBelieverCards' ? Styles.activeMenuItemStyle : Styles.menuItemStyle} />

            <Menu.Item name="새가족방 목록" as={NavLink} to="/newComersMobile" onClick={menuItemClick}
                style={(pathName.startsWith('/newComersMobile')
                        || pathName.startsWith('/manageNewComerMobile')
                        || pathName.startsWith('/createNewBeliever')
                        || pathName.startsWith('/manageNewBeliever')                
                )? Styles.activeMenuItemStyle : Styles.menuItemStyle} />

            <Menu.Item name="새가족방 만들기" as={NavLink} to="/createNewComerMobile" onClick={menuItemClick}
                style={pathName === '/createNewComerMobile' ? Styles.activeMenuItemStyle : Styles.menuItemStyle} />
        {children}
    </Fragment>
    );
};

export default NewComerManagerMobile;