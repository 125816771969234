import React, { Fragment, useContext } from "react";
import { Button, Modal } from 'semantic-ui-react'
import { RootStoreContext } from "../../../app/stores/rootStore";

interface IProps {
    id: string;
    subject: string;
    message: string;
    confirmById: (id: any) => void;
}

const ConfirmationModal: React.FC<IProps> = ({ id, subject, message, confirmById }) => {
    const rootStore = useContext(RootStoreContext);
    const { closeModal } = rootStore.modalStore;

    return (
        <Fragment>
            <Modal.Header>{subject}</Modal.Header>
            <Modal.Content>
                <p>{message}</p>
            </Modal.Content>
            <Modal.Actions>
                <Button positive onClick={() => {
                    confirmById(id);
                    closeModal();
                }}>Yes</Button>
                <Button onClick={() => closeModal()}>No</Button>
            </Modal.Actions>
        </Fragment>
    );
};

export default ConfirmationModal;