import React from 'react';
import { Segment, Grid } from 'semantic-ui-react';
import { Carousel } from 'react-responsive-carousel';
import { MainBanners } from '../../app/data/mainBanners';

// const activityImageStyle = {
//     filter: 'brightness(90%)'
// };

const HomeHeaderOrg = () => {
    return (
        <Grid>
            <Grid.Column>
                <Segment.Group>
                    <Segment basic attached="top" style={{ padding: '0' }}>
                        <Carousel showThumbs={false} showStatus={false} showArrows={false}
                            showIndicators={false} autoPlay={true} interval={7000}>
                            {MainBanners.map((banner) => (
                                <div key={banner.id}>
                                    <img src={banner.url} alt="bannerImage" />
                                </div>))}
                        </Carousel>
                    </Segment>
                </Segment.Group>
            </Grid.Column>
        </Grid >
    );
};

export default HomeHeaderOrg;