export const CitySundayServices = [
    { id: 'city1', name: '주일 1부 예배', date: '오전 11시 30분', place: '' },
    { id: 'city2', name: '주일 2부 예배', date: '오후 2시 30분', place: 'Scots Presbyterian Church' },
    { id: 'city3', name: '주일 3부 예배', date: '오후 6시 30분', place: 'Scots Presbyterian Church' }
]

export const CityEarlyChildhoodServices = [
    { id: 'city4', name: '유아부 / 아동부 ', date: '오후 2시 30분', place: '' },
    { id: 'city6', name: '한글학교', date: '오후 3시 50분,', place: '' }
]

export const CityPrayServices = [
    { id: 'city8', name: '새벽예배', date: '월요일 부터 토요일	오전 7시', 
    place: 'West Ryde 예배당 본당 1부 : 5:30 예배 - 오프라인, 2부 7시 예배 -  ZoomID: 465 471 4497 Password: 463412 https://us04web.zoom.us/j/4654714497' }
]

export const CityThursdayServices = [
    { id: 'city9', name: '목요찬양 예배', date: '매주 목요일 오후 7시', place: 'West Ryde 예배당 본당' }
]

export const WestRydeSundayServices = [
    { id: 'westRyde1', name: '주일 1부 예배', date: '오전 8시 30분', place: 'West Ryde 예배당 본당' },
    { id: 'westRyde2', name: '주일 2부 예배', date: '오전 10시 00분', place: 'West Ryde 예배당 본당' },
    { id: 'westRyde3', name: '주일 3부 예배', date: '오전 11시 30분', place: 'West Ryde 예배당 본당' },
    { id: 'westRyde6', name: '유아부', date: '주일 오전 11시 30분', place: '유아부실' },
    { id: 'westRyde7', name: '아동부', date: '주일 오전 11시 30분', place: '아동부실' },
    { id: 'westRyde8', name: '학생부', date: '주일 오전 11시 30분', place: '학생부실' }
]

export const WestRydeYouthAdultServices = [
    { id: 'westRyde9', name: '주일청년예배', date: '오후 2시 40분', place: '예배실' }
]

export const WestRydeThursdayServices = [
    { id: 'westRyde12', name: '목요찬양 예배', date: '매주 목요일 오후 7시', place: 'West Ryde 예배당 본당' }
]

export const ChatswoodSundayServices = [
    { id: 'chat1', name: '주일예배', date: '주일 오전 11시', place: 'Youth Centre' }
]

export const ChatswoodOvernightServices = [
    { id: 'chat2', name: '금요심야기도회', date: '금요일 오후 10시', place: 'Youth Centre' }
]

export const ChatswoodEarlyMorningServices = [
    { id: 'chat3', name: '새벽기도회', date: '월~금(오전 5시 30분)', place: 'CJC 선교센터' }
]


