import React, { useContext } from 'react';
import { Button, Segment, Item, Label } from 'semantic-ui-react';
import { RootStoreContext } from '../../app/stores/rootStore';
import { IApproval } from '../../app/models/approval';
import { ReportStatus } from '../../app/common/constants/reportStatus';

const ApprovalListItem: React.FC<{ approval: IApproval }> = ({ approval }) => {
    const rootStore = useContext(RootStoreContext);
    const { approve, decline, approveLoading, declineLoading } = rootStore.approvalStore;

    return (
        <Segment.Group>
            <Segment>
                <Item.Group>
                    <Item>
                        <Item.Image size="tiny"
                            circular
                            src={approval.reporterImage || '/assets/user.png'}
                            style={{ marginBottom: 3 }} />
                        <Item.Content>
                            <Item.Header>
                                {approval.status === ReportStatus.Pending ?
                                    approval.reportedDate : approval.approvedDate}
                            </Item.Header>
                            <Item.Description>
                                {approval.title}
                            </Item.Description>
                            <Item.Description>
                                reporter : {approval.reporterDisplayName}
                            </Item.Description>
                            <Item.Description>
                                {approval.description}
                            </Item.Description>
                            <Item.Description>
                                <Label basic color="blue" content={approval.status} />
                            </Item.Description>
                        </Item.Content>
                    </Item>
                </Item.Group>
            </Segment>
            <Segment clearing>
                <div className='ui two buttons'>
                    <Button basic color='green'
                        disabled={approveLoading}
                        onClick={() => approve(approval.id)}
                    >Approve</Button>
                    <Button basic color='red'
                        disabled={declineLoading}
                        onClick={() => decline(approval.id)}
                    >Decline</Button>
                </div>
            </Segment>
        </Segment.Group>
    );
}

export default ApprovalListItem;