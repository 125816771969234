import React from 'react';
import { Item, Button, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { IGroup } from '../../../app/models/group';

const GroupListItem: React.FC<{ group: IGroup }> = ({ group }) => {
    return (
        <Segment.Group>
            <Segment>
                <Item.Group>
                    <Item>
                        <Item.Image
                            size="tiny"
                            circular
                            src='/assets/icons8group80.png'
                            style={{ marginBottom: 3 }} />
                        <Item.Content>
                            <Item.Header as={Link} to={`/groups/${group.id}`}>
                                {group.title}
                            </Item.Header>
                            <Item.Description>
                                {group.description}
                            </Item.Description>
                        </Item.Content>
                    </Item>
                </Item.Group>
            </Segment>
            <Segment clearing>
                <Button as={Link} to={`/groups/${group.id}`} floated="right" content="View" color="blue" />
                {/* <Button floated="right" content="Delete" color="red"
                    onClick={() => openModal(<ModalMessage
                        id={activity.id}
                        subject={'Delete your Activity'}
                        message={'Are you sure you want to delete your Activity'}
                    />)}
                /> */}
            </Segment>
        </Segment.Group>
    );
};

export default GroupListItem;