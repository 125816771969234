import React, { Fragment } from 'react'
import { Pagination, Segment } from 'semantic-ui-react'
import TwoColumnEditTableRow from '../../../app/common/tables/TwoColumnEditTableRow';
import TwoColumnTable from '../../../app/common/tables/TwoColumnTable';

interface IProps {
    galleries : any[],
    currentPage:number,
    totalPage:number,
    pageChangHandler:(event: React.MouseEvent<HTMLAnchorElement>, data: any) =>void
};

const GalleryTreeDetail:React.FC<IProps> = ({galleries, currentPage, totalPage, pageChangHandler }:IProps) => {

    return(
        <Segment.Group>
            <Segment clearing>
                <TwoColumnTable title='제목'>
                    <Fragment>
                        {galleries.map((gallery) => {
                            return <TwoColumnEditTableRow key={gallery.id}
                                        id={gallery.id}
                                        title={gallery.title}
                                        link={`/gallery/${gallery.id}`}
                                        editLink={`/manageGalleryPhoto/${gallery.id}`}
                                        editLinkText='Edit Photo'
                                        date={gallery.date} />
                        })}
                    </Fragment>
                </TwoColumnTable>
                <Pagination
                    firstItem={null}
                    lastItem={null}
                    defaultActivePage={currentPage} totalPages={totalPage}
                    onPageChange={pageChangHandler}
                />
            </Segment>
        </Segment.Group>
    )
}

export default GalleryTreeDetail;


//  <Statistic.Group width={2}>
// <Statistic label='사진 ' value ={} size='small' />
// <Statistic label='기도 ' value ={} size='small'/>
// </Statistic.Group> 

