import React, { Fragment } from 'react';
import HelpListItem from './HelpListItem';
import { IHelp } from '../../../app/models/help';

const HelpList: React.FC<{ helps : IHelp[] }> = ({helps}) => {
    return (
        <Fragment>
            {helps.map((help) => {
                return <HelpListItem key={help.id} help={help} />
            })}
        </Fragment>
    );
};

export default HelpList;