import React from "react";
import { Link } from "react-router-dom";
import { Button, Table } from "semantic-ui-react";
import { format } from 'date-fns';

interface IProps {
    id : string;
    title: string
    date: string,
    link:string,
    editLink:string, 
    editLinkText:string
}

const TwoColumnEditTableRow: React.FC<IProps> = ({ id, title, date, link, editLink, editLinkText }) => {
    return (
        <Table.Row key={id}>
            <Table.Cell width={12}>
                <Link to={link}>{title}</Link>  - {format(new Date(date),'dd MMM yyyy')}
            </Table.Cell>
            <Table.Cell width={4}>           
                <Button as={Link} to={editLink} floated="right" content={editLinkText} color="blue" />
            </Table.Cell>
        </Table.Row>
    );
}

export default TwoColumnEditTableRow;