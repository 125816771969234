
import { IBulletin, IBulletinPhoto } from '../models/bulletin';
import { RootStore } from './rootStore';
import { observable, action, computed, runInAction } from 'mobx';
import agent from '../api/agent';
import { history } from '../..';
import { toast } from 'react-toastify';
import { AllCategory } from '../common/constants/categories';

export default class BulletinStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @observable bulletin: IBulletin | null = null;
    @observable bulletinRegistry = new Map();
    @observable bulletinCount = 0;

    @observable submitting = false;
    @observable loadingInitial = true;
    @observable category = AllCategory;

    @observable currentPage = 1;
    @observable totalPage = 1;
    @observable pageSize: number = 7;

    //
    @observable bulletinPhoto: IBulletinPhoto | null = null;
    @observable bulletinPhotoRegistry = new Map();
    @observable uploadingBulletinPhoto = false;

    @action setPageSize = (pageSize: number) => {
        this.pageSize = pageSize;
    }

    @action setCurrentPage = (page: number) => {
        this.currentPage = page;
    }

    @action setCategory = (category: string) => {
        this.category = category;
        this.currentPage = 1;
        this.bulletinRegistry.clear();
        this.loadBulletins(category);
    }

    @computed get axiosParams() {
        const params = new URLSearchParams();
        params.append('currentPage', `${this.currentPage}`);
        params.append('pageSize', `${this.pageSize}`);
        params.append('category', this.category);
        return params;
    }

    @computed get getBulletinPhotos() {
        let bulletinPhotos = Array.from(this.bulletinPhotoRegistry.values());
        return bulletinPhotos.sort(x => x.order);
    }

    @computed get getBulletin() {
        return Array.from(this.bulletinRegistry.values());
    }

    @computed get getBulletinsByDate() {
        if (this.bulletinRegistry.size >= 1) {
            return Array.from(this.bulletinRegistry.values()).sort(bulletin => bulletin.date);
              // alternative sort .sort((a,b)=>Date.parse(a.date) - Date.parse(b.date))
        }
        return [];
    }

    @action loadBulletins = async (category: string) => {
        try {
            const params = new URLSearchParams();
            params.append('currentPage', `${this.currentPage}`);
            params.append('pageSize', `${this.pageSize}`);
            params.append('category', category);
            let bulletinsResourceModel = await agent.Bulletins.list(params);

            runInAction('loading bulletins', () => {
                if (bulletinsResourceModel) {
                    this.bulletinRegistry.clear();
                    const { currentPage, count, totalPage, data } = bulletinsResourceModel;
                    data.forEach((bulletin: any) => {
                        this.bulletinRegistry.set(bulletin.id, bulletin);
                    });
                    this.currentPage = currentPage;
                    this.totalPage = totalPage;
                    this.bulletinCount = count;
                    this.loadingInitial = false;
                }
            });
        } catch (error) {
            runInAction('load bulletins error', () => {
                this.loadingInitial = false;
            });
            throw error;
        }
    };

    @action createBulletin = async (formData: FormData) => {
        this.submitting = true;
        try {
            var bulletin = await agent.Bulletins.create(formData);

            runInAction('creating bulletin', () => {
                this.bulletinRegistry.set(bulletin.id, bulletin);
                this.submitting = false;
                toast.info(bulletin.title + 'is created. Please add a photo');
                history.push(`/bulletins`);
            });
        } catch (error) {
            runInAction('Problem submitting data ', () => {
                this.submitting = false;
            });
            toast.error('Problem submitting data');
        }
    };

    @action editBulletin = async (formData: FormData, id: string) => {
        this.submitting = true;
        try {
            var bulletin = await agent.Bulletins.update(formData, id);

            runInAction('editing bulletin', () => {
                this.bulletinRegistry.set(bulletin.id, bulletin);
                this.submitting = false;
            });
            history.push(`/bulletins`);
        } catch (error) {
            runInAction('Problem submitting data', () => {
                this.submitting = false;
            });
            toast.error('Problem submitting data');
        }
    };

    @action loadBulletin = async (id: string, includePhoto: boolean = false) => {
        try {
            let bulletin = await agent.Bulletins.details(id, includePhoto);
            runInAction('getting bulletin', () => {
                bulletin.date = new Date(bulletin.date);
                this.bulletinRegistry.set(bulletin.id, bulletin);

                this.bulletinPhotoRegistry.clear();
                if (bulletin.photos) {
                    bulletin.photos.forEach((photo: IBulletinPhoto) => {
                        this.bulletinPhotoRegistry.set(photo.id, photo);
                    });
                }
                this.loadingInitial = false;
                this.bulletin = bulletin;
            });
            return bulletin;
        } catch (error) {
            runInAction('getting bulletin error', () => {
                this.loadingInitial = false;
            });
            console.log(error);
        }
    };

    @action deleteById = async (id: string) => {
        try {
            await agent.Bulletins.delete(id);
            runInAction('deleting bulletin', () => {
                this.bulletinRegistry.delete(id);
            });
        } catch (error) {
            runInAction('delete bulletin error', () => {
            });
            console.log(error);
        }
    };

    @action uploadPhoto = async (formData: FormData) => {
        this.uploadingBulletinPhoto = true;
        try {
            const photo = await agent.Bulletins.uploadPhoto(formData);
            runInAction(() => {
                this.uploadingBulletinPhoto = false;
                this.bulletinPhotoRegistry.set(photo.id, photo);
                toast.success('A photo is added.')
            });
        } catch (error) {
            console.log(error);
            toast.error('Problem uploading bulletin photo');
            runInAction(() => {
                this.uploadingBulletinPhoto = false;
            });
        }
    };

    @action deletePhoto = async (photo: IBulletinPhoto) => {
        this.uploadingBulletinPhoto = true;
        try {
            await agent.Bulletins.deletePhoto(photo.id);
            runInAction(() => {
                this.bulletinPhotoRegistry.delete(photo.id);
                this.uploadingBulletinPhoto = false;
            });
        } catch (error) {
            toast.error('Problem deleting the photo');
            runInAction(() => {
                this.uploadingBulletinPhoto = false;
            });
        }
    };
}