export interface IReport {
    id: string;
    title: string;
    status: string;
    description: string;
    reportedDate: string;
    approvedDate: string;
    approverId: string;
    approverComment: string;
    approverImage: string;
    approverDisplayName: string;
}

export class ReportFormValues {
    id?: string = undefined;
    title: string = '';
    status: string = '';
    approverId: string = '';
    description: string = '';
}

export interface IReportResouceModel {
    reports: IReport[];
    reportCount: number;
}
