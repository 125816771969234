import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Grid } from 'semantic-ui-react';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import { Pagination } from 'semantic-ui-react'
import ListItemPlaceHolder from '../../../app/common/PlaceHolder/ListItemPlaceHolder';
import SearchAndSortForm from '../../../app/common/search/SearchAndSortForm';
import { BelieverStatus } from '../../../app/common/constants/believerStatus';
import { SortOrder } from '../../../app/common/constants/sortOrder';
import NewBelieverList from './NewBelieverList';
import { RouteComponentProps } from 'react-router-dom';

const NewBelieverDashboard: React.FC<RouteComponentProps> = ({ match, history }) => {
    const rootStore = useContext(RootStoreContext);
    const { getBelievers, loadBelievers, currentPage, setCurrentPage, totalPage, loadingInitial,
        deleteById,promoteById } = rootStore.believerStore;

    const [sortOrderEntered, setSortOrderEntered] = useState<string>(SortOrder.NameAsc);
    const [searchEntered, setSearchEntered] = useState<string>('');

    const { openModal } = rootStore.modalStore;

    useEffect(() => {
        loadBelievers(BelieverStatus.New, sortOrderEntered, searchEntered,'');
    }, []);

    const searchHandler = (searchOrder:string, search:string)=>{
        setSortOrderEntered(searchOrder);
        setSearchEntered(search);
        loadBelievers(BelieverStatus.New, searchOrder, search,'');
    }

    const handlePromoteById =(id:string, newComerId:string)=>{
        promoteById(id, newComerId).then(()=>{
            history.push(`/newBelievers`);
        })
    }

    const pageChangHandler = (event: React.MouseEvent<HTMLAnchorElement>, data: any) => {
        setCurrentPage(data.activePage);
        loadBelievers(BelieverStatus.New, sortOrderEntered, searchEntered,'');
    }

    return (
        <Grid style={{ marginTop: '15px' }}>
            <Grid.Column width={11}>
                {
                    loadingInitial ? <ListItemPlaceHolder /> :
                        (
                            <Fragment>
                                <NewBelieverList
                                    openModal ={openModal}
                                    deleteById = {deleteById}                                
                                    believers={getBelievers}
                                    promoteById ={handlePromoteById}
                                    isMobile={false} />
                                <Pagination
                                    style={{marginTop:15}}
                                    firstItem={null}
                                    lastItem={null}
                                    defaultActivePage={currentPage} totalPages={totalPage}
                                    onPageChange={pageChangHandler}
                                />
                            </Fragment>
                        )
                }
            </Grid.Column>
            <Grid.Column width={5}>
                <SearchAndSortForm 
                    search={searchHandler}/> 
            </Grid.Column>
        </Grid>
    );
};

export default observer(NewBelieverDashboard);


