import React, { Fragment } from 'react'
import { Divider, Header, Icon } from 'semantic-ui-react'

interface IProps {
  week: string;
  description: string;
}

const WeeklyNote: React.FC<IProps> = ({week, description }) => (

  <Fragment>
    <Divider horizontal key={week}>
      <Header as='h4'>
        <Icon name='calendar alternate outline' />
        {week}
      </Header>
    </Divider>
    <div dangerouslySetInnerHTML={{ __html: description.replace(/\n/g, "<br />") }} />  
  </Fragment>);

export default WeeklyNote;
