import React from 'react';
import { Link } from 'react-router-dom';
import { IBulletin } from '../../app/models/bulletin';
import { Table } from 'semantic-ui-react';

const AnnouncementTableRow: React.FC<{ bulletin: IBulletin }> = ({ bulletin }) => {
    return (
        <Table.Row>
            <Table.Cell width={12}>
                <Link to={`/announcement/${bulletin.id}`}>{bulletin.title}</Link>
            </Table.Cell>
            <Table.Cell width={4}>{new Date(bulletin.date).toLocaleDateString()} </Table.Cell>
        </Table.Row>
    );
};

export default AnnouncementTableRow;


