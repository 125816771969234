import React from 'react';
import { Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps } from 'react-router-dom';

interface DetailParams {
    id: string;
}

const ReportDetails: React.FC<RouteComponentProps<DetailParams>> = ({ match, history }) => {
    // const { } = rootStore.reportStore;
    return (
        <Grid>
        </Grid>
    );
};

export default observer(ReportDetails);