import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Grid } from 'semantic-ui-react';
import { RootStoreContext } from '../../../app/stores/rootStore';
import GalleryList from './GalleryList';
import { observer } from 'mobx-react-lite';
import { Pagination } from 'semantic-ui-react';
import ListItemPlaceHolder from '../../../app/common/PlaceHolder/ListItemPlaceHolder';
import { SortOrder } from '../../../app/common/constants/sortOrder';
import SearchAndSortForm from '../../../app/common/search/SearchAndSortForm';

const GalleryDashboard: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const { getGalleries, loadGalleries, currentPage, setCurrentPage, totalPage, loadingInitial } = rootStore.galleryStore;

    const [sortOrderEntered, setSortOrderEntered] = useState<string>(SortOrder.NameAsc);
    const [searchEntered, setSearchEntered] = useState<string>('');

    useEffect(() => {
        loadGalleries('', sortOrderEntered, searchEntered);
    }, []);

    const searchHandler = (searchOrder:string, search:string)=>{
        setSortOrderEntered(searchOrder);
        setSearchEntered(search);
        loadGalleries('', searchOrder, search);
    }
    const pageChangHandler = (event: React.MouseEvent<HTMLAnchorElement>, data: any) => {
        setCurrentPage(data.activePage);
        loadGalleries('', sortOrderEntered, searchEntered);
    }

    return (
        <Grid style={{ marginTop: '15px' }}>
            <Grid.Column width={10}>
                {
                    loadingInitial ? <ListItemPlaceHolder /> :
                        (
                            <Fragment>
                                <GalleryList galleries={getGalleries} />
                                <Pagination
                                    firstItem={null}
                                    lastItem={null}
                                    defaultActivePage={currentPage} totalPages={totalPage}
                                    onPageChange={pageChangHandler}
                                />
                            </Fragment>
                        )
                }
            </Grid.Column>
            <Grid.Column width={6}>
                <SearchAndSortForm 
                        search={searchHandler}/> 
            </Grid.Column>
        </Grid>
    );
};

export default observer(GalleryDashboard);

