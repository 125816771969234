import React, { Fragment, useContext, useEffect } from 'react';
import { Grid, Breadcrumb, Pagination } from 'semantic-ui-react';
import { RootStoreContext } from '../../app/stores/rootStore';
import VideoTableItemPlaceHolder from '../video/dashboard/VideoTableItemPlaceHolder';
import { observer } from 'mobx-react-lite';
import { VideoCategories } from '../../app/common/constants/categories';
import TwoColumnTable from '../../app/common/tables/TwoColumnTable';
import TwoColumnTableRow from '../../app/common/tables/TwoColumnTableRow';

const sections = [
    { key: '교회 영상', content: '교회 영상', link: true },
    { key: '행사', content: '행사', active: true }
]

const VideoEventPage = () => {
    const rootStore = useContext(RootStoreContext);
    const { loadVideos, currentPage, setCategory, setCurrentPage,
        getVideosByDate, totalPage, loadingInitial } = rootStore.videoStore;

    useEffect(() => {
        setCategory(VideoCategories.event);
    }, [setCategory]);

    const pageChangHandler = (event: React.MouseEvent<HTMLAnchorElement>, data: any) => {
        setCurrentPage(data.activePage);
        loadVideos();
    }

    return (
        <Grid>
            <Breadcrumb icon='right angle' sections={sections} style={{ margin: '20px', paddingTop: '15px' }} />
            <Grid.Column width={16}>
                {loadingInitial ? <VideoTableItemPlaceHolder />
                    : <Fragment>
                        <TwoColumnTable title='제목'>
                           <Fragment>
                                {getVideosByDate.map((video) => {
                                    return <TwoColumnTableRow id={video.id}
                                                title={video.title}
                                                link={`/video/${video.id}`}
                                                date={video.date} />
                                })}
                            </Fragment>
                        </TwoColumnTable>
                        <Pagination
                            firstItem={null}
                            lastItem={null}
                            defaultActivePage={currentPage} totalPages={totalPage}
                            onPageChange={pageChangHandler}
                        />
                    </Fragment>
                }
            </Grid.Column>
        </Grid>
    );
};

export default observer(VideoEventPage);



