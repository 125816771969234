import React from 'react';
import { Grid, Image } from 'semantic-ui-react';

// const sections = [
//     { key: 'Church', content: '교회 소개', active: true }
// ]

// const headerSize = 'large';
// const listSize = 'large';
/* <Breadcrumb icon='right angle' sections={sections} style={{ margin: '20px', paddingTop: '15px' }} /> */ 

const ChurchPage = () => {
    return (
        <Grid>
            <Grid.Column width={16} style={{ backgroundColor: 'white' }}>
                <Image src={'/assets/main/vision.jpg'} fluid style={{ marginBottom: '20px' }} />
            </Grid.Column>
        </Grid >
    );
};

export default ChurchPage;