import React, { Fragment } from 'react';
import { IBeliever } from '../../../app/models/believer';
import NewBelieverListItem from './NewBelieverListItem';

interface IProps {
    believers: IBeliever[],
    openModal:(modal:any) =>void,
    deleteById:(id: string) =>void,
    promoteById:(id: string, newComerId:string) =>void,
    isMobile:boolean
}

const NewBelieverList: React.FC<IProps> = ({believers, openModal, deleteById, promoteById, isMobile}) => {
    return (
        <Fragment>
            {believers.map((believer) => {
                return <NewBelieverListItem 
                    key={believer.id} 
                    openModal ={openModal}
                    deleteById = {deleteById}
                    promoteById={promoteById}
                    believer={believer} 
                    isMobile={isMobile}/>
            })}
        </Fragment>
    );
};

export default NewBelieverList;