export interface IHelp {
    id: string;
    category: string;
    title: string;
    date: Date;
    filePath: string;
}

export class HelpFormValues {
    id?: string = undefined;
    category: string = '';
    title: string = '';
    date?: Date = undefined;
    image: Blob | null = null;
    filePath: string = '';
}

export interface IHelpResouceModel {
    data: IHelp[];
    count: number;
    currentPage: number;
    totalPage: number;
    pageSize: number;
}