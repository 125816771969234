import { observer } from 'mobx-react-lite';
import React, { useState, useContext, useEffect, Fragment } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Grid, Header } from 'semantic-ui-react';
import { IBulletin } from '../../app/models/bulletin';
import { RootStoreContext } from '../../app/stores/rootStore';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

interface DetailParams {
    id: string;
}

const BulletinDetail: React.FC<RouteComponentProps<DetailParams>> = ({ match, history }) => {
    const rootStore = useContext(RootStoreContext);
    const { loadBulletin, getBulletinPhotos } = rootStore.bulletinStore;

    const [bulletin, setBulletin] = useState<IBulletin>();

    useEffect(() => {
        if (match.params.id) {
            loadBulletin(match.params.id, true)
                .then((bulletin) => {
                    setBulletin(bulletin);
                })
                .finally();
        }
    }, [loadBulletin, match.params.id]);

    return (
        <Fragment>
            {bulletin &&
            <Fragment>
                <Grid style={{ marginTop: 25, paddingTop: 10, backgroundColor: 'white' }}>
                            
                            <Grid.Column width={16}>
                                <Header floated="left" content={bulletin.title} />
                                <Button
                                    onClick={() => history.goBack()}
                                    floated="right"
                                    basic
                                    content="Go Back"
                                    style={{ marginRight: 10 }}
                                />
                            </Grid.Column>                               
                </Grid>
                <Grid>
                    <Grid.Column width={3}/>
                    <Grid.Column width={10}>
                        <Carousel>
                            {getBulletinPhotos.map((photo) => {
                                var photoUrl = photo.url;
                                if (photoUrl.includes("upload") && !photoUrl.includes("q_auto:best")) {
                                    photoUrl = photoUrl.replace("upload", "upload/q_auto:best");
                                }
                                return (<div key={photo.id}>
                                    <img src={photoUrl} alt="bulletinImage" />
                                </div>)
                            })
                            }
                        </Carousel>
                    </Grid.Column>
                    <Grid.Column width={3}/>
                </Grid>
            </Fragment>
            }
        </Fragment>
    );
};

export default observer(BulletinDetail);


