import { observable, action, computed, runInAction, reaction } from 'mobx';
import { IGroup } from '../models/group';
import agent from '../api/agent';
import { history } from '../..';
import { toast } from 'react-toastify';
import { RootStore } from './rootStore';
import { setGroupProps, createMember } from '../common/util/util';
import 'mobx-react-lite/batchingForReactDom';

const LIMIT = 4;

export default class GroupStore {
	rootStore: RootStore;
	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
		reaction(
			() => this.category,
			() => {
				this.page = 0;
				this.groupRegistry.clear();
				this.loadGroups();
			}
		)
	}

	@observable submitting = false;
	@observable group: IGroup | null = null;
	@observable groupRegistry = new Map();
	@observable loadingInitial = true;
	@observable loadingAttendence = false;
	@observable groupCount = 0;
	@observable page = 0;
	@observable category = 'All';

	@action setCategory = (category: string) => {
		this.category = category;
	}

	@computed get totalPages() {
		return Math.ceil(this.groupCount / LIMIT);
	}

	@action setPage = (page: number) => {
		this.page = page;
	}

	@action createGroup = async (group: IGroup) => {
		this.submitting = true;
		try {
			await agent.Groups.create(group);

			const member = createMember(this.rootStore.userStore.user!);
			let members = [];
			members.push(member);
			group.members = members;

			runInAction('creating activities', () => {
				this.groupRegistry.set(group.id, group);
				this.submitting = false;
			});
			history.push(`/groups/${group.id}`);
		} catch (error) {
			runInAction('create activities error ', () => {
				this.submitting = false;
			});
			toast.error('Problem submitting data');
		}
	};

	@action editGroup = async (group: IGroup) => {
		this.submitting = true;
		try {
			await agent.Groups.update(group);
			runInAction('editing activities', () => {
				this.groupRegistry.set(group.id, group);
				this.group = group;
				this.submitting = false;
			});
			history.push(`/groups/${group.id}`);
		} catch (error) {
			runInAction('edit activities', () => {
				this.submitting = false;
			});
			toast.error('Problem submitting data');
		}
	};

	getGroup = (id: string) => {
		return this.groupRegistry.get(id);
	};

	@action loadGroup = async (id: string) => {
		try {
			let group = await agent.Groups.details(id);
			runInAction('getting group', () => {
				this.groupRegistry.set(group.id, group);
				setGroupProps(group, this.rootStore.userStore.user!);
				this.loadingInitial = false;
				this.group = group;
			});
			return group;
		} catch (error) {
			runInAction('getting group error', () => {
				this.loadingInitial = false;
			});
			console.log(error);
		}
	};

	@computed get axiosParams() {
		const params = new URLSearchParams();
		params.append('limit', String(LIMIT));
		params.append('offset', `${this.page ? this.page * LIMIT : 0}`);
		params.append('category', this.category);
		return params;
	}

	@action loadGroups = async () => {
		try {
			const groupResourceModel = await agent.Groups.list(this.axiosParams);
			const { groups, groupCount } = groupResourceModel;

			runInAction('loading groups', () => {
				if (groups) {
					groups.forEach((group) => {
						this.groupRegistry.set(group.id, group);
					});
				}
				this.groupCount = groupCount;
				this.loadingInitial = false;
			});
		} catch (error) {
			runInAction('load groups error', () => {
				this.loadingInitial = false;
			});
			throw error;
		}
	};

	@computed get getGroupByTitle() {
		let groups = Array.from(this.groupRegistry.values());
		return groups.sort(x => x.title);
	}

	@action attend = async () => {
		const member = createMember(this.rootStore.userStore.user!);
		this.loadingAttendence = true;
		try {
			await agent.Groups.attend(this.group!.id);
			runInAction(() => {
				if (this.group) {
					this.group.members.push(member);
					this.groupRegistry.set(this.group.id, this.group);
					this.loadingAttendence = false;
				}
			});
		} catch (error) {
			runInAction(() => {
				this.loadingAttendence = false;
			});
			toast.error('Problem join to a group');
		}
	};

	@action unattend = async () => {
		this.loadingAttendence = true;
		try {
			await agent.Groups.unattend(this.group!.id);
			runInAction(() => {
				if (this.group) {
					this.group.members = this.group.members.filter(
						(a) => a.userName !== this.rootStore.userStore.user!.userName
					);
					this.groupRegistry.set(this.group.id, this.group);
					this.loadingAttendence = false;
				}
			});
		} catch (error) {
			runInAction(() => {
				this.loadingAttendence = false;
			});
			toast.error('Problem cancelling attendance');
		}
	};
}


// let group = this.getGroup(id);
// if (group) {
// 	this.group = group;
// 	return toJS(group);
// } else {
