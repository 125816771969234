import React from 'react';
import { Grid, Header, Breadcrumb, List, Image } from 'semantic-ui-react';

const sections = [
    { key: '예배', content: '예배', link: true },
    { key: 'international-ministry', content: 'Juan internation ministry', active: true },
]

const headerSize = 'large';

const InternationalMinistryPage = () => {

    return (
        <Grid>
            <Grid.Column width={16} style={{ backgroundColor: 'white' }}>
                <Breadcrumb icon='right angle' sections={sections} style={{ margin: '20px' }} />
                <Image src={'/assets/main/internationMinistry.jpg'} style={{ marginBottom: '20px' }} />
                <Header content='JUAN INTERNATIONAL MINISTRY' size={headerSize} />
                <List.Item>Ps. John LEE</List.Item>
                <List.Item>Sundays 1:30pm</List.Item>
            </Grid.Column>
        </Grid>
    );
};

export default InternationalMinistryPage;