
import { IOrganisation, OrganisationFormValues } from '../models/organisation';
import { RootStore } from './rootStore';
import { observable, action, computed, runInAction } from 'mobx';
import agent from '../api/agent';
import { history } from '../..';
import { toast } from 'react-toastify';
import { IOptions } from '../models/options';
import { AllCategory, OrganisationCategories } from '../common/constants/categories';

export default class OrganisationStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @observable organisation: IOrganisation | null = null;
    @observable parentsRegistry = new Map();
    @observable childrenRegistry = new Map();

    @observable organisationRegistry = new Map();
    @observable dropDownOrganisationRegistry = new Map();
    @observable dropDownPrayerOptionsRegistry = new Map();
   
    @observable organisationCount = 0;

    @observable submitting = false;
    @observable loadingInitial = true;
    @observable loadingDropDown = true;
    @observable loadingGalleryOptionsDropDown =true;
    @observable loadingPrayerOptionsDropDown =true;
    @observable currentPage = 1;
    @observable totalPage = 1;
    @observable pageSize: number = 7;
    @observable deleteErrorMessage: string ='';
    @observable errorMessage: string = '';
    // @observable parentId: string ='';

    @observable category = AllCategory;

    @action setCategory = (category: string) => {
        this.category = category;
        this.currentPage = 1;
        this.organisationRegistry.clear();
        this.loadOrganisations();
    }

    @observable idOnTree :string='';
    @observable nameOnTree :string='';
    @observable descriptionOnTree :string='';
    @observable parentOnTree :string='';

    @observable isBottomOnTree :boolean=false;

    @action setPageSize = (pageSize: number) => {
        this.pageSize = pageSize;
    }

    @action setCurrentPage = (page: number) => {
        this.currentPage = page;
    }

    @observable initialSearchValue: string ='';

    @action cancel = ()  => {
        this.initialSearchValue='';
        this.idOnTree = '';
        this.nameOnTree = '';
        this.parentOnTree = '';
        this.descriptionOnTree = '';
        this.errorMessage ='';
    }

    @action initFormItemValues = ()  => {
        this.initialSearchValue='';
        this.idOnTree = '';
        this.nameOnTree = '';
        this.parentOnTree = '';
        this.descriptionOnTree = '';
    }

    @action setFormItem = async  (organistaion: IOrganisation,isBottom: boolean, parentName:string)  => {
        try {
            let organisation = await agent.Organisations.details(organistaion.id);

            runInAction('loading organisation', () => {
                this.idOnTree = organistaion.id;
                this.nameOnTree = organistaion.name;
                this.parentOnTree = parentName;
                this.descriptionOnTree = organistaion.description;

                this.initialSearchValue= parentName;
                this.isBottomOnTree = isBottom;        
                this.loadingInitial = false;
                this.errorMessage ='';
                this.deleteErrorMessage='';    
            });
            return organisation;

        } catch (error) {
            runInAction('load organisation error', () => {
                this.loadingInitial = false;
            });
            throw error;
        }
    }

    @action setOrganisationTreeItem = (organisation: IOrganisation,isBottom: boolean, parentName:string) => {
        this.idOnTree = organisation.id;
        this.nameOnTree = organisation.name;
        this.parentOnTree = parentName;
        this.initialSearchValue = parentName;
        this.organisation = organisation;
        this.descriptionOnTree = organisation.description;
        this.isBottomOnTree = isBottom;
    }

    @computed get axiosParams() {
        const params = new URLSearchParams();
        params.append('currentPage', `${this.currentPage}`);
        params.append('pageSize', `${this.pageSize}`);
        return params;
    }

    @computed get getDropDownPrayerOptions() {
        if (this.dropDownPrayerOptionsRegistry.size >= 1) {
            return Array.from(this.dropDownPrayerOptionsRegistry.values());
        }
        return [];
    }

    @computed get getDropDownlOrganisations() {
        if (this.dropDownOrganisationRegistry.size >= 1) {
            return Array.from(this.dropDownOrganisationRegistry.values());
        }
        return [];
    }

    @computed get getOrganisations() {
        if (this.organisationRegistry.size >= 1) {
            return Array.from(this.organisationRegistry.values());
        }
        return [];
    }

    @computed get getparents() {
        if (this.parentsRegistry.size >= 1) {
            return Array.from(this.parentsRegistry.values());
        }
        return [];
    }

    @computed get getchildren() {
        if (this.childrenRegistry.size >= 1) {
            return Array.from(this.childrenRegistry.values());
        }
        return [];
    }
    @action createOrganisation = async (organisation: IOrganisation) => {
        runInAction('submitting', () => {
            this.submitting = true;
        });
        try {
            await agent.Organisations.create(organisation);

            runInAction('creating organisation', () => {
                this.submitting = false;
                this.errorMessage = '';

                toast.info(organisation.name + ' is created.');
            });

        } catch (error) {
            runInAction('create organisations error ', () => {
                this.submitting = false;
                console.log(error);
                // this.errorMessage =error.statusText;
            });
            toast.error('Problem submitting data');
        }
    };

    @action editOrganisation = async (organisation: IOrganisation) => {
        runInAction('submitting', () => {
            this.submitting = true;
        });
        try {
            await agent.Organisations.update(organisation);

            runInAction('editing organisation', () => {
                this.submitting = false;
                this.errorMessage = '';
                toast.info(organisation.name + ' is updated.');
            });
        } catch (error) {
            runInAction('create organisation error ', () => {
                this.submitting = false;
                console.log(error);
                // this.errorMessage =error.statusText;
            });
            toast.error('Problem submitting data');
        }
    };

    @action loadOrganisation = async (id: string) => {
        try {
            let organisation = await agent.Organisations.details(id);

            runInAction('loading organisation', () => {
                this.loadingInitial = false;
                this.organisation = organisation;
                this.initialSearchValue = organisation.parentName;
                this.errorMessage ='';
                this.deleteErrorMessage='';    
            });
            return organisation;

        } catch (error) {
            runInAction('load organisation error', () => {
                this.loadingInitial = false;
            });
            throw error;
        }
    };

    @action loadTreeView = async ()=> {
        try {
            runInAction('before loading treeView',()=>{
                this.loadingInitial = true;
                this.parentsRegistry.clear();            
            })

            let treeView = await agent.Organisations.treeView();
            runInAction('loading treeView', () => {
                if (treeView) {
                    const { parents, children } = treeView;
                    parents.forEach((organisation: IOrganisation) => {
                        this.parentsRegistry.set(organisation.id, organisation);
                    });

                    this.childrenRegistry.clear();
                    children.forEach((organisation: IOrganisation) => {
                        this.childrenRegistry.set(organisation.id, organisation);
                    });
                    this.organisation = null;
                    this.loadingInitial = false;
                }
            });
        }
        catch (error) {
            this.loadingInitial = false;
        }
    }

    @action loadOrganisations = async () => {
        try {
            let organisationResourceModel = await agent.Organisations.list(this.currentPage, this.pageSize, this.category);

            runInAction('loading organisations', () => {
                if (organisationResourceModel) {
                    const { currentPage, count, totalPage, data } = organisationResourceModel;
                    this.organisationRegistry.clear();
                    data.forEach((organisation: IOrganisation) => {
                        this.organisationRegistry.set(organisation.id, organisation);
                    });
                    this.currentPage = currentPage;
                    this.totalPage = totalPage;
                    this.organisationCount = count;
                    this.loadingInitial = false;
                    this.errorMessage = '';
                    this.deleteErrorMessage='';        
                }
            });
            
            return organisationResourceModel.data;
        } catch (error) {
            runInAction('load organisations error', () => {
                this.loadingInitial = false;
            });
            throw error;
        }
    };

    @action loadDropDownPrayerOptions = async () => {
        try {
            this.loadingPrayerOptionsDropDown = true;
            let dropDownPrayerOptions = await agent.Organisations.prayerOptions();

            runInAction('loading organisations', () => {
                if (dropDownPrayerOptions) {
                    this.dropDownPrayerOptionsRegistry.clear();
                    dropDownPrayerOptions.forEach((option: IOptions) => {
                        this.dropDownPrayerOptionsRegistry.set(option.value, option);
                    });
                    this.loadingPrayerOptionsDropDown= false;
                }
            });
            
            return dropDownPrayerOptions;
        } catch (error) {
            runInAction('load organisations error', () => {
            });
            throw error;
        }
    };

    @action loadDropDownlOrganisations = async () => {
        try {
            runInAction('started to load organisations', () => {
                this.loadingDropDown= true;
            });
    
            let organisationResourceModel = await agent.Organisations.list(1, 10000, OrganisationCategories.Active);

            runInAction('loading organisations', () => {

                if (organisationResourceModel) {
                    this.dropDownOrganisationRegistry.clear();
                    const { data } = organisationResourceModel;
                    data.forEach((organisation: IOrganisation) => {
                        this.dropDownOrganisationRegistry.set(organisation.id, organisation);
                    });
                    this.loadingDropDown= false;
                }
            });
            
            return organisationResourceModel.data;
        } catch (error) {
            runInAction('load organisations error', () => {
                this.loadingDropDown= false;
            });
            throw error;
        }
    };

    @action deleteById = async (id: string) => {
        try {
            await agent.Organisations.delete(id);

            runInAction('deleting organiastion', () => {
                this.loadingInitial = true;
                this.organisationRegistry.delete(id);
                this.loadingInitial = false;
                this.deleteErrorMessage ='';
                history.push(`/organisations`);
                toast.info('it is removed.');                
            });
        } catch (error) {
            toast.error('Problem submitting data');
            runInAction('delete organiastion error', () => {
                console.log(error);
                this.loadingInitial = false;
            });
        }
    };

}



