import React from 'react';
import { List } from 'semantic-ui-react';
import { IOrganisation } from '../../../app/models/organisation';
import { getFontSize } from '../../../app/common/util/util';

interface IProps {
    organisation: IOrganisation,
    parentName : string,
    treeItemSize: number,
    children:any[];
    idOnTree:string;
    selectTreeItem:(organisation:IOrganisation, isBottom:boolean, parentName:string) => void;
}

const TreeItem: React.FC<IProps> = ({ organisation, parentName, treeItemSize, children, 
    idOnTree, selectTreeItem }) => {

    const filtered =()=>{
        return children.filter((child)=>{
            if (child.parentId === organisation.id)
                return organisation;
        })
    } 

    const selectTreeItemHandler = (organisation:IOrganisation, parentName:string)=>{
        const isBottom = filtered.length ===0;
        selectTreeItem(organisation, isBottom, parentName);
    }

    let itemStyle ={color:'black', cursor:'pointer', fontSize:getFontSize(treeItemSize)};
    if (organisation.id == idOnTree){
        itemStyle ={color:'#007FFF', cursor:'default', fontSize:getFontSize(treeItemSize)}
    }

    return (<List.Item style={itemStyle}>
                <List.Icon name='folder open outline' size='large'/>
                <List.Content>
                    <List.Header>
                        <span style={itemStyle} onClick={()=>selectTreeItemHandler(organisation, parentName)}>
                            {organisation.name}
                        </span>
                    </List.Header>
                    {
                        (filtered().length > 0 &&                             
                            <List>
                                {filtered().map((child) => {
                                    return <TreeItem key={child.id} treeItemSize={treeItemSize}
                                                idOnTree={idOnTree}
                                                children={children} selectTreeItem={selectTreeItem}
                                                organisation={child} parentName={organisation.name} />
                                })}
                            </List>                            
                        )
                    }
                </List.Content>        
            </List.Item>);
};

export default TreeItem;