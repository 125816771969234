import React from 'react';
import { Grid, Breadcrumb, List } from 'semantic-ui-react';

const sections = [
    { key: 'Church', content: '교회 소개', link: true },
    { key: 'Contacts', content: 'Contacts', active: true },
]

const listSize = 'large';

const ContactsPage = () => {
    return (
        <Grid>
            <Breadcrumb icon='right angle' sections={sections} style={{ margin: '20px', paddingTop: '15px' }} />

            <Grid.Column width={16} style={{ backgroundColor: 'white', margin: '10px' }}>

                <List size={listSize}>
                    <List.Item>우편 주소 : 9/927-933 Victoria Rd. West Ryde NSW 2114</List.Item>
                    <List.Item style={{ paddingTop: '20px', fontWeight: 'bold' }}>West Ryde 주안교회</List.Item>
                    <List.Item>2 Station St. West Ryde 2114</List.Item>
                    <List.Item></List.Item>
                    <List.Item>진기현 목사님 0438 820 691</List.Item>
                    <List.Item>강현규 목사님 0452 200 979</List.Item>
                    <List.Item>박주영 목사님 0403 808 574</List.Item>
                </List>
            </Grid.Column>
        </Grid>
    );
};

export default ContactsPage;