import React from 'react';
import { Grid, Breadcrumb, Header, List, Table, Image } from 'semantic-ui-react';
// import JuanMap from '../../app/common/Maps/JuanMap';
import { ChatswoodSundayServices, ChatswoodOvernightServices, ChatswoodEarlyMorningServices } from '../../app/data/services';
import TableRow from '../../app/common/tables/tableRow2';

const sections = [
    { key: '예배', content: '예배', link: true },
    { key: 'Chatswood', content: 'Chatswood', active: true },
]

const headerSize = 'large';
const listSize = 'large';

const ChatswoodPage = () => {
    return (
        <Grid>
            <Grid.Column width={16}>
                <Breadcrumb icon='right angle' sections={sections} style={{ margin: '20px' }} />
                <Image src={'/assets/main/chatswood.jpg'} fluid style={{ marginBottom: '20px' }} />
                <Header content='Chatswood Youth Centre' size={headerSize} />
                <List size={listSize} style={{ backgroundColor: 'white' }}>
                    <List.Item>64 Albert Ave, Chatswood NSW 2067</List.Item>
                    <List.Item>9904-6541 / 0430-507-441</List.Item>
                </List>
                {/* <JuanMap latitude={-33.798482} longitude={151.183345}
                    zoom={17} height={250}
                /> */}
                <Header content='주일예배시간' size={headerSize} />
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>예배시간</Table.HeaderCell>
                            <Table.HeaderCell>장소</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {ChatswoodSundayServices.map(service => (
                            <TableRow key={service.id} date={service.date} place={service.place} />
                        ))}
                    </Table.Body>
                </Table>

                <Header content='금요심야기도회' size={headerSize} />
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>예배시간</Table.HeaderCell>
                            <Table.HeaderCell>장소</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {ChatswoodOvernightServices.map(service => (
                            <TableRow key={service.id} date={service.date} place={service.place} />
                        ))}
                    </Table.Body>
                </Table>

                <Header content='새벽기도회' size={headerSize} />
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>예배시간</Table.HeaderCell>
                            <Table.HeaderCell>장소</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {ChatswoodEarlyMorningServices.map(service => (
                            <TableRow key={service.id} date={service.date} place={service.place} />
                        ))}
                    </Table.Body>
                </Table>
            </Grid.Column>
        </Grid>
    );
};

export default ChatswoodPage;