import { observable, action, computed, runInAction, reaction } from 'mobx';
import agent from '../api/agent';
import { history } from '../..';
import { toast } from 'react-toastify';
import { RootStore } from './rootStore';
import 'mobx-react-lite/batchingForReactDom';
import { IReport } from '../models/report';

const LIMIT = 4;

export default class ReportStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        reaction(() => this.status,
            () => {
                this.page = 0;
                this.reportRegistry.clear();
                this.loadReports();
            })
    }

    @observable submitting = false;
    @observable reportRegistry = new Map();
    @observable report: IReport | null = null;
    @observable loadingInitial = false;
    @observable page = 0;
    @observable status = 'Pending';

    @action setStatus = (status: string) => {
        this.status = status;
    }

    @observable reportCount = 0;

    @action setPage = (page: number) => {
        this.page = page;
    }

    @computed get totalPages() {
        return Math.ceil(this.reportCount / LIMIT);
    }

    @computed get axiosParams() {
        const params = new URLSearchParams();
        params.append('limit', String(LIMIT));
        params.append('offset', `${this.page ? this.page * LIMIT : 0}`);
        params.append('status', this.status);
        return params;
    }

    @action loadReport = async (id: string) => {
        try {
            let report = await agent.Reports.details(id);
            runInAction('getting report', () => {
                this.reportRegistry.set(report.id, report);
                this.loadingInitial = false;
                this.report = report;
            });
            return report;
        } catch (error) {
            runInAction('getting report error', () => {
                this.loadingInitial = false;
            });
            console.log(error);
        }
    };

    @action loadReports = async () => {
        try {
            const reportResourceModel = await agent.Reports.list(this.axiosParams);

            const { reports, reportCount } = reportResourceModel;

            runInAction('loading reports', () => {
                if (reports) {
                    reports.forEach((report) => {
                        this.reportRegistry.set(report.id, report);
                    });
                }
                this.reportCount = reportCount;
                this.loadingInitial = false;
            });
        } catch (error) {
            runInAction('load reports error', () => {
                this.loadingInitial = false;
            });
            throw error;
        }
    };

    @computed get getReports() {
        return Array.from(this.reportRegistry.values());
    }

    @action createReport = async (report: IReport) => {
        this.submitting = true;
        try {
            await agent.Reports.create(report);

            runInAction('creating report', () => {
                this.reportRegistry.set(report.id, report);
                this.submitting = false;
            });
            history.push('/reports');
        } catch (error) {
            runInAction('create report error ', () => {
                this.submitting = false;
            });
            toast.error('Problem submitting report');
        }
    };

    @action editReport = async (report: IReport) => {
        this.submitting = true;
        try {
            await agent.Reports.update(report);
            runInAction('editing report', () => {
                this.reportRegistry.set(report.id, report);
                this.report = report;
                this.submitting = false;
            });
            history.push(`/reports`);
        } catch (error) {
            runInAction('edit report error', () => {
                this.submitting = false;
            });
            toast.error('Problem submitting data');
        }
    };
}

