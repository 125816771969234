import React from 'react';
import { Link } from 'react-router-dom';
import { IBulletin } from '../../../app/models/bulletin';
import { Table} from 'semantic-ui-react';

interface IProps {
    bulletin: IBulletin
}

const BulletinTableMobileRow: React.FC<IProps> = ({ bulletin }) => {
    return (
            <Table.Row>
                <Table.Cell width={4}>
                    <Link to={`/bulletinMobile/${bulletin.id}`}>{bulletin.title}</Link>
                </Table.Cell>
                <Table.Cell width={12} textAlign="right">
                    {
                        bulletin.filePath ?
                            (
                                <a href={bulletin.filePath} download> - Download - </a>
                            ) : ''
                    }
                </Table.Cell>
            </Table.Row>
        )
};

export default BulletinTableMobileRow;