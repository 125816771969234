import React, { Fragment } from 'react';
import { IOrganisation } from '../../../app/models/organisation';
import OrganisationListItem from './OrganisationListItem';

interface IProps {
    organisations: IOrganisation[]
}

const OrganisationList= ({organisations}:IProps) => {
    return (
        <Fragment>
            {organisations.map((organisation) => {
                return <OrganisationListItem key={organisation.id} organisation={organisation} />
            })}
        </Fragment>
    );
};

export default OrganisationList;