import { observer } from 'mobx-react-lite';
import React, { useState, useContext, useEffect, Fragment } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Grid, Header } from 'semantic-ui-react';
import { IBulletin } from '../../app/models/bulletin';
import { RootStoreContext } from '../../app/stores/rootStore';

interface DetailParams {
    id: string;
}

const AnnouncementDetail: React.FC<RouteComponentProps<DetailParams>> = ({ match, history }) => {
    const rootStore = useContext(RootStoreContext);
    const { loadBulletin } = rootStore.bulletinStore;

    const [bulletin, setBulletin] = useState<IBulletin>();

    useEffect(() => {
        if (match.params.id) {
            loadBulletin(match.params.id, true)
                .then((bulletin) => {
                    setBulletin(bulletin);
                })
                .finally();
        }
    }, [loadBulletin, match.params.id]);

    return (
        <Grid style={{ marginTop: 25, paddingTop: 10, backgroundColor: 'white' }}>
            {bulletin &&
                <Fragment>
                    <Grid.Column width={16}>
                        <Header floated="left" content={bulletin.title} />
                        <Button
                            onClick={() => history.push('/announcement')}
                            floated="right"
                            basic
                            content="Go Back"
                            style={{ marginRight: 10 }}
                        />
                    </Grid.Column>
                    <Grid.Column width={16}>
                    </Grid.Column>
                </Fragment>
            }
        </Grid>
    );
};

export default observer(AnnouncementDetail);


