import { combineValidators, isRequired } from 'revalidate';
import React, { useState, useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { RouteComponentProps } from 'react-router-dom';
import { BulletinFormValues } from '../../../app/models/bulletin';
import { Segment, Form, Button, Grid } from 'semantic-ui-react';
import { v4 as uuid } from 'uuid';
import { Form as FinalForm, Field } from 'react-final-form';
import DateInput from '../../../app/common/form/DateInput';
import TextInput from '../../../app/common/form/TextInput';
import SelectInput from '../../../app/common/form/SelectInput';
import { BulletinCategories } from '../../../app/common/constants/categories';

const validators = combineValidators({
    category: isRequired('Category'),
    date: isRequired('Date'),
    title: isRequired({ message: 'The title is required' })
});

interface DetailParams {
    id: string;
}

const categoryOptions = [
    { key: BulletinCategories.WestRyde, text: '웨스트라이드 주보', value: BulletinCategories.WestRyde },
    { key: BulletinCategories.City, text: '시티 주보', value: BulletinCategories.City },
];

// { key: 'event', text: '행사', value: 'event' },
// { key: 'develpment', text: '개발 노트', value: 'develpment' },

const BulletinForm: React.FC<RouteComponentProps<DetailParams>> = ({ match, history }) => {

    const rootStore = useContext(RootStoreContext);
    const { createBulletin, editBulletin, loadBulletin, submitting } = rootStore.bulletinStore;

    const [image, setImage] = useState<Blob | null>(null);
    const [bulletin, setBulletin] = useState(new BulletinFormValues());
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (match.params.id) {
            setLoading(true);
            loadBulletin(match.params.id)
                .then((bulletin) => {
                    if (bulletin.photos == null) {
                        bulletin.photos = '';
                    }

                    setBulletin(bulletin);
                })
                .finally(() => setLoading(false));
        }
    }, [loadBulletin, match.params.id]);

    const fileSelectedHandler = (e: any) => {
        setImage(e.target.files[0]);
    }

    const handlFinalFormSubmit = (bulletin: any) => {
        let formData = new FormData();

        formData.append('Category', bulletin.category);
        formData.append('Title', bulletin.title);
        formData.append('Image', image!);

        var bulletinDate = new Date(bulletin.date);
        formData.append('Date', bulletinDate.toLocaleDateString("en-AU"));

        if (!bulletin.id) {
            var id = uuid();
            formData.append('Id', id);
            createBulletin(formData);
        } else {
            formData.append('Id', bulletin.id);
            editBulletin(formData, bulletin.id);
        }
    };

    return (<Grid style={{ marginTop: '15px' }}>
        <Grid.Column width={10}>
            <Segment clearing>
                <FinalForm
                    validate={validators}
                    initialValues={bulletin}
                    onSubmit={handlFinalFormSubmit}
                    render={({ handleSubmit, invalid, pristine }) => (
                        <Form onSubmit={handleSubmit} loading={loading}>
                            <Field
                                component={SelectInput}
                                options={categoryOptions}
                                name="category"
                                placeholder="Category"
                                value={bulletin.category}
                            />
                            <Field component={DateInput} name="date" date={true} placeholder="Date" value={bulletin.date} />
                            <Field
                                component={TextInput}
                                name="title"
                                placeholder="Title"
                                value={bulletin.title}
                            />
                            <Form.Field>
                                {bulletin.filePath ? <p>{bulletin.filePath}</p> : 
                                <input type="file" onChange={fileSelectedHandler} />}
                            </Form.Field>
                            <Button
                                floated="right"
                                loading={submitting}
                                disabled={loading || invalid}
                                positive
                                type="submit"
                                content="Save"
                            />
                            <Button
                                onClick={
                                     () => history.push('/bulletins')
                                }
                                floated="right"
                                type="button"
                                content="Cancel"
                            />
                        </Form>)}
                />
            </Segment>
        </Grid.Column>
    </Grid>);
};

export default observer(BulletinForm);

