import React, { useContext } from 'react';
import { Item, Button, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { IBulletin } from '../../../app/models/bulletin';
import ConfirmationModal from '../../../app/common/modals/ConfirmationModal';
import {format} from 'date-fns';

const BulletinListItem: React.FC<{ bulletin: IBulletin }> = ({ bulletin }) => {
    const rootStore = useContext(RootStoreContext);
    const { openModal } = rootStore.modalStore;
    const { deleteById } = rootStore.bulletinStore;

    return (
        <Segment.Group key={bulletin.id}>
            <Segment>
                <Item.Group>
                    <Item>
                        <Item.Content>
                            <Item.Header>
                                {bulletin.category}
                            </Item.Header>
                            <Item.Description>
                                {bulletin.title} - {format(new Date(bulletin.date),'dd MMM yyyy')}
                            </Item.Description>
                        </Item.Content>
                    </Item>
                </Item.Group>
            </Segment>
            <Segment clearing>
                <Button floated="right" content="Delete" color="red"
                    onClick={() => openModal(<ConfirmationModal
                        id={bulletin.id}
                        subject={'Delete your Bulletin'}
                        message={'Are you sure you want to delete your Bulletin'}
                        confirmById={deleteById}
                    />)}
                />
                <Button as={Link} to={`/manageBulletin/${bulletin.id}`} floated="right" content="Edit" color="blue" />
                <Button as={Link} to={`/manageBulletinPhoto/${bulletin.id}`} floated="right" content="Edit Photo" color="green" />
            </Segment>
        </Segment.Group>
    );
};

export default BulletinListItem;


//{new Date(bulletin.date).toLocaleDateString()}
