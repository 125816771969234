import React from 'react';
import { Tab, Grid, Header, List } from 'semantic-ui-react';

const TheHeartTab = () => {
    return (
        <Tab.Pane style={{ backgroundColor: '#9887fa' }}>
            <Grid>
                <Grid.Column width={16}>
                    <Header content='시티 목요찬양예배(THE HEART)안내' />
                    <Header content='매주 목요일 오후 7시' />
                    <List>
                        <List.Item>매 주간 목요일 오후 7시에</List.Item>
                        <List.Item>West Ryde 교회당에서 있습니다.</List.Item>
                        <List.Item>함께 마음을 다하여 하나님께 영광을 돌립시다.</List.Item>
                    </List>
                </Grid.Column>
            </Grid>
        </Tab.Pane>
    );
};

export default TheHeartTab;
