import React, { Fragment, useContext, useEffect } from 'react';
import { Container } from 'semantic-ui-react';
import NavBar from '../../feature/nav/NavBar';
import { observer } from 'mobx-react-lite';
import { Route, withRouter, RouteComponentProps, Switch, Redirect } from 'react-router-dom';
import BulletinForm from '../../feature/bulletin/form/BulletinForm';
import GroupForm from '../../feature/group/form/GroupForm';
import NotFound from './NotFound';
import { ToastContainer } from 'react-toastify';
import { RootStoreContext } from '../../app/stores/rootStore';
import LoadingComponent from './LoadingComponent';
import ModalContainer from '../common/modals/ModalContainer';
import ProfilePage from '../../feature/profiles/ProfilePage';
import PrivateRoute from './PrivateRoute';
import BulletinDashboard from '../../feature/bulletin/dashboard/BulletinDashboard';
import GroupDashboard from '../../feature/group/dashboard/GroupDashboard';
import GroupDetails from '../../feature/group/detail/GroupDetails';
import ReportDetails from '../../feature/report/detail/ReportDetails';
import ReportForm from '../../feature/report/form/ReportForm';
import ReportDashboard from '../../feature/report/dashboard/ReportDashboard';
import ApprovalDashboard from '../../feature/approval/ApprovalDashboard';
import CityServicePage from '../../feature/service/CityServicePage';
import InternationalMinistryPage from '../../feature/service/InternationalMinistryPage';
import ServiceTheHeartPage from '../../feature/service/ServiceTheHeart';
import WestRydePage from '../../feature/westRyde/WestRydePage';
import ChatswoodPage from '../../feature/chatswood/ChatswoodPage';
import ChurchPage from '../../feature/church/ChurchPage';
import ContactsPage from '../../feature/church/ContactsPage';
import EventPage from '../../feature/event/EventPage';
import { createMedia } from '@artsy/fresnel';
import HomePage from '../../feature/home/HomePage';
import NavBarMobile from '../../feature/nav/NavBarMobile';
import BulletinPhotoForm from '../../feature/bulletin/form/BulletinPhotoForm';
import BulletinDetail from '../../feature/bulletin/BulletinDetail';
import BulletinMoibleDetail from '../../feature/bulletin/mobile/BulletinMoibleDetail';
import VideoForm from '../../feature/video/form/VideoForm';
import VideoDashboard from '../../feature/video/dashboard/VideoDashboard';
import VideoDetail from '../../feature/video/VideoDetail';
import VideoPage from '../../feature/video/VideoPage';
import OrganisationDashboard from '../../feature/organisation/dashboard/OrganisationDashboard';
import OrganisationForm from '../../feature/organisation/form/OrganisationForm';
import AnnouncementPage from '../../feature/bulletin/AnnouncementPage';
import AnnouncementDetail from '../../feature/bulletin/AnnouncementDetail';
import WestRydeBulletinPage from '../../feature/bulletin/WestRydeBulletinPage';
import WestRydeMobileBulletinPage from '../../feature/bulletin/mobile/WestRydeBulletinMobilePage';
import CityBulletinPage from '../../feature/bulletin/CityBulletinPage';
import CityMobileBulletinMobilePage from '../../feature/bulletin/mobile/CityMobileBulletinMobilePage';
import VideoEventPage from '../../feature/video/VideoEventPage';
import VideoIntroPage from '../../feature/video/VideoIntroPage';
import VideoPraisePage from '../../feature/video/VideoPraisePage';
import VerifyEmail from '../../feature/user/VerifyEmail';
import RegisterSuccess from '../../feature/user/RegisterSuccess';
import ChangePassword from '../../feature/user/ChangePassword';
import OrganisationTree from '../../feature/organisation/tree/OrganisationTree';
import GalleryForm from '../../feature/gallery/form/GalleryForm';
import HelpForm from '../../feature/help/form/HelpForm';
import HelpDashboard from '../../feature/help/dashboard/HelpDashboard';
import GalleryDashboard from '../../feature/gallery/dashboard/GalleryDashboard';
import JuanUserForm from '../../feature/juanUser/form/JuanUserForm';
import JuanUserDashboard from '../../feature/juanUser/dashboard/JuanUserDashboard';
import GalleryPhotoForm from '../../feature/gallery/form/GalleryPhotoForm';
import GalleryDetail from '../../feature/gallery/GalleryDetail';
import GalleryPage from '../../feature/gallery/GalleryPage';
import GalleryMobilePage from '../../feature/gallery/GalleryMobilePage';
import BelieverDashboard from '../../feature/believer/dashboard/BelieverDashboard';
import NewBelieverDashboard from '../../feature/believer/dashboard/NewBelieverDashboard';
import BelieverForm from '../../feature/believer/form/BelieverForm';
import NewComerDashboard from '../../feature/newComer/dashboard/NewComerDashboard';
import NewComerForm from '../../feature/newComer/form/NewComerForm';
import BelieverCardDashboard from '../../feature/believer/dashboard/BelieverCardDashboard';
import NewBelieverCardDashboard from '../../feature/believer/dashboard/NewBelieverCardDashboard';
import NewBelieverForm from '../../feature/believer/form/NewBelieverForm';
import NewComerDetail from '../../feature/newComer/NewComerDetail';
import TreeAndForm from '../../feature/organisation/treeForm/TreeAndForm';
import GalleryTree from '../../feature/gallery/tree/GalleryTree';
import AssignOrgForm from '../../feature/believer/form/AssignOrgForm';
import BelieverTree from '../../feature/believer/tree/BelieverTree';
import LoginFormMobile from '../../feature/user/LoginFormMobile';
import CommonStore from '../stores/commonStore';
import UserStore from '../stores/userStore';
import SundayWorship from '../../feature/service/SundayWorship';

const { MediaContextProvider, Media } = createMedia({
	breakpoints: {
		mobile: 0,
		tablet: 768,
		computer: 1024,
	},
});

const App: React.FC<RouteComponentProps> = ({ location }) => {
	const rootStore = useContext(RootStoreContext);
	const { commonStore, userStore } = rootStore;

	useEffect(() => {
		if (commonStore.token) {
			userStore.getUser().finally(() => commonStore.setAppLoaded());
		} else {
			commonStore.setAppLoaded();
		}
	}, [CommonStore, UserStore]);

	if (!commonStore.appLoaded) {
		return <LoadingComponent content="Loading app..." />;
	}

	let children = (
		<Fragment>
			<Route exact path="/">
				<Redirect to="/home" />
			</Route>
			<Route
				path={'/(.+)'}
				render={() => (
					<Switch>
						<Route exact path="/home" component={HomePage} />
						<Route exact path="/service-city" component={CityServicePage} />
						<Route exact path="/service-the-heart" component={ServiceTheHeartPage} />
						<Route exact path="/sunday-worship" component={SundayWorship} />
						<Route exact path="/international-ministry" component={InternationalMinistryPage} />

						<Route exact path="/west-ryde" component={WestRydePage} />
						<Route exact path="/chatswood" component={ChatswoodPage} />

						<Route exact path="/church" component={ChurchPage} />

						<Route exact path="/contacts" component={ContactsPage} />

						<Route exact path="/event" component={EventPage} />

						<Route exact path="/announcement" component={AnnouncementPage} />
						<Route exact path="/announcement/:id" component={AnnouncementDetail} />

						<Route exact path="/video_event" component={VideoEventPage} />
						<Route exact path="/video_intro" component={VideoIntroPage} />
						<Route exact path="/video_praise" component={VideoPraisePage} />

						<Route exact path="/video" component={VideoPage} />
						<Route exact path="/video/:id" component={VideoDetail} />

						<Route exact path="/user/verifyEmail" component={VerifyEmail} />
						<Route exact path="/user/registerSuccess" component={RegisterSuccess} />

						 <PrivateRoute exact path="/videos" component={VideoDashboard} />
						<PrivateRoute key={location.key} path={['/createVideo', '/manageVideo/:id']}
							component={VideoForm} />

						<Route exact path="/westryde_bulletin" component={WestRydeBulletinPage} />
						<Route exact path="/westryde_bulletins" component={WestRydeMobileBulletinPage} />
						<Route exact path="/city_bulletin" component={CityBulletinPage} />
						<Route exact path="/city_bulletins" component={CityMobileBulletinMobilePage} />
						<Route exact path="/bulletin/:id" component={BulletinDetail} />
						<Route exact path="/bulletinMobile/:id" component={BulletinMoibleDetail} />
						<PrivateRoute exact path="/bulletins" component={BulletinDashboard} />
						<PrivateRoute key={location.key} path={['/manageBulletinPhoto/:id']}
							component={BulletinPhotoForm} />
						<PrivateRoute key={location.key} path={['/createBulletin', '/manageBulletin/:id']}
							component={BulletinForm} />

						<PrivateRoute exact path="/organisations" component={OrganisationDashboard} />
						<PrivateRoute exact path="/organisationTree" component={OrganisationTree} />
						<PrivateRoute exact path="/assignOrgForm/:believerId" component={AssignOrgForm} />
						<PrivateRoute key={location.key} path={['/createOrganisationTree', '/manageOrganisationTree/:id']}
							component={TreeAndForm} />
						<PrivateRoute key={location.key} path={['/createOrganisation', '/manageOrganisation/:id']}
							component={OrganisationForm} />

						<Route exact path="/galleries" component={GalleryPage} />
						<Route exact path="/galleriesMobile" component={GalleryMobilePage} />
						<Route exact path="/gallery/:id" component={GalleryDetail} />
						<PrivateRoute exact path="/galleryTree" component={GalleryTree} />
						<PrivateRoute exact path="/galleryDashboard" component={GalleryDashboard} />
						<PrivateRoute key={location.key} path={['/createGallery', '/manageGallery/:id']}
							component={GalleryForm} />
						<PrivateRoute key={location.key} path={['/manageGalleryPhoto/:id']}
							component={GalleryPhotoForm} />

						<PrivateRoute exact path="/juanUsers" component={JuanUserDashboard} />
						<PrivateRoute key={location.key} path={['/createJuanUser', '/manageJuanUser/:id']}
							component={JuanUserForm} />

						<PrivateRoute exact path="/believerCards" component={BelieverCardDashboard} />
						<PrivateRoute exact path="/believerTree" component={BelieverTree} />
						<PrivateRoute exact path="/believers" component={BelieverDashboard} />
						<PrivateRoute key={location.key} path={['/createBeliever', '/manageBeliever/:id']}
							component={BelieverForm} />

						<PrivateRoute exact path={['/newComers', '/newComersMobile']} component={NewComerDashboard} />
						<PrivateRoute key={location.key} path={['/createNewComer', '/manageNewComer/:id', '/createNewComerMobile', '/manageNewComerMobile/:id']}
							component={NewComerForm} />  

						<PrivateRoute key={location.key} path={['/newComers/:id', '/newComersMobile/:id']}
							component={NewComerDetail} />
						<PrivateRoute exact path="/newBelievers" component={NewBelieverDashboard} />
						<PrivateRoute exact path="/newBelieverCards" component={NewBelieverCardDashboard} />

						<PrivateRoute key={location.key} 
							path={['/createNewBeliever/:newComerId', '/manageNewBeliever/:newComerId/:id',
							'/createNewBelieverMobile/:newComerId', '/manageNewBelieverMobile/:newComerId/:id',]}
							component={NewBelieverForm} />

						<PrivateRoute exact path="/helps" component={HelpDashboard} />
						<PrivateRoute key={location.key} path={['/createHelp', '/manageHelp/:id']}
							component={HelpForm} />

						<PrivateRoute exact path="/groups" component={GroupDashboard} />
						<PrivateRoute path="/groups/:id" component={GroupDetails} />
						<PrivateRoute key={location.key} path={['/createGroup', '/manageGroup/:id']}
							component={GroupForm} />

						<PrivateRoute exact path="/reports" component={ReportDashboard} />
						<PrivateRoute key={location.key} path={['/createReport', '/manageReport/:id']}
							component={ReportForm} />
						<PrivateRoute path="/reports/:id" component={ReportDetails} />

						<PrivateRoute exact path="/approvals" component={ApprovalDashboard} />
						<PrivateRoute path="/changePassword" component={ChangePassword} />
						<PrivateRoute path="/profile/:userName" component={ProfilePage} />
						<Route exact path="/login" component={LoginFormMobile} />
						<PrivateRoute component={NotFound} />
					</Switch>
				)} />
		</Fragment>
	)

	return (
		<Fragment>
			<ModalContainer />
			<ToastContainer position="top-right" />
			<Fragment>
				<MediaContextProvider>
					<Media greaterThanOrEqual='tablet'>
						<NavBar pathName={location.pathname} />
						<Container style={{ paddingTop: '6em' }}>
							{children}
						</Container>
				</Media>
					<Media lessThan='tablet'>
						<NavBarMobile pathName={location.pathname}
							children={children} />
					</Media>
				</MediaContextProvider>
			</Fragment>
		</Fragment>
	);
};

export default withRouter(observer(App));


// import PrayerForm from '../../feature/prayer/form/PrayerForm';
// import PrayerDashboard from '../../feature/prayer/dashboard/PrayerDashboard';
// import PrayerDetail from '../../feature/prayer/PrayerDetail';
// import PrayerPage from '../../feature/prayer/PrayerPage';
// import PrayerMobilePage from '../../feature/prayer/PrayerMobilePage';


/* <Route exact path="/prayersMobile" component={PrayerMobilePage} />
<Route exact path="/prayers" component={PrayerPage} />
<PrivateRoute exact path="/prayerDashboard" component={PrayerDashboard} />
<PrivateRoute key={location.key} path={['/createPrayer', '/managePrayer/:id']}
	component={PrayerForm} />
<Route exact path="/prayer/:id" component={PrayerDetail} />
 */