import React, { useContext } from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import { Form, Button,  Divider } from 'semantic-ui-react';
import TextInput from '../../app/common/form/TextInput';
import { RootStoreContext } from '../../app/stores/rootStore';
import { IUserFormValues } from '../../app/models/user';
import { FORM_ERROR } from 'final-form';
import { combineValidators, isRequired } from 'revalidate';
import ErrorMessage from '../../app/common/form/ErrorMessage';
import { observer } from 'mobx-react-lite';

const validate = combineValidators({
	email: isRequired('email'),
	password: isRequired('password'),
})

const LoginForm = () => {
	const rootStore = useContext(RootStoreContext);
	const { login } = rootStore.userStore;
	return (
		<FinalForm
			onSubmit={(values: IUserFormValues) => login(values).catch(error => ({
				[FORM_ERROR]: error
			}))
			}
			validate={validate}
			render={({ handleSubmit,
				submitting,
				submitError,
				invalid,
				pristine,
				dirtySinceLastSubmit }) => (
				<Form onSubmit={handleSubmit} error >
					<Divider horizontal>Login
					</Divider>					
					<Field name="email" component={TextInput} placeholder="Email" />
					<Field name="password" component={TextInput} placeholder="password" type="password" />
					{submitError && !dirtySinceLastSubmit &&
						<ErrorMessage error={submitError} />}
					<Button disabled={(invalid && !dirtySinceLastSubmit) || pristine}
						color='blue' loading={submitting} content="Login" fluid />
				</Form>
			)}
		/>
	);
};

export default observer(LoginForm);


 
// import SocialLogin from './SocialLogin';
	
//<Form onSubmit={handleSubmit} error autoComplete='off'> 
