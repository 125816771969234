import React from "react";
import { Link } from "react-router-dom";
import { Table } from "semantic-ui-react";

interface IProps {
    id : string;
    title: string
    link:string,
    filePath:string,
}

const TwoColumnTableRowWithDownload: React.FC<IProps> = ({ id, title, filePath, link }) => {
    return (
        <Table.Row key={id}>
            <Table.Cell width={12}>
                {
                    link ? <Link to={link}>{title}</Link>
                    : <span>{title}</span>
                }                
            </Table.Cell>
            <Table.Cell width={4}>
                {
                    filePath ?
                        (
                            <a href={filePath} download> - Download - </a>
                        ) :
                        ''
                }
            </Table.Cell>
        </Table.Row>
    );
}

export default TwoColumnTableRowWithDownload;