import React, { useContext, useEffect, useState } from 'react';
import { Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import InfiniteScroll from 'react-infinite-scroller';
import { RootStoreContext } from '../../app/stores/rootStore';
import ApprovalListItemPlaceholder from './ApprovalListItemPlaceholder';
import ApprovalList from './ApprovalList';
import ApprovalFilters from './ApprovalFilters';

const ApprovalDashboard: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const { loadApprovals, loadingInitial, setPage, page, totalPages } = rootStore.approvalStore;

    const [loadingNext, setLoadingNext] = useState(false);

    const handleGetNext = () => {
        setLoadingNext(true);
        setPage(page + 1);
        loadApprovals().then(() => setLoadingNext(false));
    };

    useEffect(() => {
        loadApprovals();
    }, [loadApprovals]);

    return (
        <Grid>
            <Grid.Column width={10}>
                {loadingInitial ? (
                    <ApprovalListItemPlaceholder />
                ) : (
                        <InfiniteScroll
                            pageStart={0}
                            loadMore={handleGetNext}
                            hasMore={!loadingNext && page + 1 < totalPages}
                            initialLoad={false}
                        >
                            <ApprovalList />
                        </InfiniteScroll>
                    )}
            </Grid.Column>
            <Grid.Column width={6}>
                <ApprovalFilters />
            </Grid.Column>
        </Grid>
    );
};

export default observer(ApprovalDashboard);
