import { observer } from 'mobx-react-lite';
import React, { useState, useContext, useEffect, Fragment } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Container, Grid, Header } from 'semantic-ui-react';
import { IGallery } from '../../app/models/gallery';
import { RootStoreContext } from '../../app/stores/rootStore';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { format } from 'date-fns';

interface DetailParams {
    id: string;
}

const GalleryDetail: React.FC<RouteComponentProps<DetailParams>> = ({ match, history }) => {
    const rootStore = useContext(RootStoreContext);
    const { loadGallery, getGalleryPhotos } = rootStore.galleryStore;

    const [gallery, setGallery] = useState<IGallery>();

    useEffect(() => {
        if (match.params.id) {
            loadGallery(match.params.id, true)
                .then((gallery) => {
                    setGallery(gallery);
                })
                .finally();
        }
    }, [loadGallery, match.params.id]);

    return (
        <Fragment>
            {gallery &&
            <Fragment>
                <Grid style={{ marginTop: 25, paddingTop: 10, backgroundColor: 'white' }}>
                            
                            <Grid.Column width={12}>
                                <Container key='galleryTitle'>
                                    <Header floated="left"  content={gallery.title }/>
                                </Container>
                                 <Container  key='galleryDate'
                                     textAlign='left'>{format(new Date(gallery.date),'dd MMM yyyy')}</Container>
                                 { gallery.description && 
                                 (<Container key='galleryDesc' textAlign='left' style={{paddingTop:20}}>
                                        <div dangerouslySetInnerHTML={{ __html: gallery.description.replace(/\n/g, "<br />") }} />
                                </Container>)                                                                  
                                 }

                            </Grid.Column>                               
                            <Grid.Column width={4}>
                                <Button
                                    onClick={() => history.goBack()}
                                    floated="right"
                                    basic
                                    content="Go Back"
                                    style={{ marginRight: 10 }}
                                />
                            </Grid.Column>                               





                </Grid>
                <Grid>
                    <Grid.Column width={16}>
                        <Carousel>
                            {getGalleryPhotos.map((photo) => {
                                var photoUrl = photo.url;
                                if (photoUrl.includes("upload") && !photoUrl.includes("q_auto:best")) {
                                    photoUrl = photoUrl.replace("upload", "upload/q_auto:best");
                                }
                                return (<div key={photo.id}>
                                    <img src={photoUrl} alt="galleryImage" />
                                </div>)
                            })
                            }
                        </Carousel>
                    </Grid.Column>
                </Grid>
            </Fragment>
            }
        </Fragment>
    );
};

export default observer(GalleryDetail);


