import React, { useState, useRef } from 'react';
import { Segment,Button, Grid, Form as SemanticForm } from 'semantic-ui-react';
import { v4 as uuid } from 'uuid';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import BasicErrorMessage from '../../../app/common/form/BasicErrorMessage';
import TextInputFocusField from '../../../app/common/formikForm/TextInputFocusField';
import CheckBoxField from '../../../app/common/formikForm/CheckBoxField';
import TextAreaField from '../../../app/common/formikForm/TextAreaField';
import "./FormItem.css";
import SearchField from '../../../app/common/search/SearchField';

interface IProps {
    createOrganisation: (organisation: any) => any;
    editOrganisation: (organisation: any) => any;
    initFormItemValues:()=>void;
    cancel:()=>void;
    organisation: any;
    getDropDownlOrganisations: any[];
    loadingDropDown:boolean;
    submitting:boolean;
    errorMessage:string;
    history:any;
    initialSearchValue: string;
}

const validationObject = Yup.object({
    name :Yup.string().required('The name is required.').max(100, 'max length is 100 characters'),
    description :Yup.string().required('The description is required').max(2000, 'max length is 2000 characters'),
});

const FormItem : React.FC<IProps> = ({organisation, createOrganisation, editOrganisation,
    getDropDownlOrganisations, loadingDropDown, submitting, errorMessage,
    history, initFormItemValues, cancel, initialSearchValue }) => {

    const parentRef = useRef<any | null>(null);

    const [isActive, setIsActive] = useState(true);
    
    const handlFormikFormSubmit = (organisation: any) => {
        let updatedOrganisation = {
            ...organisation,
            isActive:isActive,
        };

        if (parentRef && parentRef &&  parentRef.current ){
            updatedOrganisation.parentName =parentRef.current.state!.value;
        }

        if (!organisation.id) {
            updatedOrganisation.id = uuid();
            createOrganisation(updatedOrganisation).then(()=>{
                initFormItemValues();
                history.push(`/createOrganisationTree`);
            });

        } else {
            editOrganisation(updatedOrganisation).then(()=>{
                initFormItemValues();
                history.push(`/createOrganisationTree`);
            });
        }
    };

    const handleCheckBoxClick = (event: React.ChangeEvent<HTMLInputElement>)=> {
        if (event.target.name ==='isActive'){
            setIsActive(event.target.checked)
        }
    }
    
    return (<Grid.Column width={8}>
            <Segment clearing>
                {
                    loadingDropDown === false && 
                    (
                        <Formik validationSchema = {validationObject}
                        enableReinitialize initialValues={organisation} 
                        onSubmit={values=> handlFormikFormSubmit(values)}>
                        {({values, handleSubmit, isValid, isSubmitting, dirty}) =>(
                            <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>      
                                <SemanticForm.Field>
                                   <BasicErrorMessage content= {errorMessage} />       
                                </SemanticForm.Field>   
                                {
                                ( getDropDownlOrganisations && getDropDownlOrganisations.length > 0) && ( 
                                    <SemanticForm.Field>
                                        <SearchField 
                                            source = {getDropDownlOrganisations}
                                            intialSearchValue ={initialSearchValue}
                                            forwardedRef = {parentRef}
                                            name ='parentName'
                                        />       
                                    </SemanticForm.Field>) }

                                <TextInputFocusField name="name" placeholder="Name" />    
                                <CheckBoxField text='IsActive' name='isActive' onChange={(e)=>handleCheckBoxClick(e)}
                                    checked={isActive===true} disabled={false}/>

                                <TextAreaField type='textarea' name="description" placeholder="Description"/>                            

                                <Button
                                    floated="right"
                                    loading={submitting}
                                    disabled={!isValid || isSubmitting } positive
                                    type="submit"
                                    content={(organisation && organisation.id)? "Edit" : "Add"}
                                />
                                <Button
                                    onClick={() => {
                                        cancel();
                                        history.push(`/createOrganisationTree`)
                                    }}
                                    floated="right"
                                    type="button"
                                    content="Cancel"
                                />
                            </Form>
                        )}
    
                    </Formik>
                    )

                }
            </Segment>
        </Grid.Column>);
};

export default FormItem;



// useEffect(() => {
//     console.log('useEffect - organisation Form');

//     loadDropDownlOrganisations().then((orgs) =>{
//         if (match.params.id) {
//             loadOrganisation(match.params.id)
//                 .then((organisation) => {
//                     setOrganisation(organisation);
//                     if (organisation.parentName){
//                         setIntialSearchValue(organisation.parentName)
//                     }                    
//                     setIsActive(organisation.isActive);
//                     setLoadingSearch(false);
//                 })
//                 .finally();
//         }
//         else{
//             setLoadingSearch(false);
//         }
//     });
// }, []);