import React, { useContext } from 'react';
import { Item, Button, Segment, Header, List, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { IJuanUser } from '../../../app/models/juanUser';
import ConfirmationModal from '../../../app/common/modals/ConfirmationModal';
import { ImagePaths } from '../../../app/common/constants/imagePaths';
import { getRoleText } from '../../../app/common/constants/roles';

const JuanUserListItem: React.FC<{ juanUser: IJuanUser }> = ({ juanUser }) => {
    const rootStore = useContext(RootStoreContext);
    const { openModal } = rootStore.modalStore;
    const { deleteById } = rootStore.juanUserStore;

    return (
        <Segment.Group key={juanUser.id}>
            <Segment>
                <Item.Group>
                        <Item>
							<Item.Image avatar size="tiny" src={juanUser.url || ImagePaths.defaultUser} />
							<Item.Content>
								<Header as="h3">{juanUser.displayName}</Header>
                                <span><br/>{juanUser.email}</span>
                                {
                                    juanUser.emailConfirmed
                                    && ( <span><br/> confirm email : <Icon name ="check"/></span>)
                                }
							</Item.Content>
                            <Item.Description>
                                <List horizontal link>
                                    {
                                        juanUser.role && 
                                        (<List.Item>                    
                                            <Icon name='user outline' />
                                                {getRoleText(juanUser.role)}
                                        </List.Item>
                                        )
                                    }
                                </List>              

                            </Item.Description>
						</Item>
                </Item.Group>
            </Segment>
            <Segment clearing>
                <Button floated="right" content="Delete" color="red"
                    onClick={() => openModal(<ConfirmationModal
                        id={juanUser.id}
                        subject={'Delete'}
                        message={'Are you sure you want to delete it?'}
                        confirmById={deleteById}
                    />)}
                />
                <Button as={Link} to={`/manageJuanUser/${juanUser.id}`} floated="right" content="Edit" color="blue" />
            </Segment>
        </Segment.Group>
    );
};

export default JuanUserListItem;