import React, { Fragment, useContext } from 'react';
import { Menu, Header } from 'semantic-ui-react';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import { ReportStatus } from '../../../app/common/constants/reportStatus';

const ReportFilters = () => {
    const rootStore = useContext(RootStoreContext);
    const { status, setStatus } = rootStore.reportStore;

    return (
        <Fragment>
            <Menu vertical size={'large'} style={{ width: '100%', marginTop: 5 }}>
                <Header icon={'filter'} attached color={'blue'} content={'Filters'} />
                <Menu.Item
                    active={status === ReportStatus.Pending}
                    onClick={() => setStatus(ReportStatus.Pending)}
                    color={'blue'}
                    name={ReportStatus.Pending}
                    content={ReportStatus.Pending} />
                <Menu.Item
                    active={status === ReportStatus.Approved}
                    onClick={() => setStatus(ReportStatus.Approved)}
                    color={'blue'}
                    name={ReportStatus.Approved}
                    content={ReportStatus.Approved} />
                <Menu.Item
                    active={status === ReportStatus.Declined}
                    onClick={() => setStatus(ReportStatus.Declined)}
                    color={'blue'}
                    name={ReportStatus.Declined}
                    content={ReportStatus.Declined} />
            </Menu>
        </Fragment>
    );
};

export default observer(ReportFilters);
