import { RootStore } from './rootStore';
import { observable, action, computed, runInAction } from 'mobx';
import agent from '../api/agent';
import { history } from '../..';
import { toast } from 'react-toastify';
import { IPrayer } from '../models/prayer';

export default class PrayerStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @observable optionsRegistry = new Map();
    @observable loadingInitial = true;
    @observable submitting = false;
    @observable errorMessage: string = '';

    @observable prayer: IPrayer | null = null;
    @observable deleteErrorMessage: string ='';

    @observable currentOrganisationId ='';

    // list
    @observable prayerRegistry = new Map();
    @observable prayerCount = 0;
    @observable currentPage = 1;
    @observable totalPage = 1;
    @observable pageSize: number = 7;

    @computed get getPrayers() {
        if (this.prayerRegistry.size >= 1) {
            return Array.from(this.prayerRegistry.values());
        }
        return [];
    }
    
    @action setPageSize = (pageSize: number) => {
        this.pageSize = pageSize;
    }

    @action setCurrentPage = (page: number) => {
        this.currentPage = page;
    }

    @action setCurrentOrganisationId = (organisationId:string) => {
        this.currentOrganisationId = organisationId;
    }

    @action createPrayer = async (Prayer: IPrayer) => {
        this.submitting = true;
        try {
            await agent.Prayers.create(Prayer);

            runInAction('creating Prayer', () => {
                this.submitting = false;
                toast.info(Prayer.title + 'is created.');
                history.push(`/prayerDashboard`);
            });

        } catch (error) {
            runInAction('create Prayers error ', () => {
                this.submitting = false;
                console.log(error);
                // this.errorMessage =error.statusText;
            });
            toast.error('Problem submitting data');
        }
    };

    @action editPrayer = async (Prayer: IPrayer) => {
        this.submitting = true;
        try {
            await agent.Prayers.update(Prayer);

            runInAction('editing Prayer', () => {
                this.submitting = false;
            });
            history.push(`/prayerDashboard`);
        } catch (error) {
            runInAction('create Prayer error ', () => {
                this.submitting = false;
                console.log(error);
                // this.errorMessage =error.statusText;
            });
            toast.error('Problem submitting data');
        }
    };

    @action loadPrayers = async () => {
        try {
            const params = new URLSearchParams();
            params.append('currentPage', `${this.currentPage}`);
            params.append('pageSize', `${this.pageSize}`);
            params.append('organisationId', `${this.currentOrganisationId}`);
            
            let prayersResourceModel = await agent.Prayers.list(params);

            runInAction('loading prayers', () => {
                if (prayersResourceModel) {
                    this.prayerRegistry.clear();
                    const { currentPage, count, totalPage, data } = prayersResourceModel;
                    data.forEach((prayer: any) => {
                        this.prayerRegistry.set(prayer.id, prayer);
                    });
                    this.currentPage = currentPage;
                    this.totalPage = totalPage;
                    this.prayerCount = count;
                    this.loadingInitial = false;
                }
            });
        } catch (error) {
            runInAction('load prayers error', () => {
                this.loadingInitial = false;
            });
            throw error;
        }
    };

    @action loadPrayer = async (id: string) => {
        try {
            let prayer = await agent.Prayers.details(id);

            runInAction('loading prayer', () => {
                this.loadingInitial = false;
                this.prayer = prayer;
                this.errorMessage ='';
                this.deleteErrorMessage='';    
            });
            return prayer;

        } catch (error) {
            runInAction('load prayer error', () => {
                this.loadingInitial = false;
            });
            throw error;
        }
    };

    @action deleteById = async (id: string) => {
        try {
            await agent.Prayers.delete(id);
            runInAction('deleting prayer', () => {
                this.prayerRegistry.delete(id);
            });
        } catch (error) {
            runInAction('delete prayer error', () => {
            });
            console.log(error);
        }
    };
}
