import React, { Fragment } from 'react';
import { Placeholder, List } from 'semantic-ui-react';
const TreeViewPlaceHolder = () => {
    return (
        <Fragment>
            <List>
                <List.Item key='treePlaceHolder1'>
                    <List.Icon name='folder open outline' size='large' />
                    <List.Content>                    
                        <Placeholder>
                            <Placeholder.Header>
                                <Placeholder.Line key='placeHolder1a'/>
                                <Placeholder.Line key='placeHolder1b'/>
                            </Placeholder.Header>                            
                        </Placeholder>
                    </List.Content>
                </List.Item>   
                <List.Item key='treePlaceHolder2'>
                    <List.Icon name='folder open outline' size='large' />
                    <List.Content>                    
                        <Placeholder>
                            <Placeholder.Header>
                                <Placeholder.Line key='placeHolder2a' />
                                <Placeholder.Line key='placeHolder2b'/>
                            </Placeholder.Header>
                        </Placeholder>
                    </List.Content>
                </List.Item>                                
            </List>
        </Fragment>
    );
};

export default TreeViewPlaceHolder;


