import React, { useContext } from 'react';
import { Segment, Item, Button } from 'semantic-ui-react';
import { IGroup } from '../../../app/models/group';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { RootStoreContext } from '../../../app/stores/rootStore';
import GroupListItemMembers from '../dashboard/GroupListItemMembers';

const GroupDetailedHeader: React.FC<{ group: IGroup }> = ({ group }) => {
    const rootStore = useContext(RootStoreContext);
    const { user } = rootStore.userStore;

    return (
        <Segment.Group>
            <Segment>
                <Item.Group>
                    <Item>
                        <Item.Image
                            size="tiny"
                            circular
                            src='/assets/icons8group80.png'
                            style={{ marginBottom: 3 }} />
                        <Item.Content>
                            <Item.Header as={Link} to={`/groups/${group.id}`}>
                                {group.title} {group.category}
                            </Item.Header>
                            <Item.Description>
                                {group.description}
                            </Item.Description>
                        </Item.Content>
                    </Item>
                </Item.Group>
            </Segment>
            <Segment secondary>
                <GroupListItemMembers members={group.members} />
            </Segment>
            <Segment clearing attached="bottom">
            </Segment>
        </Segment.Group>
    );
};

export default observer(GroupDetailedHeader);


/* {user && user.role === ROLE_APPROVER &&
    <Button as={Link} to={`/manageGroup/${group.id}`} color="blue" floated="right">
        Manage Group
</Button>} */
