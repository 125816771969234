import React, { useContext } from 'react';
import { Item, Segment, Label, Button } from 'semantic-ui-react';
import { IReport } from '../../../app/models/report';
import { Link } from 'react-router-dom';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { ReportStatus } from '../../../app/common/constants/reportStatus';

const ReportListItem: React.FC<{ report: IReport }> = ({ report }) => {
    const rootStore = useContext(RootStoreContext);
    const { user } = rootStore.userStore;

    return (
        <Segment.Group>
            <Segment>
                <Item.Group>
                    <Item>
                        <Item.Image size="tiny"
                            circular
                            src={report.approverImage || '/assets/user.png'}
                            style={{ marginBottom: 3 }} />
                        <Item.Content>
                            <Item.Header>
                                {report.reportedDate} - {report.title}
                            </Item.Header>
                            <Item.Description>
                                {report.description}
                            </Item.Description>
                            <Item.Description>
                                {report.reportedDate}
                            </Item.Description>
                            <Item.Description>
                                <Label basic color="blue" content={report.status} />
                                {report.status === ReportStatus.Pending && user &&
                                    <Button as={Link} to={`/manageReport/${report.id}`} color="blue" floated="right">
                                        Manage Report
                                </Button>}
                            </Item.Description>
                        </Item.Content>
                    </Item>
                </Item.Group>
            </Segment>
            <Segment clearing>

            </Segment>
        </Segment.Group>
    );
};

export default ReportListItem;

