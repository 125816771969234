import React, { useState } from 'react'
import { Button, Form } from 'semantic-ui-react'
import { SortOrder } from '../constants/sortOrder';

interface IProps {
  search: (searchOrder:string, search:string) => void;
}

const SortOrderOptions = [
  { key: SortOrder.NameAsc , text: 'Name Ascending', value: SortOrder.NameAsc },
  { key: SortOrder.NameDesc, text: 'Name Descending', value: SortOrder.NameDesc },
  { key: SortOrder.DateAsc, text: 'Date Ascending', value: SortOrder.DateAsc },
  { key: SortOrder.DateDesc, text: 'Date Descending', value: SortOrder.DateDesc }
];

const SearchAndSortForm :React.FC<IProps> = ({search}) => {
  const [searchText, setSearchText] = useState<string>('');
  const [searchOrder, setSearchOrder] = useState<string>(SortOrder.NameAsc);

  const searchTextChanged =(e:any)=>{
    setSearchText(e.target.value);
  }

  const searchOrderChanged =(e:any, data:any)=>{
    setSearchOrder(data.value);
  }

  return (
    <Form>
        <Form.Input 
          onChange = {(e)=>searchTextChanged(e)}
          value ={searchText} label='Search' placeholder='Search' />
        <Form.Select label='Sort Order'
          value={searchOrder} onChange={(e, data)=>searchOrderChanged(e, data)}
          placeholder='Select sort order'
          options={SortOrderOptions}
        />
      <Button primary onClick={()=>search(searchOrder, searchText)} type='submit'>Search</Button>
    </Form>
  )
}

export default SearchAndSortForm;

/* <Form.Checkbox label='I agree to the Terms and Conditions' /> */

/* <Form.Group widths={1}>
<Form.Input label='Address' placeholder='Address' />
</Form.Group> */



// Horizontal
// class FormExampleCaptureValues extends Component {
//   state = { name: '', email: '', submittedName: '', submittedEmail: '' }

//   handleChange = (e, { name, value }) => this.setState({ [name]: value })

//   handleSubmit = () => {
//     const { name, email } = this.state

//     this.setState({ submittedName: name, submittedEmail: email })
//   }

//   render() {
//     const { name, email, submittedName, submittedEmail } = this.state

//     return (
//       <div>
//         <Form onSubmit={this.handleSubmit}>
//           <Form.Group>
//             <Form.Input
//               placeholder='Name'
//               name='name'
//               value={name}
//               onChange={this.handleChange}
//             />
//             <Form.Input
//               placeholder='Email'
//               name='email'
//               value={email}
//               onChange={this.handleChange}
//             />
//             <Form.Button content='Submit' />
//           </Form.Group>
//         </Form>
//         <strong>onChange:</strong>
//         <pre>{JSON.stringify({ name, email }, null, 2)}</pre>
//         <strong>onSubmit:</strong>
//         <pre>{JSON.stringify({ submittedName, submittedEmail }, null, 2)}</pre>
//       </div>
//     )
//   }
// }

// export default FormExampleCaptureValues