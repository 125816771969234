import React, { useState, useContext, useEffect } from 'react';
import { Segment, Form, Button, Grid } from 'semantic-ui-react';
import { v4 as uuid } from 'uuid';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps } from 'react-router-dom';
import { Form as FinalForm, Field } from 'react-final-form';
import TextInput from '../../../app/common/form/TextInput';
import TextAreaInput from '../../../app/common/form/TextAreaInput';
import SelectInput from '../../../app/common/form/SelectInput';
import { combineValidators, isRequired, composeValidators, hasLengthGreaterThan } from 'revalidate';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { GroupFormValues } from '../../../app/models/group';

const validators = combineValidators({
	title: isRequired({ message: 'The title is required' }),
	category: isRequired('Category'),
	description: composeValidators(
		isRequired('Description'),
		hasLengthGreaterThan(3)({
			message: 'Description needs to be at least 3 characters'
		})
	)(),
});

interface DetailParams {
	id: string;
}

const categoryOptions = [
	{ key: 'cell', text: '셀', value: 'cell' },
	{ key: 'farm', text: '목장', value: 'farm' }
];

const GroupForm: React.FC<RouteComponentProps<DetailParams>> = ({ match, history }) => {
	const rootStore = useContext(RootStoreContext);
	const { createGroup, editGroup, loadGroup, submitting } = rootStore.groupStore;

	const [group, setGroup] = useState(new GroupFormValues());
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (match.params.id) {
			setLoading(true);
			loadGroup(match.params.id)
				.then((group) => setGroup(group))
				.finally(() => setLoading(false));
		}
	}, [loadGroup, match.params.id]);

	const handlFinalFormSubmit = (group: any) => {
		if (!group.id) {
			let newGroup = {
				...group,
				id: uuid()
			};
			createGroup(newGroup);
		} else {
			editGroup(group);
		}
	};

	return (
		<Grid>
			<Grid.Column width={10}>
				<Segment clearing>
					<FinalForm
						validate={validators}
						initialValues={group}
						onSubmit={handlFinalFormSubmit}
						render={({ handleSubmit, invalid, pristine }) => (
							<Form onSubmit={handleSubmit} loading={loading}>
								<Field name="title" placeholder="name" value={group.title} component={TextInput} />
								<Field
									component={SelectInput}
									options={categoryOptions}
									name="category"
									placeholder="Category"
									value={group.category}
								/>
								<Field
									component={TextAreaInput}
									rows={10}
									name="description"
									placeholder="Description"
									value={group.description}
								/>
								<Button
									floated="right"
									loading={submitting}
									disabled={loading || invalid || pristine}
									positive
									type="submit"
									content="Submit"
								/>
								<Button
									disabled={loading || invalid || pristine}
									onClick={
										group.id ? () => history.push(`/groups/${group.id}`) : () => history.push('/groups')
									}
									floated="right"
									type="button"
									content="Cancel"
								/>
							</Form>
						)}
					/>
				</Segment>
			</Grid.Column>
		</Grid>
	);
};

export default observer(GroupForm);
