import React from 'react';
import { Segment, Button, Placeholder } from 'semantic-ui-react';
const VideoListItemPlaceHolder = () => {
    return (
        <Segment.Group>
            <Segment>
                <Segment.Group>
                    <Segment style={{ minHeight: 25 }}>
                        <Placeholder>
                            <Placeholder.Header>
                                <Placeholder.Line />
                                <Placeholder.Line />
                            </Placeholder.Header>
                        </Placeholder>
                    </Segment>
                    <Segment style={{ minHeight: 10 }}>
                        <Button disabled color='green' floated='right' content='Edit' />
                        <Button disabled color='red' floated='right' content='Delete' />
                    </Segment>
                </Segment.Group>
            </Segment>
        </Segment.Group>
    );
};

export default VideoListItemPlaceHolder;