import React, { Fragment } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Menu, Dropdown } from 'semantic-ui-react';
import * as Styles from '../styles';

interface IProp {
    pathName:string,
    children:JSX.Element
}

const AppManager = ({pathName, children}:IProp) => {
    return (
        <Fragment>
            <Menu.Item name="교회" as={NavLink} to="/organisationTree" text="폴더 뷰"
                style={ pathName === '/organisationTree' ? Styles.activeMenuItemStyle : Styles.menuItemStyle}/>
            <Menu.Item name="Video"
                style={(pathName === '/videos' || pathName === '/createVideo'
                    || pathName.startsWith('/manageVideo')
                ) ? Styles.activeMenuItemStyle : Styles.menuItemStyle}>
                <Dropdown pointing="top left" text='비디오' >
                    <Dropdown.Menu>
                        <Dropdown.Item as={Link} to="/videos" text="목록" />
                        <Dropdown.Item as={Link} to="/createVideo" text="만들기" />
                    </Dropdown.Menu>
                </Dropdown>
            </Menu.Item>

            <Menu.Item name="Gallery"
                style={(pathName === '/createGallery'
                    || pathName ==='/galleryDashboard'                    
                    || pathName ==='/galleryTree'
                    || pathName.startsWith('/manageGallery')
                ) ? Styles.activeMenuItemStyle : Styles.menuItemStyle}>						
                <Dropdown pointing="top left" text='사진첩' >
                    <Dropdown.Menu>								
                        <Dropdown.Item as={Link} to="/galleryTree" text="폴더 뷰" />
                        <Dropdown.Item as={Link} to="/galleryDashboard" text="목록" />									
                        <Dropdown.Item as={Link} to="/createGallery" text="만들기" />
                    </Dropdown.Menu>
                </Dropdown>
            </Menu.Item>

            <Menu.Item name="Bulletin"
                style={(pathName === '/bulletins' || pathName === '/createBulletin'
                    || pathName.startsWith('/manageBulletin')
                    || pathName.startsWith('/manageBulletinPhoto')
                ) ? Styles.activeMenuItemStyle : Styles.menuItemStyle}>
                <Dropdown pointing="top left" text='주보' >
                    <Dropdown.Menu>
                        <Dropdown.Item as={Link} to="/bulletins" text="목록" />
                        <Dropdown.Item as={Link} to="/createBulletin" text="만들기" />
                    </Dropdown.Menu>
                </Dropdown>
            </Menu.Item>
            <Menu.Item name="Help"
                style={(pathName === '/helps' || pathName === '/createHelp'
                    || pathName.startsWith('/manageHelp')) ? Styles.activeMenuItemStyle : Styles.menuItemStyle}>
                <Dropdown pointing="top left" text='도웃말' >
                    <Dropdown.Menu>
                        <Dropdown.Item as={Link} to="/helps" text="목록" />
                        <Dropdown.Item as={Link} to="/createHelp" text="만들기" />
                    </Dropdown.Menu>
                </Dropdown>
            </Menu.Item>
            {children}
    </Fragment>
    );
};

export default AppManager;
