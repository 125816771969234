import React, { useContext } from 'react';
import { Item, Button, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { IOrganisation } from '../../../app/models/organisation';
import ConfirmationModal from '../../../app/common/modals/ConfirmationModal';

const OrganisationListItem: React.FC<{ organisation: IOrganisation }> = ({ organisation }) => {
    const rootStore = useContext(RootStoreContext);
    const { openModal } = rootStore.modalStore;
    const { deleteById } = rootStore.organisationStore;

    return (
        <Segment.Group>
            <Segment>
                <Item.Group>
                    <Item>
                        <Item.Content>
                            <Item.Header>
                                {organisation.name}
                            </Item.Header>
                            <Item.Description>
                                
                            </Item.Description>
                        </Item.Content>
                    </Item>
                </Item.Group>
            </Segment>
            <Segment clearing>
                <Button floated="right" content="Delete" color="red"
                    onClick={() => openModal(<ConfirmationModal
                        id={organisation.id}
                        subject={'Delete your Organisation'}
                        message={'Are you sure you want to delete your Organisation'}
                        confirmById= {deleteById}
                    />)}
                /> 
                <Button as={Link} to={`/manageOrganisation/${organisation.id}`} floated="right" content="Edit" color="blue" />
            </Segment>
        </Segment.Group>
    );
};

export default OrganisationListItem;