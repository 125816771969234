import React, { Fragment, useState, useRef } from 'react';
import { Header, Grid, Button, Divider } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';

interface IProps {
    loading: boolean;
    uploadPhoto: (file: Blob) => void;
    maxWidthHeight:number;
    isResize:boolean;
}

const ResizePhotoUploadWidget: React.FC<IProps> = ({ loading, uploadPhoto, maxWidthHeight, isResize }) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const originalImgRef = useRef<HTMLImageElement>(null);
    const [hasFile, setHasFile] = useState(false);

 
    const fileSelectedHandler = (e: any) => {
        if (originalImgRef && originalImgRef.current){
            setHasFile(true);
            var imageUrl = URL.createObjectURL(e.target.files[0]);
            originalImgRef.current.src = imageUrl!;                

            originalImgRef.current.onload = (event:any) =>{
                if (canvasRef && canvasRef.current){
                    let scaleSize = maxWidthHeight / event.target.width;

                    console.log('original width and height: '+event.target.width + ' '+event.target.height);
                    console.log('max width or height: '+maxWidthHeight);

                    if (isResize && event.target.width > maxWidthHeight && event.target.width >= event.target.height){
                        canvasRef.current.width = maxWidthHeight;
                        canvasRef.current.height = event.target.height * scaleSize;    
                    }
                    else if (isResize && event.target.height > maxWidthHeight && event.target.height > event.target.width){
                        let scaleSize = maxWidthHeight / event.target.height;
                        canvasRef.current.height = maxWidthHeight;
                        canvasRef.current.width = event.target.width * scaleSize;    
                    }

                    else{
                        canvasRef.current.width = event.target.width;
                        canvasRef.current.height = event.target.height;    
                    }

                    const ctx = canvasRef.current.getContext('2d');
                    ctx?.drawImage(event.target, 0, 0, canvasRef.current.width, canvasRef.current.height);                 
                }
            }
        }
    }

    const fileUploadHandler = () => {
        if (canvasRef && canvasRef.current){
            canvasRef.current.toBlob(function(blob) {
                uploadPhoto(blob!);
            });
        }
	};

    return (
        <Fragment>
            <Divider horizontal>Upload File </Divider>
            <Grid>
                {
                    hasFile ? (
                        <Grid.Column width={16}>
                                <Button  onClick={fileUploadHandler} content="Upload" color='blue' loading={loading} />
                        </Grid.Column>
                    ):
                    (
                        <Grid.Column width={16}>
                            <Header color="teal" sub content="Upload Photo" />                    
                            <input type="file" onChange={fileSelectedHandler} accept =".jpg, .jpeg, .png" />                 
                        </Grid.Column>
                    )
                }
 

            </Grid>
                <Divider horizontal>Preview </Divider>
                <Grid>
                    <Grid.Column width={16}>
                        <canvas ref={canvasRef} />
                        <div style={{ display: 'none' }}>
                            <img ref={originalImgRef} alt="originalImg" />
                        </div>
                    </Grid.Column>
                </Grid>
        </Fragment>
    );
};

export default observer(ResizePhotoUploadWidget);

//https://imagekit.io/blog/how-to-resize-image-in-javascript/



// https://medium.com/@650egor/react-30-day-challenge-day-2-image-upload-preview-2d534f8eaaa
//compress images before uploading for performance. 
// https://www.youtube.com/watch?v=bXf_UdyDzSA&t=318s
// https://stackoverflow.com/questions/10313992/upload-html5-canvas-as-a-blob/13889188

    // const fileSelectedHandler = (e: any) => {
	// 	const file = e.target.files[0];
	// 	const reader = new FileReader();
	// 	reader.readAsDataURL(file);

    //     reader.onload = (event)=>{            
    //         const imgElement = document.createElement('img');
    //         if (event.target && event.target.result){
    //             imgElement.src = event.target.result;
    //         }

    //         // if (canvasRef && canvasRef.current){
    //         //     var context = canvasRef.current.getContext('2d');
    //         // }
	// 	// 	const imgElement = document.createElement('img');
	// 	// 	imgElement.src = event.target.result;
	// 	// 	document.querySelector("#iput").src = event.target.result;

    //     }


    //     //setImage(e.target.files[0]);
    // }