import React, { Fragment, useState, useEffect } from 'react';
import { Header, Grid, Button, Divider } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';

interface IProps {
    loading: boolean;
    uploadPhoto: (file: Blob, bulletinId: any, title: string, order: string) => void;
    bulletinId: any;
    defaultOrder: number;
    category: string;
}

const BulletinPhotoUploadWidget: React.FC<IProps> = ({ loading, uploadPhoto, bulletinId, defaultOrder, category }) => {
    const [files, setFiles] = useState<any[]>([]);
    const [image, setImage] = useState<Blob | null>(null);
    const [title, setTitle] = useState('Title ');
    const [order, setOrder] = useState(defaultOrder.toString());

    useEffect(() => {
        console.log('bulletinPhotoUploadWidget');
        return () => {
            files.forEach((file) => URL.revokeObjectURL(file.preview));
        };
    },[]);

    const onChangeHandler = (e: any) => {
        if (e.target.name === 'title') {
            setTitle(e.target.value)
        }
        else if (e.target.name === 'order') {
            setOrder(e.target.value)
        }
    }

    const fileSelectedHandler = (e: any) => {
        setImage(e.target.files[0]);
    }

    const fileUploadHandler = () => {
        uploadPhoto(image!, bulletinId, title, order)
    }

    return (
        <Fragment>
            <Grid>
                <Grid.Column width={10}>
                    <input
                        name="title"
                        placeholder="Title"
                        value={title}
                        onChange={onChangeHandler}
                        style={{ marginRight: 30, width: '200px' }}
                    />
                    <input
                        name="order"
                        placeholder="Order: Please enter an order (number)"
                        value={order}
                        onChange={onChangeHandler}
                        style={{ marginRight: 30, width: '200px' }}
                    />
                </Grid.Column>
            </Grid>
            <Divider horizontal>Upload File </Divider>
            <Grid>
                <Grid.Column width={8}>
                    <Header color="teal" sub content="Upload Photo" />
                    <input type="file" onChange={fileSelectedHandler} />
                    <br />
                    <br />
                    {
                        image &&
                        <Button onClick={fileUploadHandler} content="Upload" color='blue' loading={loading} />
                    }
                </Grid.Column>
            </Grid>
        </Fragment>
    );
};

export default observer(BulletinPhotoUploadWidget);


/* <Divider horizontal>2 Crop & Upload File </Divider>
<Grid>
    <Grid.Column width={3}>
        <Header color="teal" sub content="Step 1 - Add Photo" />
        <PhotoWidgetDropzone setFiles={setFiles} />
    </Grid.Column>
    <Grid.Column width={1} />
    <Grid.Column width={6}>
        <Header sub color="teal" content="Step 2 - Resize image" />
        {files.length > 0 && <BulletinPhotoWidgetCropper setImage={setImage} imagePreview={files[0].preview} category={category} />}
    </Grid.Column>
    <Grid.Column width={1} />
    <Grid.Column width={5}>
        <Header sub color="teal" content="Step 3 - Preview & Upload" />
        {files.length > 0 && (
            <Fragment>
                <div className="img-preview" style={{ minHeight: '400px', overflow: 'hidden' }} />
                <Button.Group widths={2}>
                    <Button positive icon="check" loading={loading} onClick={() => uploadPhoto(image!, bulletinId, title, order)} />
                    <Button icon="close" disabled={loading} onClick={() => setFiles([])} />
                </Button.Group>
            </Fragment>
        )}
    </Grid.Column>
</Grid> */
