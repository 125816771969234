import React, { useContext } from 'react';
import { Item, Button, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { IVideo } from '../../../app/models/video';
import ConfirmationModal from '../../../app/common/modals/ConfirmationModal';
import {format} from 'date-fns';

const VideoListItem: React.FC<{ video: IVideo }> = ({ video }) => {
    const rootStore = useContext(RootStoreContext);
    const { openModal } = rootStore.modalStore;
    const { deleteById } = rootStore.videoStore;

    return (
        <Segment.Group>
            <Segment>
                <Item.Group>
                    <Item>
                        <Item.Content>
                            <Item.Header>
                                {video.category}
                            </Item.Header>
                            <Item.Description>
                                {video.title} - {format(new Date(video.date),'dd MMM yyyy')} 
                            </Item.Description>
                        </Item.Content>
                    </Item>
                </Item.Group>
            </Segment>
            <Segment clearing>
                <Button floated="right" content="Delete" color="red"
                    onClick={() => openModal(<ConfirmationModal
                        id={video.id}
                        subject={'Delete your Video'}
                        message={'Are you sure you want to delete your Video'}
                        confirmById={deleteById}
                    />)}
                />
                <Button as={Link} to={`/manageVideo/${video.id}`} floated="right" content="Edit" color="blue" />
                <Button as={Link} to={`/video/${video.id}`} floated="right" content="View" color="grey" />
            </Segment>
        </Segment.Group>
    );
};

export default VideoListItem;

//                                {video.title} - {new Date(video.date).toLocaleDateString()}
