import React, { useState, useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { RouteComponentProps } from 'react-router-dom';
import { Segment,  Button, Grid, Form as SemanticForm, Image } from 'semantic-ui-react';
import { v4 as uuid } from 'uuid';
import 'suneditor/dist/css/suneditor.min.css';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import TextInputField from '../../../app/common/formikForm/TextInputField';
import DateInputField from '../../../app/common/formikForm/DateInputField';
import { BelieverFormValues } from '../../../app/models/believer';
import TextAreaField from '../../../app/common/formikForm/TextAreaField';
import { BelieverStatus } from '../../../app/common/constants/believerStatus';
import { isMobilePath } from '../../../app/common/constants/paths';
import BasicErrorMessage from '../../../app/common/form/BasicErrorMessage';

const validationSchema = Yup.object({
    name:Yup.string().required('name is required').max(50, 'max length is 100 characters'),
    phone:Yup.string().required('phone is required').max(100, 'max length is 100 characters'),
    description:Yup.string().required('description is required').max(2000, 'max length is 2000 characters'),
});

interface iFormParams {
    id: string;
};

const BelieverForm: React.FC<RouteComponentProps<iFormParams>> = ({ match, history }) => {

    const rootStore = useContext(RootStoreContext);
 
    const { createBeliever, editBeliever, submitting, loadBeliever, errorMessage } = rootStore.believerStore;

    const [believer, setBeliever] = useState(new BelieverFormValues());
    const [image, setImage] = useState<Blob | null>(null);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(()=>{        
        if (isMobilePath(match.path)){
            setIsMobile(true);
        }

        if (match.params.id) {
            loadBeliever(match.params.id)
                .then((believer) => {
                    if (believer.birthday){
                        believer.birthday = new Date(believer.birthday);
                    }
                    setBeliever(believer);
                })
                .finally();
        }
    },[match.params.id, loadBeliever]);

    const handleFormSubmit =(believer:any)=>{
        let formData = new FormData();

        formData.append('name', believer.name);
        formData.append('phone', believer.phone);
        formData.append('kakaotalkId', believer.kakaotalkId);
        formData.append('status', BelieverStatus.Regular);
        formData.append('description', believer.description);

        if (image != null){
            formData.append('image', image!);
        }

        if (believer.birthday){
            var birthday = new Date(believer.birthday);
            formData.append('birthday', birthday.toLocaleDateString("en-AU"));    
        }

        if (!believer.id) {
            var id = uuid();
            formData.append('Id', id);
            createBeliever(formData).then(()=>{
                isMobile ? history.push(`/believersMobile`) : history.push(`/believers`);
            });
            
        } else {
            formData.append('Id', believer.id);
            editBeliever(formData, believer.id).then(()=>{
                isMobile ? history.push(`/believersMobile`) : history.push(`/believers`);
            })
        }
    }

    const fileSelectedHandler = (e: any) => {
        setImage(e.target.files[0]);
    }

    return (<Grid style={{ marginTop: '15px' }}>
        <Grid.Column width={10}>
            <Segment clearing>
                    <Formik enableReinitialize validationSchema = {validationSchema}
                    onSubmit={values=> handleFormSubmit(values)}
                    initialValues={believer}>
                    {( { values, handleSubmit, isValid, isSubmitting, dirty})=>(
                        <Form className='ui form' onSubmit={handleSubmit} autoComplete ='off'>
                            <SemanticForm.Field>
                                <BasicErrorMessage content= {errorMessage} />       
                            </SemanticForm.Field> 
                            <TextInputField type='text' name="name" placeholder="이름"/>   
                            <TextInputField type='text' name="phone" placeholder="전화번호"/>   
                            <TextInputField type='text' name="kakaotalkId" placeholder="카카오톡 아이디"/>   
                            <DateInputField placeholderText='생일' name ='birthday'                                 
                                showTimeSelect={false} timeCaption='time' dateFormat='dd MMM yyyy' />

                            {
                                !believer.url &&
                                (
                                    <SemanticForm.Field>
                                        <input type="file" onChange={fileSelectedHandler} 
                                            accept="image/png, image/jpeg"/> 
                                    </SemanticForm.Field>
                                ) 
                            }
                            
                            <TextAreaField type='textarea' name="description" 
                                placeholder="세례여부, 전공, 비자 종류" />                            

                            <Button
                                floated="right"
                                loading={submitting}
                                disabled={!isValid || isSubmitting }
                                positive
                                type="submit"
                                content="Save" />
                            <Button
                                onClick={() => history.push('/believers')}
                                floated="right"
                                type="button"
                                content="Cancel" />
                        </Form>
                    )}
                </Formik>
            </Segment>
        </Grid.Column>
        <Grid.Column width={6}>
                <Image src ={believer.url}/>
        </Grid.Column>
    </Grid>);
};

export default observer(BelieverForm);
