import React from "react";
import { Link } from "react-router-dom";
import { Table } from "semantic-ui-react";
import { format } from 'date-fns';

interface IProps {
    id : string;
    title: string
    date: string,
    link:string
}

const TwoColumnTableRow: React.FC<IProps> = ({ id, title, date, link }) => {
    return (
        <Table.Row key={id}>
            <Table.Cell width={10}>
                <Link to={link}>{title}</Link>
            </Table.Cell>
            <Table.Cell width={6}>{format(new Date(date),'dd MMM yyyy')}</Table.Cell>
        </Table.Row>
    );
}

export default TwoColumnTableRow;