import GroupStore from './groupStore';
import UserStore from './userStore';
import { createContext } from 'react';
import { configure } from 'mobx';
import CommonStore from './commonStore';
import ModalStore from './modalStore';
import ProfileStore from './profileStore';
import ReportStore from './reportStore';
import ApprovalStore from './approvalStore';
import ServiceStore from './serviceStore';
import BulletinStore from './bulletinStore';
import OrganisationStore from './organisationStore';
import VideoStore from './videoStore';
import PastorStore from './pastorStore';
import HomeStore from './homeStore';
import GalleryStore from './galleryStore';
import PrayerStore from './prayerStore';
import HelpStore from './helpStore';
import JuanUserStore from './juanUserStore';
import BelieverStore from './believerStore';
import NewComerStore from './newComerStore';

configure({ enforceActions: 'always' });


export class RootStore {
	homeStore: HomeStore;
	groupStore: GroupStore;
	userStore: UserStore;
	commonStore: CommonStore;
	modalStore: ModalStore;
	profileStore: ProfileStore;
	reportStore: ReportStore;
	approvalStore: ApprovalStore;
	serviceStore: ServiceStore;
	pastorStore: PastorStore;
	bulletinStore: BulletinStore;
	videoStore: VideoStore;
	organisationStore: OrganisationStore;
	galleryStore : GalleryStore;
	prayerStore : PrayerStore;
	helpStore : HelpStore;
	juanUserStore : JuanUserStore;
	believerStore : BelieverStore;
	newComerStore : NewComerStore;

	constructor() {
		this.homeStore = new HomeStore(this);
		this.groupStore = new GroupStore(this);
		this.userStore = new UserStore(this);
		this.commonStore = new CommonStore(this);
		this.modalStore = new ModalStore(this);
		this.profileStore = new ProfileStore(this);
		this.reportStore = new ReportStore(this);
		this.approvalStore = new ApprovalStore(this);
		this.serviceStore = new ServiceStore(this);
		this.bulletinStore = new BulletinStore(this);
		this.videoStore = new VideoStore(this);
		this.pastorStore = new PastorStore(this);
		this.organisationStore = new OrganisationStore(this);
		this.galleryStore = new GalleryStore(this);
		this.prayerStore = new PrayerStore(this);
		this.helpStore = new HelpStore(this);
		this.juanUserStore = new JuanUserStore(this);
		this.believerStore = new BelieverStore(this);
		this.newComerStore = new NewComerStore(this);
	}
}

export const RootStoreContext = createContext(new RootStore());