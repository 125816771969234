import React, { Fragment, useContext } from 'react';
import { Menu, Header } from 'semantic-ui-react';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';

const CategoryValues = {
    All: 'All',
    Cell: 'Cell',
    Group: 'Farm'
}

const GroupFilters = () => {
    const rootStore = useContext(RootStoreContext);
    const { category, setCategory } = rootStore.groupStore;

    return (
        <Fragment>
            <Menu vertical size={'large'} style={{ width: '100%', marginTop: 5 }}>
                <Header icon={'filter'} attached color={'blue'} content={'Filters'} />
                <Menu.Item
                    active={category === CategoryValues.All}
                    onClick={() => setCategory(CategoryValues.All)}
                    color={'blue'}
                    name={CategoryValues.All}
                    content={CategoryValues.All} />
                <Menu.Item
                    active={category === CategoryValues.Cell}
                    onClick={() => setCategory(CategoryValues.Cell)}
                    color={'blue'}
                    name={CategoryValues.Cell}
                    content='셀' />
                <Menu.Item
                    active={category === CategoryValues.Group}
                    onClick={() => setCategory(CategoryValues.Group)}
                    color={'blue'}
                    name={CategoryValues.Group}
                    content='목장' />
            </Menu>
        </Fragment>
    );
};

export default observer(GroupFilters);
