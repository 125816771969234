import { IBeliever } from "./believer";

export interface INewComer {
    id: string;
    name: string;
    date: Date;
    week1: string;
    week2: string;
    week3: string;
    week4: string;
    week5: string;
}

export interface INewComerDetail {
    id: string;
    name: string;
    date: Date;
    believers: IBeliever[];
}

export class NewComerFormValues {
    id?: string = undefined;
    name: string ='';
    date?: Date = undefined;
    week1: string ='';
    week2: string ='';
    week3: string ='';
    week4: string ='';
    week5: string ='';
}

export interface INewComerResouceModel {
    data: INewComer[];
    count: number;
    currentPage: number;
    totalPage: number;
    pageSize: number;
}