import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, Fragment, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Grid, Item, Segment } from 'semantic-ui-react';
import { RootStoreContext } from '../../app/stores/rootStore';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { format } from 'date-fns';
import NewBelieverList from '../believer/dashboard/NewBelieverList';
import { isMobilePath } from '../../app/common/constants/paths';
import WeeklyNote from '../../app/common/note/WeeklyNote';

interface DetailParams {
    id: string;
}

const NewComerDetail: React.FC<RouteComponentProps<DetailParams>> = ({ match, history }) => {
    const rootStore = useContext(RootStoreContext);    
    const { loadNewComerDetail, newComer, getBelievers } = rootStore.newComerStore;
    const { promoteById } = rootStore.believerStore;

    const { deleteById } = rootStore.believerStore;
    const { openModal } = rootStore.modalStore;
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if (isMobilePath(match.path)){
            setIsMobile(true);
        }

        if (match.params.id) {
            loadNewComerDetail(match.params.id);
        }
    }, [loadNewComerDetail, match.params.id]);

    const handlePromoteById =(id:string, newComerId:string)=>{
        promoteById(id, newComerId).then(()=>{
            isMobile ? history.push(`/newComersMobile/${newComerId}`) : history.push(`/newComers/${newComerId}`);
        })
    }

    const handleDeleteById =(believerId:string)=>{
        deleteById(believerId).then(()=>{
            isMobile ? history.push(`/newComersMobile/${newComer!.id}`) : history.push(`/newComers/${newComer!.id}`);
        })
    }

    return (
        <Grid style={{ marginTop: '15px' }}>
            { newComer && (
                <Fragment>
                    <Grid.Column width={isMobile ? 16 : 11}>
                    <Segment.Group>
                        <Segment>
                            <Item.Group>
                                <Item>
                                    <Item.Content>
                                        <Item.Header>
                                            {newComer.name} - {format(new Date(newComer.date),'dd MMM yyyy')}
                                        </Item.Header>
                                        <Item.Description> 
                {                                    
                    ( newComer.week1 && isMobile)                   
                    &&  <WeeklyNote 
                        week='Week 1' description={newComer.week1}
                    />
                }
                {                                    
                    ( newComer.week2 && isMobile)                   
                    &&  <WeeklyNote 
                        week='Week 2' description={newComer.week2}
                    />
                }
                {                                    
                    ( newComer.week3 && isMobile)                   
                    &&  <WeeklyNote 
                        week='Week 3' description={newComer.week3}
                    />
                }
                {                                    
                    ( newComer.week4 && isMobile)                   
                    &&  <WeeklyNote 
                        week='Week 4' description={newComer.week4}
                    />
                }
                 {                                    
                    ( newComer.week5 && isMobile)                   
                    &&  <WeeklyNote 
                        week='Week 5' description={newComer.week5}
                    />
                }                   
                                    </Item.Description>
                                    </Item.Content>
                                </Item>
                            </Item.Group>
                        </Segment>
                        <Segment clearing>
                            <NewBelieverList
                                    openModal ={openModal}
                                    deleteById = {handleDeleteById}                                
                                    believers={getBelievers}
                                    promoteById ={handlePromoteById}
                                    isMobile={isMobile}/>
                        </Segment>
                    </Segment.Group>
                    </Grid.Column>
                    {
                        (!isMobile) && 
                        (
                            <Grid.Column width={5}>
                                {                                    
                                    newComer.week1 &&  <WeeklyNote 
                                        week='Week 1' description={newComer.week1} />
                                }
                                {                                    
                                    newComer.week2 &&  <WeeklyNote 
                                        week='Week 2' description={newComer.week2}/>
                                }
                                {                                    
                                    newComer.week3 &&  <WeeklyNote 
                                        week='Week 3' description={newComer.week3} />
                                }
                                {                                    
                                    newComer.week4 &&  <WeeklyNote 
                                        week='Week 4' description={newComer.week4}/>
                                }
                                {                                    
                                    newComer.week5 &&  <WeeklyNote 
                                        week='Week 5' description={newComer.week5} />
                                }
                            </Grid.Column>
                        )
                    }
                </Fragment>
            )}
        </Grid>
    );
};

export default observer(NewComerDetail);