import React, { useContext, Fragment } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../app/stores/rootStore';
import ApprovalListItem from './ApprovalListItem';

const GroupList: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const { getApprovals } = rootStore.approvalStore;

    return (
        <Fragment>
            {getApprovals.map((approval) => {
                return <ApprovalListItem key={approval.id} approval={approval} />
            })}
        </Fragment>
    );
};

export default observer(GroupList);
