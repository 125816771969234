import React, { useContext } from 'react';
import { Grid } from 'semantic-ui-react';
import ServiceContent from './ServiceContent';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../app/stores/rootStore';
import HomeHeaderOrg from './HomeHeaderOrg';
import SocialMediaLinks from '../../app/common/socialMedia/SocialMediaLinks';

const HomePage = () => {
	const rootStore = useContext(RootStoreContext);
	const {
		setActiveTab
	} = rootStore.serviceStore;

	return (
		<Grid>
			<Grid.Column width={16}>
        <HomeHeaderOrg />
        <SocialMediaLinks/>
				<ServiceContent setActiveTab={setActiveTab} />
			</Grid.Column>
		</Grid >
	);
};

export default observer(HomePage);

// import HomeHeader from './HomeHeader';
// const { loadMainBanner, getMainBanner } = rootStore.homeStore;
// useEffect(()=> {
// 	loadMainBanner();
// }, [loadMainBanner]);
