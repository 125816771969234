import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import AnnouncementTableRow from './AnnouncementTableRow';
import { Table } from 'semantic-ui-react';

import { RootStoreContext } from '../../app/stores/rootStore';

const AnnouncementTable: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const { getBulletinsByDate } = rootStore.bulletinStore;

    return (
        <Table celled>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>제목</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {getBulletinsByDate.map((bulletin) => {
                    return <AnnouncementTableRow key={bulletin.id} bulletin={bulletin} />
                })}
            </Table.Body>
        </Table>
    );
};

export default observer(AnnouncementTable);