import React, {  useContext, useEffect, useState } from 'react';
import { Grid, List } from 'semantic-ui-react';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import * as Styles from './styles';
import FormItem from './FormItem';
import { RouteComponentProps } from 'react-router';
import TreeViewPlaceHolder from '../../../app/common/PlaceHolder/TreeViewPlaceHolder';
import TreeItem from '../../../app/common/tree/TreeItem';
import { IOrganisation, OrganisationFormValues } from '../../../app/models/organisation';

interface DetailParams {
    id: string;
}

const TreeAndForm: React.FC<RouteComponentProps<DetailParams>> = ({ match, history }) =>{
    const rootStore = useContext(RootStoreContext);
    const { loadingInitial, loadTreeView, getparents, createOrganisation, 
        submitting, getDropDownlOrganisations, loadingDropDown, idOnTree,
        loadDropDownlOrganisations, editOrganisation, errorMessage,
        initialSearchValue, initFormItemValues, setOrganisationTreeItem, getchildren,cancel  } = rootStore.organisationStore;
        
    const [currentOrganisationId, setCurrentOrganisationId] = useState<string>('');
    const [organisation, setOrganisation] = useState(new OrganisationFormValues());
    const [IsLoaded, setIsLoaded] = useState<boolean>(false);

    useEffect(() => { 
        if (!IsLoaded && !errorMessage){
            cancelHandler();
            setIsLoaded(true);                        
        }
        loadTreeView();
        loadDropDownlOrganisations();
    }, [currentOrganisationId]);

    const selectTreeItemHandler= (organisation:IOrganisation, isBottom:boolean, parentName:string) =>{
        setOrganisationTreeItem(organisation, isBottom, parentName);
        setOrganisation(organisation);
        setCurrentOrganisationId(organisation.id);
    }

    const cancelHandler= () =>{
        setOrganisation(new OrganisationFormValues());
        cancel();
    }


    return (
        <Grid style={{ marginTop: '10px' }}>
            <Grid.Column width={8}>
                {
                    loadingInitial ? <TreeViewPlaceHolder /> :
                        (
                            getparents.length===0 ?
                            (<span>최상위 교회가 없습니다.</span>):
                            (                            
                            <div>
                                <List style={Styles.tree}>
                                    {getparents.map((organisation) => {
                                        return <TreeItem key={organisation.id} 
                                            treeItemSize ={3}
                                            idOnTree={idOnTree}
                                            children={getchildren} 
                                            selectTreeItem={selectTreeItemHandler}
                                            organisation={organisation} parentName='' 
                                        />
                                    })}
                                </List>
                            </div>)
                        )
                }
            </Grid.Column>
            <FormItem 
                history ={history}
                errorMessage={errorMessage}
                submitting = {submitting}
                createOrganisation = {createOrganisation} 
                editOrganisation = {editOrganisation}
                organisation ={organisation} 
                initialSearchValue ={initialSearchValue}
                loadingDropDown={loadingDropDown}
                getDropDownlOrganisations={getDropDownlOrganisations}
                initFormItemValues ={initFormItemValues}
                cancel={cancelHandler}                
                />
        </Grid>
    );
};

export default observer(TreeAndForm);

// https://react.semantic-ui.com/elements/icon/


// const [loadingSearch, setLoadingSearch] = useState(true);
// && loadingSearch===false

