import React, { useContext, useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps } from 'react-router-dom';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import { RootStoreContext } from '../../../app/stores/rootStore';
import GroupDetailedHeader from './GroupDetailedHeader';

interface DetailParams {
    id: string;
}

const GroupDetails: React.FC<RouteComponentProps<DetailParams>> = ({ match, history }) => {
    const rootStore = useContext(RootStoreContext);

    const { group, loadGroup, loadingInitial } = rootStore.groupStore;

    useEffect(() => {
        loadGroup(match.params.id);
        console.log('loadGroup');
    }, [loadGroup, match.params.id]);

    if (loadingInitial) {
        return <LoadingComponent content="Loading group" />;
    }

    if (!group) return <h2>Group not found</h2>;

    return (
        <Grid>
            <Grid.Column width={10}>
                <GroupDetailedHeader group={group} />
            </Grid.Column>
            <Grid.Column width={6}>
                {/* {group && group.members &&
                    <GroupDetailedSidebar members={group.members} />
                } */}
            </Grid.Column>
        </Grid>
    );
};

export default observer(GroupDetails);