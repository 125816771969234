import React, { useState } from 'react';
import { Grid, Breadcrumb, Header, Table, Image, List } from 'semantic-ui-react';
import { CitySundayServices, CityEarlyChildhoodServices, CityPrayServices, CityThursdayServices } from '../../app/data/services';
import TableRow from '../../app/common/tables/tableRow3';
import JuanMap from '../../app/common/Maps/JuanMap';

const sections = [
    { key: '예배', content: '예배', link: true },
    { key: 'service-city', content: 'City', active: true },
]

const headerSize = 'large';
const listSize = 'large';

const CityServicePage = () => {
  const [showCityMap, setCityMap ] = useState(false);
  const [showOfficeMap, setOfficeMap ] = useState(false);

  return (
      <Grid>
          <Grid.Column width={16}>
              <Breadcrumb icon='right angle' sections={sections} style={{ margin: '20px' }} />
              <Image src={'/assets/main/city.jpg'} fluid style={{ marginBottom: '20px' }} />

              <Header content='City 주안교회' size={headerSize} />
              <List size={listSize} style={{ backgroundColor: 'white' }}>
                  <List.Item>
                    {
                      showCityMap ? 
                        <span>Scots Presbyterian Church, 44 Margaret St. Sydney NSW 2000</span>
                      :
                      <span style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                      onClick={()=>{setCityMap(true)}}>
                      Scots Presbyterian Church, 44 Margaret St. Sydney NSW 2000
                    </span>                    
                    }                  
                  </List.Item>           
              </List>
              {
                showCityMap ? 
                <JuanMap latitude={-33.8648104} longitude={151.2058118} zoom={17} height={300} />
                : <Image src={'/assets/images/CityMap.png'} />            
              }
              <Header content='주일예배' size={headerSize} />
              <Table celled>
                  <Table.Header>
                      <Table.Row>
                          <Table.HeaderCell></Table.HeaderCell>
                          <Table.HeaderCell>예배시간</Table.HeaderCell>
                          <Table.HeaderCell>장소</Table.HeaderCell>
                      </Table.Row>
                  </Table.Header>
                  <Table.Body>
                      {CitySundayServices.map(service => (
                          <TableRow key={service.id} name={service.name} date={service.date}
                              place={service.place} />
                      ))}
                  </Table.Body>
              </Table>

              <Header content='주일 영아/유아 예배' size={headerSize} />
              <Table celled>
                  <Table.Header>
                      <Table.Row>
                          <Table.HeaderCell></Table.HeaderCell>
                          <Table.HeaderCell>예배시간</Table.HeaderCell>
                          <Table.HeaderCell>장소</Table.HeaderCell>
                      </Table.Row>
                  </Table.Header>
                  <Table.Body>
                      {CityEarlyChildhoodServices.map(service => (
                          <TableRow key={service.id} name={service.name} date={service.date}
                              place={service.place} />
                      ))}
                  </Table.Body>
              </Table>

              <Header content='목요찬양예배' size={headerSize} />
              <Table celled>
                  <Table.Header>
                      <Table.Row>
                          <Table.HeaderCell></Table.HeaderCell>
                          <Table.HeaderCell>예배시간</Table.HeaderCell>
                          <Table.HeaderCell>장소</Table.HeaderCell>
                      </Table.Row>
                  </Table.Header>
                  <Table.Body>
                      {CityThursdayServices.map(service => (
                          <TableRow key={service.id} name={service.name} date={service.date}
                              place={service.place} />
                      ))}
                  </Table.Body>
              </Table>

              <Header content='새벽 예배' size={headerSize} />
              <List>
              <List.Item><List.Header>월요일-금요일</List.Header></List.Item>
                <List.Item>&nbsp; &nbsp;1부: 오전 5:30 West Ryde 교회당</List.Item>
                <List.Item>&nbsp; &nbsp;(2 Station St. West Ryde) </List.Item>
                <List.Item></List.Item>          
                <List.Item>&nbsp; &nbsp;2부: 오전 7시</List.Item>
                <List.Item>&nbsp; &nbsp;줌(ZOOM) ID: 465-471-4497 Password: 463412 </List.Item>
                <List.Item>&nbsp; &nbsp;<a href="https://us04web.zoom.us/j/4654714497">https://us04web.zoom.us/j/4654714497</a></List.Item>
                <List.Item></List.Item>
                <List.Item><List.Header>토요일</List.Header></List.Item>
                <List.Item>&nbsp; &nbsp;6:30 West Ryde 주안교회당에서 모입니다.</List.Item>          
                <List.Item></List.Item>          
                <List.Item>줌(ZOOM)으로도( ID: 465-471-4497), Password: 463412, </List.Item>
                <List.Item><a href="https://us04web.zoom.us/j/4654714497">https://us04web.zoom.us/j/4654714497</a> 로 참여하실 수 있습니다.</List.Item>
              </List>
          </Grid.Column>
      </Grid>
    );
};

export default CityServicePage;