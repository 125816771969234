import React from 'react'
import { Segment } from 'semantic-ui-react'

interface IProps {
    id:string;
    name:string;
    description:string;
    parentName:string
}

const OrganisationTreeDetail:React.FC<IProps> = ({id,name,description,parentName}:IProps) => {

    if(!name)
        return null;

    return(
        <Segment.Group>
            <Segment style={{height:60}}>
                {name}
            </Segment>
            <Segment clearing>
                {
                    description &&                  
                        <div dangerouslySetInnerHTML={{ __html: description.replace(/\n/g, "<br />") }} />
                }
            </Segment>
        </Segment.Group>
    )
}

export default OrganisationTreeDetail;


//  <Statistic.Group width={2}>
// <Statistic label='사진 ' value ={} size='small' />
// <Statistic label='기도 ' value ={} size='small'/>
// </Statistic.Group> 

