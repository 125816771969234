import React, { Fragment, useContext, useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import { RootStoreContext } from '../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import { Pagination } from 'semantic-ui-react'
import VideoTableItemPlaceHolder from './dashboard/VideoTableItemPlaceHolder';
import TwoColumnTable from '../../app/common/tables/TwoColumnTable';
import TwoColumnTableRow from '../../app/common/tables/TwoColumnTableRow';

const VideoPage: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const { loadVideos, currentPage, setCurrentPage, 
        getVideosByDate, totalPage, loadingInitial } = rootStore.videoStore;

    useEffect(() => {
        loadVideos();
    }, []);

    const pageChangHandler = (event: React.MouseEvent<HTMLAnchorElement>, data: any) => {
        setCurrentPage(data.activePage);
        loadVideos();
    }

    return (
        <Grid style={{ marginTop: '15px' }}>
            <Grid.Column width={16}>
                {loadingInitial ? <VideoTableItemPlaceHolder />
                    : <Fragment>
                        <TwoColumnTable title='제목'>
                                <Fragment>
                                    {getVideosByDate.map((video) => {
                                        return <TwoColumnTableRow id={video.id}
                                                    title={video.title}
                                                    link={`/video/${video.id}`}
                                                    date={video.date} />
                                    })}
                                </Fragment>
                        </TwoColumnTable>
                        <Pagination
                            firstItem={null}
                            lastItem={null}
                            defaultActivePage={currentPage} totalPages={totalPage}
                            onPageChange={pageChangHandler}
                        />
                    </Fragment>
                }
            </Grid.Column>
        </Grid>
    );
};

export default observer(VideoPage);


