import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom'
import queryString from 'query-string';
import agent from '../../app/api/agent';
import { Header, Icon, Button, Container } from 'semantic-ui-react';
import { toast } from 'react-toastify';

const VerifyEmail : React.FC<RouteComponentProps> = ({location}) =>{
    const Status = {
        Verifying:'Verifying',
        Failed:'Failed',
        Success:'Success'
    };

    const [status, setStatus] = useState(Status.Verifying);
    const {token, email} = queryString.parse(location.search);

    useEffect(()=>{
        agent.User.verifyEmail(token as string, email as string).then(()=>{
            setStatus(Status.Success);
        }).catch(()=>{
            setStatus(Status.Failed);
        })
    },[Status.Success, Status.Failed, token, email]);

    const handleConfirmEmailResend = () =>{
        agent.User.resendEmailVerification(email as string).then(()=>{
            toast.success('Verification email resent - please check your email');
        }).catch((error)=>console.log(error));
    }

    const getBody =()=>{
        switch(status){
            case Status.Verifying:
                return (<p>Verifying...</p>);
            case Status.Failed:
                return (
                    <div className="center">
                        <p>Verification failed - you can try resending the verification email</p>
                        <Button onClick={handleConfirmEmailResend} primary size="huge" content="Resend email"/>
                    </div>
                )
            case Status.Success:
                return (
                    <div className="center">
                        <p>Email has been verified - you can now login</p>
                    </div>
                )    
        }        
    }

    return (
        <Container text style={{marginTop:25}}>
            <Header><Icon name='envelope' />Email Verification</Header>
            {getBody()}
        </Container>
    )
}

export default VerifyEmail;
