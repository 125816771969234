import React, { Fragment, useContext, useEffect } from 'react';
import { Grid, Breadcrumb, List, Header, Pagination, Image } from 'semantic-ui-react';
import { RootStoreContext } from '../../app/stores/rootStore';
import VideoTableItemPlaceHolder from '../video/dashboard/VideoTableItemPlaceHolder';
import { observer } from 'mobx-react-lite';
import { VideoCategories } from '../../app/common/constants/categories';
import TwoColumnTable from '../../app/common/tables/TwoColumnTable';
import TwoColumnTableRow from '../../app/common/tables/TwoColumnTableRow';

const sections = [
    { key: '예배', content: '예배', link: true },
    { key: 'service-the-heart', content: '목요 찬양', active: true }
]

const headerSize = 'large';

const ServiceTheHeartPage = () => {
    const rootStore = useContext(RootStoreContext);
    const { loadVideos, currentPage, setCategory, getVideosByDate,
        setCurrentPage, totalPage, loadingInitial } = rootStore.videoStore;

    useEffect(() => {
        setCategory(VideoCategories.theHeartService);
    }, [setCategory]);

    const pageChangHandler = (event: React.MouseEvent<HTMLAnchorElement>, data: any) => {
        setCurrentPage(data.activePage);
        loadVideos();
    }

    return (
        <Grid>
            <Grid.Column width={16} style={{ backgroundColor: 'white' }}>
                <Breadcrumb icon='right angle' sections={sections} style={{ margin: '20px' }} />
                <Image src={'/assets/main/theHeart.jpg'} style={{ marginBottom: '20px' }} />

                <Header content='시티 목요찬양예배(THE HEART)안내' size={headerSize} />
                <List.Item key='sd1'>시드니 주안교회에서는 시티에서 매주 목요일마다 찬양집회(THE HEART)를 하고 있습니다.</List.Item>
                <List.Item key='sd2'>*’THE HEART’란 ‘Touching Heaven on Earth with Father’s Heart’라는 뜻으로 하나님 아버지의 마음으로 ‘하나님과 세상 사이의 중보자’로 선다는 뜻입니다.
                <List.List as='ol'>
                        <List.Item key='sd3' as='li'>찬양집회 일시: 매주 목요일 오후 7시</List.Item>
                        <List.Item key='sd4' as='li'>장소: West Ryde 교회당에서 있습니다. </List.Item>
                        <List.Item key='sd5' as='li'>대상: 하나님께 예배하기 원하는 모든 사람들</List.Item>
                    </List.List>
                </List.Item>
            </Grid.Column>
            <Grid.Column width={16}>
                {loadingInitial ? <VideoTableItemPlaceHolder />
                    : <Fragment>
                        <TwoColumnTable title='목요 찬양'>
                            <Fragment>
                                {getVideosByDate.map((video) => {
                                    return <TwoColumnTableRow id={video.id}
                                                title={video.title}
                                                link={`/video/${video.id}`}
                                                date={video.date} />
                                })}
                            </Fragment>
                        </TwoColumnTable>
                        <Pagination
                            firstItem={null}
                            lastItem={null}
                            defaultActivePage={currentPage} totalPages={totalPage}
                            onPageChange={pageChangHandler}
                        />
                    </Fragment>
                }
            </Grid.Column>
        </Grid>
    );
};

export default observer(ServiceTheHeartPage);



