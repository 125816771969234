import React, { Fragment } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Menu, Dropdown } from 'semantic-ui-react';
import * as Styles from '../styles';

interface IProp {
    pathName:string,
    children:JSX.Element
}

const NewComerManager = ({pathName, children}:IProp) => {
    return (
        <Fragment>
            <Menu.Item name="교회" as={NavLink} to="/organisationTree" text="폴더 뷰"
                style={ pathName === '/organisationTree' ? Styles.activeMenuItemStyle : Styles.menuItemStyle}/>
            <Menu.Item name="NewComer"
                style={(pathName === '/createNewComer'
                    || pathName =='/newBelievers'
                    || pathName.startsWith('/newComers')
                    || pathName.startsWith('/manageNewComer')
                    || pathName.startsWith('/createNewBeliever')
                    || pathName.startsWith('/manageNewBeliever')
                ) ? Styles.activeMenuItemStyle : Styles.menuItemStyle}>						
                <Dropdown pointing="top left" text='새가족' >
                    <Dropdown.Menu>								
                        <Dropdown.Item as={Link} to="/newBelievers" text="새가족 목록" />
                        <Dropdown.Item as={Link} to="/newComers" text="새가족방 목록" />
                        <Dropdown.Item as={Link} to="/createNewComer" text="새가족방 만들기" />
                    </Dropdown.Menu>
                </Dropdown>
            </Menu.Item>

            {children}
    </Fragment>
    );
};

export default NewComerManager;
