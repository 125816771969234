import React from 'react';
import { Form } from 'semantic-ui-react';


interface Props {
    checked:boolean,
    text:string,
    name:string,
    onChange:(e: React.ChangeEvent<HTMLInputElement>) => void,
    disabled:boolean
}

const CheckBoxField = (props: Props) => {
    return (
        <Form.Field>
            <div className="ui checkbox">
                <input name ={props.name} 
                    type="checkbox"
                    checked = {props.checked} 
                    onChange={props.onChange}
                    />
                <label>{props.text}</label>
            </div> 
        </Form.Field>
   )
}

export default CheckBoxField;

// !! means cast it to boolean.

// <div class="ui checkbox"><input type="checkbox" class="hidden" readonly="" tabindex="0"/><label>
//Make my profile visible</label></div> 