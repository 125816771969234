import { observer } from 'mobx-react-lite';
import React, { useState, useContext, useEffect, Fragment } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Card, Grid, Header, Image } from 'semantic-ui-react';
import BulletinPhotoUploadWidget from '../../../app/common/photoUpload/BulletinPhotoUploadWidget';
import { IBulletin } from '../../../app/models/bulletin';
import { RootStoreContext } from '../../../app/stores/rootStore';

interface DetailParams {
    id: string;
}

const BulletinPhotoForm: React.FC<RouteComponentProps<DetailParams>> = ({ match, history }) => {
    const rootStore = useContext(RootStoreContext);
    const { loadBulletin, uploadPhoto, uploadingBulletinPhoto, getBulletinPhotos, deletePhoto } = rootStore.bulletinStore;

    const [bulletin, setBulletin] = useState<IBulletin>();
    const [loading, setLoading] = useState(false);
    const [cropAddPhotoMode, setCropAddPhotoMode] = useState(false);
    const [deleteTarget, setDeleteTarget] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (match.params.id) {
            setLoading(true);
            loadBulletin(match.params.id, true)
                .then((bulletin) => {
                    setBulletin(bulletin);
                })
                .finally(() => setLoading(false));
        }
    }, [loadBulletin, match.params.id]);

    const handleUploadImage = (file: Blob, bulletinId: string, title: string, order: string) => {
        let formData = new FormData();
        formData.append('File', file);
        formData.append('BulletinId', bulletinId);
        formData.append('Title', title);
        formData.append('order', order.toString());

        uploadPhoto(formData)
            .then(() => setCropAddPhotoMode(false));
    };

    return (
        <Grid style={{ marginTop: 25, paddingTop: 10, backgroundColor: 'white' }}>
            {bulletin &&
                <Fragment>
                    <Grid.Column width={16}>
                        <Header floated="left" content={bulletin.title} />
                        <Button
                            onClick={() => setCropAddPhotoMode(!cropAddPhotoMode)}
                            floated="right"
                            basic
                            content={cropAddPhotoMode ? 'Cancel' : 'Add Photo'}
                        />
                    </Grid.Column>
                    <Grid.Column width={16}>
                        {cropAddPhotoMode ? (
                            <BulletinPhotoUploadWidget
                                bulletinId={bulletin.id}
                                uploadPhoto={handleUploadImage} loading={uploadingBulletinPhoto}
                                defaultOrder={(getBulletinPhotos.length + 1)}
                                category={bulletin.category}
                            />
                        ) : (
                                <Card.Group itemsPerRow={5}>
                                    { getBulletinPhotos.map((photo) => (
                                        <Card key={photo.id}>
                                            <Image src={photo.url} />
                                            <Button.Group fluid widths={2}>
                                                <Button
                                                    name={photo.id}
                                                    onClick={(e) => {
                                                        deletePhoto(photo);
                                                        setDeleteTarget(e.currentTarget.name);
                                                    }}
                                                    loading={loading && deleteTarget === photo.id}
                                                    disabled={photo.isMain}
                                                    basic
                                                    negative
                                                    icon="trash"
                                                />
                                            </Button.Group>
                                            <p>Order : {photo.order}</p>
                                        </Card>
                                    ))}
                                </Card.Group>
                            )}
                    </Grid.Column>
                </Fragment>
            }
        </Grid>
    );
};

export default observer(BulletinPhotoForm);
