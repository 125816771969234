import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Grid, Icon, Label, List } from 'semantic-ui-react';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import OrganisationTreeDetail from './OrganisationTreeDetail';
import * as Styles from './styles';
import TreeViewPlaceHolder from '../../../app/common/PlaceHolder/TreeViewPlaceHolder';
import TreeItem from '../../../app/common/tree/TreeItem';

const OrganisationTree: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const { loadingInitial, loadTreeView, getparents, idOnTree, getchildren,
        nameOnTree, descriptionOnTree, parentOnTree, setOrganisationTreeItem } = rootStore.organisationStore;

    const [treeItemSize, setTreeItemSize] = useState(3);

    useEffect(() => {
        loadTreeView();
    }, []);

    const zoomIn=()=>{
        if (treeItemSize < 8){
            let zoomedInSize =treeItemSize + 1;
            setTreeItemSize(zoomedInSize);    
        }
    }

    const zoomOut=()=>{
        if (treeItemSize > 1){
            let zoomOutSize =treeItemSize -1;
            setTreeItemSize(zoomOutSize);    
        }
    }

    return (
        <Grid style={{ marginTop: '10px' }}>
            <Grid.Column width={8}>
                {
                    loadingInitial ? <TreeViewPlaceHolder /> :
                        (
                            getparents.length===0 ?
                            (<span>최상위 교회가 없습니다.</span>):
                            (                            
                            <div>
                                <Fragment>
                                    <span style={{cursor:'pointer', paddingRight:'10px' }} onClick={zoomIn}>
                                        <Label size='medium'>
                                            <Icon  name='plus' />
                                            확대
                                        </Label>
                                    </span>
                                    <span style={{cursor:'pointer'}} onClick={zoomOut}>
                                        <Label size='medium'>
                                            <Icon name='minus'/>
                                            축소
                                        </Label>
                                    </span>
                                </Fragment>
                                <List style={Styles.tree}>
                                    {getparents.map((organisation) => {
                                        return <TreeItem key={organisation.id} 
                                            treeItemSize ={treeItemSize}
                                            idOnTree={idOnTree}
                                            children={getchildren} 
                                            selectTreeItem={setOrganisationTreeItem}
                                            organisation={organisation} parentName='' />
                                    })}
                                </List>
                            </div>)
                        )
                }
            </Grid.Column>
            <Grid.Column width={8}>
                <OrganisationTreeDetail id={idOnTree} name ={nameOnTree} description={descriptionOnTree}
                    parentName={parentOnTree}/>
            </Grid.Column>
        </Grid>
    );
};

export default observer(OrganisationTree);

// https://react.semantic-ui.com/elements/icon/


