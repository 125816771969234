import { RootStore } from './rootStore';
import { observable, action, computed, runInAction } from 'mobx';
import agent from '../api/agent';
import { toast } from 'react-toastify';
import { IBeliever } from '../models/believer';
import { BelieverStatus } from '../common/constants/believerStatus';

export default class BelieverStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @observable loadingInitial = true;
    @observable submitting = false;
    @observable errorMessage: string = '';

    @observable believer: IBeliever | null = null;

    @observable believerRegistry = new Map();
    @observable believerOrganisationsRegistry = new Map();

    @observable deleteErrorMessage: string ='';

    // list
    @observable believerCount = 0;
    @observable currentPage = 1;
    @observable totalPage = 1;
    @observable pageSize: number = 8;

    @computed get getBelievers() {
        if (this.believerRegistry.size >= 1) {
            return Array.from(this.believerRegistry.values());
        }
        return [];
    }

    @computed get getBelieverOrganisations() {
        if (this.believerOrganisationsRegistry.size >= 1) {
            return Array.from(this.believerOrganisationsRegistry.values());
        }
        return [];
    }

    @action initBeliever = () => {
        this.believerRegistry.clear();
    }

    @action setPageSize = (pageSize: number) => {
        this.pageSize = pageSize;
    }

    @action setCurrentPage = (page: number) => {
        this.currentPage = page;
    }

    @action createBeliever = async (formData: FormData) => {
        this.submitting = true;
        try {
            var result = await agent.Believers.create(formData);
            runInAction('creating believer', () => {
                // this.believerRegistry.set(believer.id, believer);
                this.submitting = false;
                toast.info('it is created.');
            });
            } catch (error) {
            runInAction('Problem submitting data', () => {
                this.submitting = false;
            });
            toast.error('Problem submitting data');
        }
    };

    @action editBeliever = async (formData: FormData, id: string) => {
        this.submitting = true;
        try {
            var believer = await agent.Believers.update(formData, id);

            runInAction('editing bulletin', () => {
                // this.believerRegistry.set(believer.id, believer);
                toast.info('it is edited.');
                this.submitting = false;
            });
        } catch (error) {
            runInAction('Problem submitting data ', () => {
                this.submitting = false;
            });
            toast.error('Problem submitting data');
        }
    };

    @action loadBelievers = async (status: string,  sortOrder:string, search:string, organisationId:string) => {
        try {
            const params = new URLSearchParams();
            params.append('currentPage', `${this.currentPage}`);
            params.append('pageSize', `${this.pageSize}`);
            params.append('status', status);
            params.append('sortOrder', sortOrder);
            params.append('search', search);
            params.append('organisationId', organisationId);            

            let believersResourceModel = await agent.Believers.list(params);

            runInAction('loading believers', () => {
                if (believersResourceModel) {
                    this.believerRegistry.clear();
                    const { currentPage, count, totalPage, data } = believersResourceModel;
                    data.forEach((believer: any) => {
                        this.believerRegistry.set(believer.id, believer);
                    });
                    this.currentPage = currentPage;
                    this.totalPage = totalPage;
                    this.believerCount = count;
                    this.loadingInitial = false;
                }
            });
        } catch (error) {
            runInAction('load believers error', () => {
                this.loadingInitial = false;
            });
            throw error;
        }
    };

    @action loadNewBeliever = async (id: string) => {
        try {
            let believer = await agent.Believers.detailsWithNewComer(id);

            runInAction('loading believer', () => {
                this.loadingInitial = false;
                this.errorMessage ='';
                this.deleteErrorMessage='';    
                this.believer = believer;
            });
            return believer;

        } catch (error) {
            runInAction('load believer error', () => {
                this.loadingInitial = false;
            });
            throw error;
        }
    };

    @action loadBeliever = async (id: string) => {
        try {
            let believer = await agent.Believers.details(id);

            runInAction('loading believer', () => {
                this.loadingInitial = false;
                this.errorMessage ='';
                this.deleteErrorMessage='';    
                this.believer = believer;
                this.believerOrganisationsRegistry.clear();
                if (believer.organisations && believer.organisations.length > 0 )
                    believer.organisations.forEach((organisation: any) => {
                        this.believerOrganisationsRegistry.set(organisation.id, organisation);
                });
            });
            return believer;

        } catch (error) {
            runInAction('load believer error', () => {
                this.loadingInitial = false;
            });
            throw error;
        }
    };

    @action deleteById = async (id: string) => {
        try {
            await agent.Believers.delete(id);
            runInAction('deleting believer', () => {
                this.believerRegistry.delete(id);
            });
        } catch (error) {
            runInAction('delete believer error', () => {
            });
            console.log(error);
        }
    };

    @action assign = async (believerId:string, formData: FormData) => {
        try {
            var result = await agent.Believers.assign(formData);

            runInAction('assigning believer', () => {
                this.submitting = false;
                if (result && result.succeeded===false){
                    this.errorMessage = result.message;
                }
                else{
                    this.loadBeliever(believerId);
                }
        });

        } catch (error) {
            runInAction('Problem submitting data', () => {
                this.submitting = false;
            });
            // toast.error('Problem submitting data');
        }
    };

    @action unassign = async (believerId:string, formData: FormData) => {
        try {
            var result = await agent.Believers.unassign(formData);

            runInAction('unassigning believer', () => {
                this.submitting = false;
                if (result && result.succeeded===false){
                    this.errorMessage = result.message;
                }
                else{
                    this.loadBeliever(believerId);
                }
            });

        } catch (error) {
            runInAction('Problem submitting data', () => {
                this.submitting = false;
            });
            // toast.error('Problem submitting data');
        }
    };

    @action promoteById = async (id: string, newComerId :string) => {
        try {            
            let formData = new FormData();
            formData.append('id', id);    
            formData.append('status', BelieverStatus.Regular);    

            var believer = await agent.Believers.promoteById(formData, id);
 
            runInAction('prmoting believer', () => {
                this.believerRegistry.set(believer.id, believer);
                this.submitting = false;            
            });
        } catch (error) {
            runInAction('prmoting believer error', () => {
            });
            console.log(error);
        }
    };    
}

