export const BulletinCategories = {
    WestRyde: 'WestRyde',
    City: 'City'
};

export const VideoCategories = {
    theHeartService: 'theHeartService',
    event: 'event',
    praise: 'praise',
    onlineService: 'onlineService'
  };

export const OrganisationCategories = {
    InActive: 'InActive',
    Active: 'Active'
};

export const AllCategory = 'ALL';

