import React, { useContext } from 'react';
import { Item, Button, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { IHelp } from '../../../app/models/help';
import ConfirmationModal from '../../../app/common/modals/ConfirmationModal';
import {format} from 'date-fns';

const HelpListItem: React.FC<{ help: IHelp }> = ({ help }) => {
    const rootStore = useContext(RootStoreContext);
    const { deleteById } = rootStore.helpStore;
    const { openModal } = rootStore.modalStore;

    return (
        <Segment.Group>
            <Segment>
                <Item.Group>
                    <Item>
                        <Item.Content>
                            <Item.Header>
                                {help.title} - {format(new Date(help.date),'dd MMM yyyy')}
                            </Item.Header>
                            <Item.Description>                                 
                                {
                                    help.filePath ?
                                        (
                                            <a href={help.filePath} download> Download </a>
                                        ) :
                                        ''
                                }                                
                            </Item.Description>
                        </Item.Content>
                    </Item>
                </Item.Group>
            </Segment>
            <Segment clearing>
                <Button floated="right" content="Delete" color="red"
                    onClick={() => openModal(<ConfirmationModal
                        id={help.id}
                        subject={'Delete your Help'}
                        message={'Are you sure you want to delete it?'}
                        confirmById={deleteById}
                    />)}
                />
                <Button as={Link} to={`/manageHelp/${help.id}`} floated="right" content="Edit" color="blue" />
            </Segment>
        </Segment.Group>
    );
};

export default HelpListItem;