import { combineValidators, isRequired } from 'revalidate';
import React, { useState, useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { RouteComponentProps } from 'react-router-dom';
import { VideoFormValues } from '../../../app/models/video';
import { Segment, Form, Button, Grid } from 'semantic-ui-react';
import { v4 as uuid } from 'uuid';
import { Form as FinalForm, Field } from 'react-final-form';
import DateInput from '../../../app/common/form/DateInput';
import TextInput from '../../../app/common/form/TextInput';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import SelectInput from '../../../app/common/form/SelectInput';
import { VideoCategories } from '../../../app/common/constants/categories';

const validators = combineValidators({
    category: isRequired('Category'),
    date: isRequired('Date'),
    title: isRequired({ message: 'The title is required' })
});

interface DetailParams {
    id: string;
}

const categoryOptions = [
    { key: VideoCategories.theHeartService, text: '목요 찬양 예배', value: VideoCategories.theHeartService },
    { key: VideoCategories.event, text: '행사', value: VideoCategories.event },
    { key: VideoCategories.praise, text: '찬양', value: VideoCategories.praise },
    { key: VideoCategories.praise, text: '예배', value: VideoCategories.onlineService }
];

const VideoForm: React.FC<RouteComponentProps<DetailParams>> = ({ match, history }) => {

    const rootStore = useContext(RootStoreContext);
    const { createVideo, editVideo, loadVideo, submitting } = rootStore.videoStore;

    const [video, setVideo] = useState(new VideoFormValues());
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (match.params.id) {
            setLoading(true);
            loadVideo(match.params.id)
                .then((video) => {
                    setVideo(video);
                    setDescription(video.description);
                })
                .finally(() => setLoading(false));
        }
    }, [loadVideo, match.params.id]);

    const [description, setDescription] = useState('');

    const handleChange = (description: any) => {
        setDescription(description);
    }

    const handlFinalFormSubmit = (video: any) => {
        if (!video.id) {
            let newVideo = {
                ...video,
                id: uuid(),
                description: description
            };
            createVideo(newVideo);
        } else {
            video.description = description;
            editVideo(video);
        }
    };

    return (<Grid style={{ marginTop: '15px' }}>
        <Grid.Column width={10}>
            <Segment clearing>
                <FinalForm
                    validate={validators}
                    initialValues={video}
                    onSubmit={handlFinalFormSubmit}
                    render={({ handleSubmit, invalid, pristine }) => (
                        <Form onSubmit={handleSubmit} loading={loading}>
                            <Field
                                component={SelectInput}
                                options={categoryOptions}
                                name="category"
                                placeholder="Category"
                                value={video.category}
                            />
                            <Field component={DateInput} name="date" date={true} placeholder="Date" value={video.date} />
                            <Field
                                component={TextInput}
                                name="title"
                                placeholder="Title"
                                value={video.title}
                            />
                            <Field
                                component={TextInput}
                                name="youtubeVideoId"
                                placeholder="youtube Video Id"
                                value={video.youtubeVideoId}
                            />
                            <Form.Field>
                                <SunEditor onChange={handleChange} setContents={video.description} />
                            </Form.Field>
                            <Button
                                floated="right"
                                loading={submitting}
                                disabled={loading || invalid}
                                positive
                                type="submit"
                                content="Save"
                            />
                            <Button
                                onClick={
                                    () => history.push('/videos')
                                }
                                floated="right"
                                type="button"
                                content="Cancel"
                            />
                        </Form>)}
                />
            </Segment>
        </Grid.Column>
    </Grid>);
};

export default observer(VideoForm);