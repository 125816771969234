import { observable, action, computed, runInAction, reaction } from 'mobx';
import agent from '../api/agent';
import { toast } from 'react-toastify';
import { RootStore } from './rootStore';
import 'mobx-react-lite/batchingForReactDom';
import { ReportStatus } from '../common/constants/reportStatus';

const LIMIT = 4;

export default class ApprovalStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        reaction(() => this.status,
            () => {
                this.page = 0;
                this.approvalRegistry.clear();
                this.loadApprovals();
            })
    }

    @observable loadingInitial = true;
    @observable approvalRegistry = new Map();
    @observable status = ReportStatus.Pending;

    @observable approveLoading = false;
    @observable declineLoading = false;

    @action setStatus = (status: string) => {
        this.status = status;
    }

    @observable page = 0;
    @observable approvalCount = 0;

    @computed get totalPages() {
        return Math.ceil(this.approvalCount / LIMIT);
    }

    @action setPage = (page: number) => {
        this.page = page;
    }


    @computed get axiosParams() {
        const params = new URLSearchParams();
        params.append('limit', String(LIMIT));
        params.append('offset', `${this.page ? this.page * LIMIT : 0}`);
        params.append('status', this.status);
        return params;
    }

    @computed get getApprovals() {
        return Array.from(this.approvalRegistry.values());
    }

    @action loadApprovals = async () => {
        try {
            const approvalResourceModel = await agent.Approvals.list(this.axiosParams);

            const { approvals, approvalCount } = approvalResourceModel;

            runInAction('loading reports', () => {
                if (approvals) {
                    approvals.forEach((approval) => {
                        this.approvalRegistry.set(approval.id, approval);
                    });
                }
                this.approvalCount = approvalCount;
                this.loadingInitial = false;
            });
        } catch (error) {
            runInAction('load approvals error', () => {
                this.loadingInitial = false;
            });
            throw error;
        }
    };

    @action approve = async (id: string) => {
        this.approveLoading = true;
        try {
            await agent.Approvals.approve(id);
            runInAction(() => {
                let approval = this.approvalRegistry.get(id);
                approval.status = ReportStatus.Approved;
                this.approvalRegistry.set(id, approval);
                this.approveLoading = false;
                this.status = ReportStatus.Approved;
            });
        } catch (error) {
            runInAction(() => {
                this.approveLoading = false;
            });
            toast.error('Problem approving report');
        }
    };

    @action decline = async (id: string) => {
        this.declineLoading = true;
        try {
            await agent.Approvals.decline(id);
            runInAction(() => {
                let approval = this.approvalRegistry.get(id);
                approval.status = ReportStatus.Declined;
                this.approvalRegistry.set(id, approval);
                this.declineLoading = false;
                this.status = ReportStatus.Declined;
            });
        } catch (error) {
            runInAction(() => {
                this.declineLoading = false;
            });
            toast.error('Problem approving report');
        }
    };
}