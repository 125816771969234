import React from 'react';
import { IBeliever } from '../../../app/models/believer';
import { Card, Image, Button, Icon } from 'semantic-ui-react';
import { ImagePaths } from '../../../app/common/constants/imagePaths';
import Kakaotalk from '../../../assets/images/kakaotalk.png';
import {format} from 'date-fns';

interface IProps {
    believers: IBeliever[],
    openModal:(modal:any) =>void,
    deleteById:(id: string) =>void,
    itemsPerRow:any;
}

const BelieverCardList: React.FC<IProps> = ({believers, openModal, deleteById, itemsPerRow}) => {
    
    return (
        <Card.Group itemsPerRow={itemsPerRow}>
            {believers.map((believer) => {
                var photoUrl = believer.url;
                if (photoUrl && photoUrl.includes("upload") && !photoUrl.includes("q_auto:best")) {
                    photoUrl = photoUrl.replace("upload", "upload/q_auto:best");
                }

                return(
                <Card key={believer.id}>
                    <Card.Content>
                        <Image style={{marginBottom:10}} size="small" src={photoUrl || ImagePaths.defaultUser } />
                        <Card.Header>{believer.name}</Card.Header>
                        <Card.Description>
                        {
                            believer.description && 
                            (<div dangerouslySetInnerHTML={{ __html: believer.description.replace(/\n/g, "<br />") }} />)
                        }
                        </Card.Description>
                    </Card.Content>

                    <Card.Content extra>                    
                        <Icon name='phone' />{believer.phone}
                    </Card.Content>

                    {
                         believer.kakaotalkId && 
                         (
                            <Card.Content extra>
                                <img src={Kakaotalk} style={{ marginRight:'8px',  width: '17px' }} />   
                                {believer.kakaotalkId}
                            </Card.Content>
                         )
                    }

                    {
                         believer.birthday && 
                        (<Card.Content extra>                    
                            <Icon name='birthday' />
                            {format(new Date(believer.birthday),'dd MMM yyyy')}
                         </Card.Content>
                        )
                    }
                </Card>)
            })}
        </Card.Group>
    );
};

export default BelieverCardList;



/*                         */
