import { observer } from 'mobx-react-lite';
import React, { useState, useContext, useEffect, Fragment } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Card, Grid, Header, Image } from 'semantic-ui-react';
import ResizePhotoUploadWidget from '../../../app/common/photoUpload/ResizePhotoUploadWidget';
import { IGallery } from '../../../app/models/gallery';
import { RootStoreContext } from '../../../app/stores/rootStore';

interface DetailParams {
    id: string;
}

const GalleryPhotoForm: React.FC<RouteComponentProps<DetailParams>> = ({ match, history }) => {
    const rootStore = useContext(RootStoreContext);
    const { loadGallery, uploadPhoto, uploadingGalleryPhoto, getGalleryPhotos, deletePhoto } = rootStore.galleryStore;

    const [gallery, setGallery] = useState<IGallery>();
    const [loading, setLoading] = useState(false);
    const [cropAddPhotoMode, setCropAddPhotoMode] = useState(false);
    const [deleteTarget, setDeleteTarget] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (match.params.id) {
            setLoading(true);
            loadGallery(match.params.id, true)
                .then((gallery) => {
                    setGallery(gallery);
                })
                .finally(() => setLoading(false));
        }
    }, [loadGallery, match.params.id]);

    const handleUploadImage = (file: Blob) => {
        let formData = new FormData();
        formData.append('File', file);
        if (gallery){
            formData.append('GalleryId', gallery.id);            
            formData.append('order', getGalleryPhotos.length.toString());    
        }

        uploadPhoto(formData).then(() => {
            setCropAddPhotoMode(false)
        });
    };

    return (
        <Grid style={{ marginTop: 25, paddingTop: 10, backgroundColor: 'white' }}>
            {gallery &&
                <Fragment>
                    <Grid.Column width={16}>
                        <Header floated="left" content={gallery.title} />
                        <Button
                            onClick={() => setCropAddPhotoMode(!cropAddPhotoMode)}
                            floated="right"
                            basic
                            content={cropAddPhotoMode ? 'Cancel' : 'Add Photo'}
                        />
                    </Grid.Column>
                    <Grid.Column width={16}>
                        {cropAddPhotoMode ? (
                            <ResizePhotoUploadWidget
                                loading = {uploadingGalleryPhoto}
                                uploadPhoto={handleUploadImage}
                                maxWidthHeight={1224} 
                                isResize={true}/>
                        ) : (
                                <Card.Group itemsPerRow={4}>
                                    { getGalleryPhotos.map((photo) => (
                                        <Card key={photo.id}>
                                            <Image src={photo.url} />
                                            <Button.Group fluid widths={2}>
                                                <Button
                                                    name={photo.id}
                                                    onClick={(e) => {
                                                        deletePhoto(photo);
                                                        setDeleteTarget(e.currentTarget.name);
                                                    }}
                                                    loading={loading && deleteTarget === photo.id}
                                                    disabled={photo.isMain}
                                                    basic
                                                    negative
                                                    icon="trash"
                                                />
                                            </Button.Group>
                                            <p>Order : {photo.order}</p>
                                        </Card>
                                    ))}
                                </Card.Group>
                            )}
                    </Grid.Column>
                </Fragment>
            }
        </Grid>
    );
};

export default observer(GalleryPhotoForm);
