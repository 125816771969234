import React, { useState, useContext, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { RouteComponentProps } from 'react-router-dom';
import { OrganisationFormValues } from '../../../app/models/organisation';
import { Segment,Button, Grid, Form as SemanticForm } from 'semantic-ui-react';
import { v4 as uuid } from 'uuid';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import BasicErrorMessage from '../../../app/common/form/BasicErrorMessage';
import TextInputFocusField from '../../../app/common/formikForm/TextInputFocusField';
import CheckBoxField from '../../../app/common/formikForm/CheckBoxField';
import TextAreaField from '../../../app/common/formikForm/TextAreaField';
import "./OrganisationForm.css";
import SearchField from '../../../app/common/search/SearchField';

const validationObject = Yup.object({
    name :Yup.string().required('The name is required.').max(100, 'max length is 100 characters'),
    description :Yup.string().required('The description is required').max(2000, 'max length is 2000 characters'),
});

interface DetailParams {
    id: string;
}

const OrganisationForm: React.FC<RouteComponentProps<DetailParams>> = ({ match, history }) => {
    const rootStore = useContext(RootStoreContext);
    const { createOrganisation, submitting, loadOrganisation, getDropDownlOrganisations, loadingDropDown,
        loadDropDownlOrganisations, editOrganisation, errorMessage } = rootStore.organisationStore;

    const [organisation, setOrganisation] = useState(new OrganisationFormValues());
    const [isActive, setIsActive] = useState(true);
    const [loadingSearch, setLoadingSearch] = useState(true);

    const parentRef = useRef<any | null>(null);
    const [intialSearchValue, setIntialSearchValue] = useState<string>('');

    const handlFormikFormSubmit = (organisation: any) => {
        let updatedOrganisation = {
            ...organisation,
            isActive:isActive,
        };

        if (parentRef && parentRef.current ){
            updatedOrganisation.parentName =parentRef.current.state!.value;
        }

        if (!organisation.id) {
            updatedOrganisation.id = uuid();
            createOrganisation(updatedOrganisation);
        } else {
            editOrganisation(updatedOrganisation);
        }
    };

    const handleCheckBoxClick = (event: React.ChangeEvent<HTMLInputElement>)=> {
        if (event.target.name ==='isActive'){
            setIsActive(event.target.checked)
        }
    }

    useEffect(() => {
        console.log('useEffect - organisation Form');

        loadDropDownlOrganisations().then((orgs) =>{
            if (match.params.id) {
                loadOrganisation(match.params.id)
                    .then((organisation) => {
                        setOrganisation(organisation);
                        if (organisation.parentName){
                            setIntialSearchValue(organisation.parentName)
                        }
                        setIsActive(organisation.isActive);
                        setLoadingSearch(false);
                    })
                    .finally();
            }
            else{
                setLoadingSearch(false);
            }
        });

    }, []);

    return (<Grid style={{ marginTop: '15px' }}>
        <Grid.Column width={10}>
            <Segment clearing>
                {
                    loadingDropDown === false && 
                    (
                        <Formik validationSchema = {validationObject}
                        enableReinitialize initialValues={organisation}
                        onSubmit={values=> handlFormikFormSubmit(values)}>
                        {({values, handleSubmit, isValid, isSubmitting, dirty}) =>(
                            <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>
                                <SemanticForm.Field>
                                   <BasicErrorMessage content= {errorMessage} />
                                </SemanticForm.Field>
                                {
                                ( getDropDownlOrganisations && getDropDownlOrganisations.length > 0)
                                && ((!match.params.id) || (match.params.id && intialSearchValue))
                                && (
                                    <SemanticForm.Field>
                                        <SearchField
                                            source = {getDropDownlOrganisations}
                                            intialSearchValue ={intialSearchValue}
                                            forwardedRef = {parentRef}
                                            name ='parentName'
                                        />
                                    </SemanticForm.Field>) }

                                <TextInputFocusField name="name" placeholder="Name"/>
                                <CheckBoxField text='IsActive' name='isActive' onChange={(e)=>handleCheckBoxClick(e)}
                                    checked={isActive===true} disabled={false}/>

                                <TextAreaField type='textarea' name="description" placeholder=""/>

                                <Button
                                    floated="right"
                                    loading={submitting}
                                    disabled={!isValid || isSubmitting } positive
                                    type="submit"
                                    content="Save"
                                />
                                <Button
                                    onClick={() => history.push('/Organisations')}
                                    floated="right"
                                    type="button"
                                    content="Cancel"
                                />
                            </Form>
                        )}

                    </Formik>
                    )

                }
            </Segment>
        </Grid.Column>
        <Grid.Column width={6}>
        </Grid.Column>
    </Grid>);
};

export default observer(OrganisationForm);





// {( getDropDownlOrganisations && getDropDownlOrganisations.length > 0) && (
//     <SemanticForm.Field>
//         <div ref={wrapperRef} className="flex-container flex-column pos-rel" >
//             <input id="auto" onFocus={()=>setShowHint(true)}
//                 placeholder="소속 교회" name ='parentId'
//                 value={search} onChange={event => setSearch(event.target.value)}/>
//                 { (showHint && search.length>0  ) && (
//                     <div className="autoContainer">
//                         {
//                             getDropDownlOrganisations
//                             .filter(({ name }) => name.indexOf(search.toLowerCase()) > -1)
//                             .map((value, i) => {
//                                 return (
//                                 <div style={{cursor:'pointer'}} onClick={() => updateParent(value.name)}
//                                     className="option" key={i} tabIndex ={0}>
//                                     <span>{value.name}</span>
//                                 </div>
//                                 );
//                         })}
//                      </div>
//                 )}
//         </div>
//     </SemanticForm.Field>) }
