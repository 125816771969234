import React from 'react';
import BulletinTableMobileRow from './BulletinTableMobileRow';
import { Table } from 'semantic-ui-react';
import { IBulletin } from '../../../app/models/bulletin';

interface IProps {
    title: string;
    bulletins:IBulletin[];
}

const BulletinMobileTable: React.FC<IProps> = ({ title, bulletins }) => {
 
    return (
        <Table celled>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>{title}</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {bulletins.map((bulletin) => {
                    return <BulletinTableMobileRow key={bulletin.id} bulletin={bulletin} />
                })}
            </Table.Body>
        </Table>
    );
};

export default BulletinMobileTable;