import React, { Fragment, useContext, useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import { RootStoreContext } from '../../../app/stores/rootStore';
import BulletinList from './BulletinList';
import { observer } from 'mobx-react-lite';
import { Pagination } from 'semantic-ui-react'
import { AllCategory, BulletinCategories } from '../../../app/common/constants/categories';
import ListItemPlaceHolder from '../../../app/common/PlaceHolder/ListItemPlaceHolder';
import ListFilter from '../../../app/common/filter/ListFilter';

const BulletinDashboard: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const { loadBulletins, currentPage, setCurrentPage, totalPage, loadingInitial, 
        setCategory, category, getBulletinsByDate } = rootStore.bulletinStore;

    useEffect(() => {
        loadBulletins(AllCategory);
    }, []);

    const pageChangHandler = (event: React.MouseEvent<HTMLAnchorElement>, data: any) => {
        setCurrentPage(data.activePage);
        loadBulletins(AllCategory);
    }

    var menuItems=[AllCategory, BulletinCategories.City, BulletinCategories.WestRyde ];

    return (
        <Grid style={{ marginTop: '15px' }}>
            <Grid.Column width={10}>
                {
                    loadingInitial ? <ListItemPlaceHolder /> :
                        (
                            <Fragment>
                                <BulletinList bulletins ={getBulletinsByDate}/>
                                <Pagination
                                    firstItem={null}
                                    lastItem={null}
                                    defaultActivePage={currentPage} totalPages={totalPage}
                                    onPageChange={pageChangHandler}
                                />
                            </Fragment>
                        )
                }
            </Grid.Column>
            <Grid.Column width={6}>
                <ListFilter menuItems={menuItems} currentCategory={category}
                    selectMenuItem={setCategory} name ='타입'/>
            </Grid.Column>
        </Grid>
    );
};

export default observer(BulletinDashboard);


