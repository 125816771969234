import React from 'react';
import { Tab, Grid, Header, List } from 'semantic-ui-react';

const DawnWorshipTab = () => {

    return (
        <Tab.Pane style={{ backgroundColor: '#D3D3D3' }}>
            <Grid>
                <Grid.Column width={16}>
                    <Header content='새벽 얘배' />
                    <List>
                    <List.Item><List.Header>월요일-금요일</List.Header></List.Item>
                    <List.Item>&nbsp; &nbsp;1부: 오전 5:30 West Ryde 교회당</List.Item>
                    <List.Item>&nbsp; &nbsp;(2 Station St. West Ryde) </List.Item>
                    <List.Item></List.Item>          
                    <List.Item>&nbsp; &nbsp;2부: 오전 7시 </List.Item>
                    <List.Item>&nbsp; &nbsp;줌(ZOOM) ID: 465-471-4497 Password: 463412 </List.Item>
                    <List.Item>&nbsp; &nbsp;<a href="https://us04web.zoom.us/j/4654714497">https://us04web.zoom.us/j/4654714497</a></List.Item>
                    <List.Item></List.Item>
                    <List.Item><List.Header>토요일</List.Header></List.Item>
                    <List.Item>&nbsp; &nbsp;6:30 West Ryde 주안교회당에서 모입니다.</List.Item>          
                    <List.Item></List.Item>          
                    <List.Item>줌(ZOOM)으로도( ID: 465-471-4497), Password: 463412, </List.Item>
                    <List.Item><a href="https://us04web.zoom.us/j/4654714497">https://us04web.zoom.us/j/4654714497</a> 로 참여하실 수 있습니다.</List.Item>
                  </List>
                </Grid.Column>
            </Grid>
        </Tab.Pane>
    );
};

export default DawnWorshipTab;
