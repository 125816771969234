import React, { Fragment, useContext, useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import { Pagination } from 'semantic-ui-react'
import BulletinMobileTable from './BulletinMobileTable';
import BulletinTableItemPlaceHolder from '../dashboard/BulletinTableItemPlaceHolder';
import { BulletinCategories } from '../../../app/common/constants/categories';

const CityMobileBulletinMobilePage: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const { loadBulletins, currentPage, setCurrentPage, 
        totalPage, loadingInitial, getBulletinsByDate } = rootStore.bulletinStore;

    useEffect(() => {
        loadBulletins(BulletinCategories.City);
    }, []);

    const pageChangHandler = (event: React.MouseEvent<HTMLAnchorElement>, data: any) => {
        setCurrentPage(data.activePage);
        loadBulletins(BulletinCategories.City);
    }

    return (
        <Grid style={{ marginTop: '15px' }}>
            <Grid.Column width={16}>
                {loadingInitial ? <BulletinTableItemPlaceHolder />
                    : <Fragment>
                        <BulletinMobileTable 
                            bulletins={getBulletinsByDate}
                            title="City 주보"  />
                        <Pagination
                            firstItem={null}
                            lastItem={null}
                            defaultActivePage={currentPage} totalPages={totalPage}
                            onPageChange={pageChangHandler}
                        />
                    </Fragment>
                }
            </Grid.Column>
        </Grid>
    );
};

export default observer(CityMobileBulletinMobilePage);


