import React, { useContext } from 'react';
import { Item, Button, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { IGallery } from '../../../app/models/gallery';
import ConfirmationModal from '../../../app/common/modals/ConfirmationModal';
import {format} from 'date-fns';

const GalleryListItem: React.FC<{ gallery: IGallery }> = ({ gallery }) => {
    const rootStore = useContext(RootStoreContext);
    const { openModal } = rootStore.modalStore;
    const { deleteById } = rootStore.galleryStore;

    return (
        <Segment.Group>
            <Segment key={gallery.id}>
                <Item.Group>
                    <Item>
                        <Item.Content>
                            <Item.Header>
                            {gallery.title}
                            </Item.Header>
                            <Item.Description>
                              {gallery.organisation.name}  - {format(new Date(gallery.date),'dd MMM yyyy')}
                            </Item.Description>
                        </Item.Content>
                    </Item>
                </Item.Group>
            </Segment>
            <Segment clearing>
                <Button floated="right" content="Delete" color="red"
                    onClick={() => openModal(<ConfirmationModal
                        id={gallery.id}
                        subject={'Delete your Gallery'}
                        message={'Are you sure you want to delete your Gallery'}
                        confirmById={deleteById}
                    />)}
                /> 
                <Button as={Link} to={`/manageGallery/${gallery.id}`} floated="right" content="Edit" color="blue" />
                <Button as={Link} to={`/manageGalleryPhoto/${gallery.id}`} floated="right" content="Edit Photo" color="green" />
                <Button as={Link} to={`/gallery/${gallery.id}`} floated="right" content="View" color="grey" />
            </Segment>
        </Segment.Group>
    );
};

export default GalleryListItem;