import React, { Fragment } from 'react';
import NewComerListItem from './NewComerListItem'
import { INewComer } from '../../../app/models/newComer';

interface IProps {
    newComers : INewComer[],
    isMobile :boolean,
}

const NewComerList: React.FC<IProps> = ({newComers, isMobile}) => {
    return (
        <Fragment>
            {newComers.map((newComer) => {
                return <NewComerListItem key={newComer.id} 
                    isMobile={isMobile}
                    newComer={newComer} />
            })}
        </Fragment>
    );
};

export default NewComerList;