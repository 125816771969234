// SocialMediaLinks.js
import React from 'react';
import { Container, Icon, Grid } from 'semantic-ui-react';

const SocialMediaLinks = () => {
  return (
    <Container textAlign="center" >
      {/* <Header as="h1">Follow Us</Header> */}
      <Grid centered columns={2}>
        <Grid.Column textAlign="center">
          <a
            href="https://www.instagram.com/sydneyjooanchurch_official"
            target="_blank"
            rel="noopener noreferrer">
            <Icon name="instagram" size="huge" color="purple" />
            <p>Instagram</p>
          </a>
        </Grid.Column>
        <Grid.Column textAlign="center">
          <a
            href="https://www.youtube.com/c/SYDNEYJOOANCHURCH"
            target="_blank"
            rel="noopener noreferrer">
            <Icon name="youtube" size="huge" color="red" />
            <p>YouTube</p>
          </a>
        </Grid.Column>
      </Grid>
    </Container>
  );
};

export default SocialMediaLinks;

// style={{ marginTop: '5px', marginBottom: '15px' }}