import React from 'react';
import { Tab, Grid } from 'semantic-ui-react';
import CityServiceTab from './tab/CityServiceTab';
import WestRydeServiceTab from './tab/WestRydeServiceTab';
import TheHeartTab from './tab/TheHeartTab';
import DawnWorshipTab from './tab/dawnWorshipTab';

const paneFontSize = '13px';
const panes = [
    { menuItem: { key: 'service1', content: 'City', style: { fontSize: paneFontSize } }, render: () => <CityServiceTab /> },
    { menuItem: { key: 'service2', content: 'West Ryde', style: { fontSize: paneFontSize } }, render: () => <WestRydeServiceTab /> },
    { menuItem: { key: 'service3', content: '목요 찬양', style: { fontSize: paneFontSize } }, render: () => <TheHeartTab /> },
    { menuItem: { key: 'service4', content: '새벽 예배', style: { fontSize: paneFontSize } }, render: () => <DawnWorshipTab /> },
  ];

interface IProps {
    setActiveTab: (activeIndex: any) => void;
}

const ServiceContent: React.FC<IProps> = ({ setActiveTab }) => {
    return (
        <Grid>
            <Grid.Column width={1}></Grid.Column>
            <Grid.Column width={14}>
                <Tab
                    panes={panes}
                    onTabChange={(e, data) => setActiveTab(data.activeIndex)} />
            </Grid.Column>
            <Grid.Column width={1}></Grid.Column>
        </Grid>
    );
};

export default ServiceContent;


// { menuItem: { key: 'service5', content: 'JUAN INTERNATIONAL MINISTRY', style: { fontSize: paneFontSize } }, render: () => <InternationalMinistryTab /> }
