import React, { Fragment, useContext, useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import { Pagination } from 'semantic-ui-react'
import ListItemPlaceHolder from '../../../app/common/PlaceHolder/ListItemPlaceHolder';
import BelieverCardList from './BelieverCardList';
import { BelieverStatus } from '../../../app/common/constants/believerStatus';
import { SortOrder } from '../../../app/common/constants/sortOrder';

const NewBelieverCardDashboard: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const { getBelievers, loadBelievers, currentPage, setCurrentPage, totalPage, loadingInitial,
        deleteById } = rootStore.believerStore;

    const { openModal } = rootStore.modalStore;

    useEffect(() => {
        loadBelievers(BelieverStatus.New, SortOrder.NameAsc, '','');
    }, []);

    const pageChangHandler = (event: React.MouseEvent<HTMLAnchorElement>, data: any) => {
        setCurrentPage(data.activePage);
        loadBelievers(BelieverStatus.New, SortOrder.NameAsc, '','');
    }

    return (
        <Grid style={{ marginTop: '15px' }}>
            <Grid.Column width={16}>
                {
                    loadingInitial ? <ListItemPlaceHolder /> :
                        (
                            <Fragment>
                                <BelieverCardList
                                    openModal ={openModal}
                                    deleteById = {deleteById}
                                    believers={getBelievers} 
                                    itemsPerRow={1}/>
                                <Pagination
                                    style={{marginTop:15}}
                                    firstItem={null}
                                    lastItem={null}
                                    defaultActivePage={currentPage} totalPages={totalPage}
                                    onPageChange={pageChangHandler}
                                />
                            </Fragment>
                        )
                }
            </Grid.Column>
        </Grid>
    );
};

export default observer(NewBelieverCardDashboard);


