import { IMainBanner } from '../models/mainBanner';
import { RootStore } from './rootStore';
import { observable, action, computed, runInAction } from 'mobx';
import agent from '../api/agent';

export default class HomeStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @observable IMainBanner: IMainBanner | null = null;
    @observable mainBannerRegistry = new Map();

    @observable loadingInitial = true;

    @computed get getMainBanner() {
        return Array.from(this.mainBannerRegistry.values());
    }

    @action loadCityService = async () => {
        try {
            let cityServices = await agent.Service.city();

            runInAction('loading city service', () => {
                if (cityServices) {
                }
            });
        } catch (error) {
            throw error;
        }
    };


    @action loadWestRydeService = async () => {
        try {
            let westRydeServices = await agent.Service.westRyde();

            runInAction('loading westRyde service', () => {
                if (westRydeServices) {
                }
            });
        } catch (error) {
            throw error;
        }
    };


    @action loadMainBanner = async () => {
        try {
            let mainBanners = await agent.Home.mainBanner();

            runInAction('loading main Banner', () => {
                if (mainBanners) {
                    this.mainBannerRegistry.clear();
                    mainBanners.forEach((mainBanner: any) => {
                        this.mainBannerRegistry.set(mainBanner.id, mainBanner);
                    });

                    this.loadingInitial = false;
                }
            });
        } catch (error) {
            runInAction('load main Banner error', () => {
                this.loadingInitial = false;
            });
            throw error;
        }
    };
}