import React, { useState, useContext, useEffect } from 'react';
import { Segment, Form, Button, Grid } from 'semantic-ui-react';
import { v4 as uuid } from 'uuid';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps } from 'react-router-dom';
import { Form as FinalForm, Field } from 'react-final-form';
import TextInput from '../../../app/common/form/TextInput';
import TextAreaInput from '../../../app/common/form/TextAreaInput';
import { combineValidators, isRequired, composeValidators, hasLengthGreaterThan } from 'revalidate';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { ReportFormValues } from '../../../app/models/report';

const validators = combineValidators({
    title: isRequired({ message: 'The title is required' }),
    description: composeValidators(
        isRequired('Description'),
        hasLengthGreaterThan(3)({
            message: 'Description needs to be at least 3 characters'
        })
    )(),
});

interface ReportDetailParams {
    id: string;
}

const ReportForm: React.FC<RouteComponentProps<ReportDetailParams>> = ({ match, history }) => {
    const rootStore = useContext(RootStoreContext);
    const { createReport, editReport, loadReport, submitting } = rootStore.reportStore;
    // const { approvers } = rootStore.userStore;

    const [report, setReport] = useState(new ReportFormValues());
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (match.params.id) {
            setLoading(true);
            loadReport(match.params.id)
                .then((report) => setReport(report))
                .finally(() => setLoading(false));
        }
    }, [loadReport, match.params.id]);

    const handlFinalFormSubmit = (report: any) => {
        if (!report.id) {
            let newReport = {
                ...report,
                id: uuid()
            };
            createReport(newReport);
        } else {
            editReport(report);
        }
    };

    return (
        <Grid>
            <Grid.Column width={10}>
                <Segment clearing>
                    <FinalForm
                        validate={validators}
                        initialValues={report}
                        onSubmit={handlFinalFormSubmit}
                        render={({ handleSubmit, invalid, pristine }) => (
                            <Form onSubmit={handleSubmit} loading={loading}>
                                <Field name="title" placeholder="title" value={report.title} component={TextInput} />
                                {/* <Field
                                    component={SelectInput}
                                    options={approvers!.map((approver: IApprover) => ({
                                        key: approver.id,
                                        text: approver.displayName,
                                        value: approver.id
                                    }))}
                                    name="approverId"
                                    placeholder="Approver"
                                    value={report.approverId}
                                /> */}
                                <Field
                                    component={TextAreaInput}
                                    rows={10}
                                    name="description"
                                    placeholder="Description"
                                    value={report.description}
                                />
                                <Button
                                    floated="right"
                                    loading={submitting}
                                    disabled={loading || invalid || pristine}
                                    positive
                                    type="submit"
                                    content="Submit"
                                />
                                <Button
                                    disabled={loading || invalid || pristine}
                                    onClick={
                                        report.id ? () => history.push(`/reports/${report.id}`) : () => history.push('/reports')
                                    }
                                    floated="right"
                                    type="button"
                                    content="Cancel"
                                />
                            </Form>
                        )}
                    />
                </Segment>
            </Grid.Column>
        </Grid>
    );
};

export default observer(ReportForm);
