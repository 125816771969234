import React, { useContext, useState } from 'react';
import { Tab, Grid, Header, Button } from 'semantic-ui-react';
import { RootStoreContext } from '../../../app/stores/rootStore';
import ProfileEditForm from './ProfileEditForm';
import { observer } from 'mobx-react-lite';

const ProfileDescription = () => {
	const rootStore = useContext(RootStoreContext);
	const { updateProfile, profile, isCurrentUser } = rootStore.profileStore;
	const [editMode, setEditMode] = useState(false);

	const updateProfileHandler=(updatedProfile: any)=>{
		updateProfile(updatedProfile);
		setEditMode(false);		
	}

	return (
		<Tab.Pane>
			<Grid>
				<Grid.Column width={16}>
					<Header floated="left" icon="user" content={`About ${profile!.displayName}`} />
					{isCurrentUser && `role : ${profile!.role}`}
					{isCurrentUser && (
						<Button
							floated="right"
							basic
							content={editMode ? 'Cancel' : 'Edit Profile'}
							onClick={() => setEditMode(!editMode)}
						/>
					)}
				</Grid.Column>
				<Grid.Column width={16}>
					{editMode ? (
						<ProfileEditForm updateProfile={updateProfileHandler} profile={profile!} />
					) : (
							profile!.bio &&
								(<div dangerouslySetInnerHTML={{ __html: profile!.bio.replace(/\n/g, "<br />") }} />)						
						)}

				</Grid.Column>
			</Grid>
		</Tab.Pane>
	);
};

export default observer(ProfileDescription);
