import React, { Fragment } from 'react';
import BelieverListItem from './BelieverListItem';
import { IBeliever } from '../../../app/models/believer';

interface IProps {
    believers: IBeliever[],
    openModal:(modal:any) =>void,
    deleteById:(id: string) =>void
}

const BelieverList: React.FC<IProps> = ({believers, openModal, deleteById}) => {
    return (
        <Fragment>
            {believers.map((believer) => {
                return <BelieverListItem 
                    key={believer.id} 
                    openModal ={openModal}
                    deleteById = {deleteById}
                    believer={believer} />
            })}
        </Fragment>
    );
};

export default BelieverList;