import { observer } from 'mobx-react-lite';
import React, { useState, useContext, useEffect, Fragment } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Grid, Header, Table } from 'semantic-ui-react';
import { IVideo } from '../../app/models/video';
import { RootStoreContext } from '../../app/stores/rootStore';
import YouTube from 'react-youtube';

interface DetailParams {
    id: string;
}

const VideoDetail: React.FC<RouteComponentProps<DetailParams>> = ({ match, history }) => {
    const rootStore = useContext(RootStoreContext);
    const { loadVideo } = rootStore.videoStore;

    const [video, setVideo] = useState<IVideo>();

    useEffect(() => {
        if (match.params.id) {
            loadVideo(match.params.id)
                .then((video) => {
                    setVideo(video);
                })
                .finally();
        }
    }, [loadVideo, match.params.id]);

    const onReady = (event: { target: any }) => {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }
    const opts = {
        width: '100%',
        playerVars: {
            autoplay: 0 as 0
        }
    };

    return (
        <Grid style={{ marginTop: 25, paddingTop: 10, backgroundColor: 'white' }}>
            {video &&
                <Fragment>
                    <Grid.Column width={16}>
                        <Header floated="left" content={video.title} style={{ paddingLeft: 15 }} />
                        <Button
                            onClick={() => history.goBack()}
                            floated="right"
                            basic
                            content="Go Back"
                            style={{ marginRight: 10 }}
                        />
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <Table celled>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell width={9} style={{ verticalAlign: 'Top' }}>
                                        <YouTube videoId={video.youtubeVideoId} opts={opts} onReady={onReady} />
                                    </Table.Cell>
                                    <Table.Cell width={7}>
                                        <div dangerouslySetInnerHTML={{ __html: video.description }} />
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                </Fragment>
            }
        </Grid>
    );
};

export default observer(VideoDetail);


