import React from 'react';
import { Grid } from 'semantic-ui-react';

const EventPage = () => {

    return (
        <Grid>
            <Grid.Column width={16}>
                <h4>Event Page</h4>
            </Grid.Column>
        </Grid>
    );
};

export default EventPage;