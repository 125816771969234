import React, { useContext, Fragment } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../app/stores/rootStore';
import ReportListItem from './ReportListItem';

const ReportList: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const { getReports } = rootStore.reportStore;

    return (
        <Fragment>
            {getReports.map((report) => {
                return <ReportListItem key={report.id} report={report} />
            })}
        </Fragment>
    );
};

export default observer(ReportList);
