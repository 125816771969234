export const ROLE_MEMBER = 'MEMBER';
export const ROLE_ADMIN = 'ADMIN';
export const ROLE_APP_MANAGER= 'APP_MANAGER';
export const NEW_BELIEVER_MANAGER= 'NEW_BELIEVER_MANAGER';


export const getRoleText=(role:string)=>{
    if (role==='ALL')
        return 'ALL';
    if (role===ROLE_MEMBER)
        return '일반 사용자';
    if (role===ROLE_ADMIN)
        return '교인 관리자';
    if (role===ROLE_APP_MANAGER)
        return '교회 웹 관리자';
    if (role===NEW_BELIEVER_MANAGER)
        return '새가족 관리자';

    return '';
}
