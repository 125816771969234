import React, { Fragment } from 'react';
import { observer } from 'mobx-react-lite';
import VideoListItem from './VideoListItem';
import { IVideo } from '../../../app/models/video';

interface IProp {
    videos : IVideo[]
}

const VideoList = ({videos}: IProp) => {
    return (
        <Fragment>
            {videos.map((video) => {
                return <VideoListItem key={video.id} video={video} />
            })}
        </Fragment>
    );
};

export default observer(VideoList);