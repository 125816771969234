import { IGroup, IMember } from "../../models/group";
import { IUser } from "../../models/user";

export const combineDateTime = (date: Date, time: Date) => {
    const dateString = date.toISOString().split('T')[0];
    const timeString = time.toISOString().split('T')[1];

    return new Date(dateString + ' ' + timeString);
}

export const setGroupProps = (group: IGroup, user: IUser) => {
    if (user) {
        group.isMember = group.members.some(m => m.userName === user.userName);
    }
    return group;
}

export const createMember = (user: IUser): IMember => {
    return {
        displayName: user.displayName,
        userName: user.userName,
        image: user.image!
    }
}

export const getFontSize = (treeItemSize:number) : number =>{
    if (treeItemSize===1)
        return 9;
    if (treeItemSize===2)
        return 11;
    if (treeItemSize===3)
        return 13;
    if (treeItemSize===4)
        return 15;
    if (treeItemSize===5)
        return 17;
    if (treeItemSize===6)
        return 19;
    if (treeItemSize===7)
        return 21;
    if (treeItemSize===8)
        return 21;

    return 13;
}
