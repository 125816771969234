import React, { useContext } from 'react';
import { Modal, Menu, Container, Dropdown, Image, Button } from 'semantic-ui-react';
import { NavLink, Link } from 'react-router-dom';
import { RootStoreContext } from '../../app/stores/rootStore';
import logo from '../../assets/images/logo.png';
import LoginForm from '../user/LoginForm';
import { observer } from 'mobx-react-lite';
import NoRole from './role/NoRole';
import * as ROLES from '../../app/common/constants/roles';
import AppManager from './role/AppManager';
import AdminManager from './role/AdminManager';
import NewComerManager from './role/NewComerManager';

const logoStyle = {
	border: '0px', backgroundColor: 'white', margin: '0px', padding: '0px'
};

interface IProps {
	pathName: string;
}

const NavBar: React.FC<IProps> = ({ pathName }) => {
	const rootStore = useContext(RootStoreContext);
	const { user, logout } = rootStore.userStore;
	const { openModal } = rootStore.modalStore;

	return (
		<Menu fixed="top">
			<Container>
				<Menu.Item header as={NavLink} exact to="/" style={logoStyle}>
					<img src={logo} alt="logo" style={{ margin: '5px', width: '110px' }} />
				</Menu.Item>
				{( (!user || ( user && user.role === ROLES.ROLE_MEMBER)
					|| ( user && !user.role)
				) && (
					<NoRole pathName={pathName}>
						<div className="right item">
							{!user && (
								  <Button.Group>
										<Button basic color='blue' content='Login' onClick={() => openModal(<Modal.Content><LoginForm /></Modal.Content>)}/>
								</Button.Group>
							)}

							{user && (
								<Menu.Item>
									<Image avatar spaced="right" src={user.image || '/assets/user.png'} />
									<Dropdown pointing="top left" text={user.displayName}>
										<Dropdown.Menu>
											<Dropdown.Item as={Link} to={`/profile/${user.userName}`} text="My profile" icon="user" />
											<Dropdown.Item onClick={logout} text="Logout" icon="power" />
										</Dropdown.Menu>
									</Dropdown>
								</Menu.Item>)}
						</div>
					</NoRole>
				))}

				{  ((user && (user.role ===ROLES.NEW_BELIEVER_MANAGER  )) && (
					<NewComerManager pathName={pathName}>
						<Menu.Item position="right">
							<Image avatar src={user.image || '/assets/user.png'} />
							<Dropdown pointing="top left" text={user.displayName}>
								<Dropdown.Menu>
									<Dropdown.Item as={Link} to={`/profile/${user.userName}`} text="My profile" icon="user" />
									{/* <Dropdown.Item as={Link} to='/changePassword' text='Change password' icon='setting' />  */}
									<Dropdown.Item onClick={logout} text="Logout" icon="power" />
								</Dropdown.Menu>
							</Dropdown>
						</Menu.Item>
					</NewComerManager>
				))}

				{  ((user && (user.role ===ROLES.ROLE_APP_MANAGER  )) && (
					<AppManager pathName={pathName}>
						<Menu.Item position="right">
							<Image avatar src={user.image || '/assets/user.png'} />
							<Dropdown pointing="top left" text={user.displayName}>
								<Dropdown.Menu>
									<Dropdown.Item as={Link} to={`/profile/${user.userName}`} text="My profile" icon="user" />
									<Dropdown.Item as={Link} to='/changePassword' text='Change password' icon='setting' />
									<Dropdown.Item onClick={logout} text="Logout" icon="power" />
								</Dropdown.Menu>
							</Dropdown>
						</Menu.Item>
					</AppManager>
				))}

				{  ((user && (user.role ===ROLES.ROLE_ADMIN )) && (
					<AdminManager pathName={pathName}>
						<Menu.Item position="right">
							<Image avatar src={user.image || '/assets/user.png'} />
							<Dropdown pointing="top left" text={user.displayName}>
								<Dropdown.Menu>
									<Dropdown.Item as={Link} to={`/profile/${user.userName}`} text="My profile" icon="user" />
									<Dropdown.Item onClick={logout} text="Logout" icon="power" />
								</Dropdown.Menu>
							</Dropdown>
						</Menu.Item>
					</AdminManager>
				))}
			</Container>
		</Menu >
	);
};

export default observer(NavBar);


// <Dropdown.Item as={Link} to="/createJuanUser" text="만들기" />
/*
<Button.Or />
<Button basic color='blue' content='Register' onClick={() => openModal(<Modal.Content><RegisterForm /></Modal.Content>)}/>
<Dropdown.Item as={Link} to='/changePassword' text='Change password' icon='setting' />  */

/* <Image avatar spaced="right" src={user.image || '/assets/user.png'} /> */

/* <Menu.Item name="Login" position="right"
onClick={() => openModal(<Modal.Content><LoginForm /></Modal.Content>)} /> */

// Change Password   --- it should be fixed. ...
///<Dropdown.Item as={Link} to='/account' text='My Account' icon='setting' />


/* <Menu.Item name="Prayer"
style={(pathName === '/prayers'
|| pathName === '/prayerDashboard'
|| pathName === '/createPrayer'
	|| pathName.startsWith('/managePrayer')
	|| pathName.startsWith('/managePrayerPhoto')
) ? activeMenuItemStyle : menuItemStyle}>
<Dropdown pointing="top left" text='기도' >
	<Dropdown.Menu>
		<Dropdown.Item as={Link} to="/prayers" text="미리 보기" />
		<Dropdown.Item as={Link} to="/prayerDashboard" text="목록" />
		<Dropdown.Item as={Link} to="/createPrayer" text="만들기" />
	</Dropdown.Menu>
</Dropdown>
</Menu.Item> */



//<Dropdown.Item as={Link} to="/video_intro" text="소개" />

// import { history } from '../..';
// {
// 	user &&
// 	<Fragment>
// 		<Menu.Item name="목장 & 셀" as={NavLink} to="/groups" />
// 		<Menu.Item name="리포트" as={NavLink} to="/reports" />
// 	</Fragment>
// }
// {
// 	user && user.role === ROLE_APPROVER &&
// 	<Menu.Item name="approve / decline" as={NavLink} to="/approvals" />
// }

// const menuItemClicked = () => {
// 	history.push('/west-ryde');
// }

// <Menu.Item name="test" onClick={menuItemClicked}>
// <img src={'/assets/menu/test1.png'} />
// </Menu.Item>
