import React, { Fragment, useContext, useEffect } from 'react';
import { Grid, Breadcrumb, List, Header, Pagination, Image } from 'semantic-ui-react';
import { RootStoreContext } from '../../app/stores/rootStore';
import VideoTableItemPlaceHolder from '../video/dashboard/VideoTableItemPlaceHolder';
import { observer } from 'mobx-react-lite';
import { VideoCategories } from '../../app/common/constants/categories';
import TwoColumnTable from '../../app/common/tables/TwoColumnTable';
import TwoColumnTableRow from '../../app/common/tables/TwoColumnTableRow';

const SundayWorship = () => {
    const rootStore = useContext(RootStoreContext);
    const { loadVideos, currentPage, setCategory, getVideosByDate,
        setCurrentPage, totalPage, loadingInitial } = rootStore.videoStore;

    useEffect(() => {
        setCategory(VideoCategories.onlineService);
    }, [setCategory]);

    const pageChangHandler = (event: React.MouseEvent<HTMLAnchorElement>, data: any) => {
        setCurrentPage(data.activePage);
        loadVideos();
    }

    return (
        <Grid>
            <Grid.Column width={16}>
                {loadingInitial ? <VideoTableItemPlaceHolder />
                    : <Fragment>
                        <TwoColumnTable title='예배'>
                            <Fragment>
                                {getVideosByDate.map((video) => {
                                    return <TwoColumnTableRow id={video.id}
                                                title={video.title}
                                                link={`/video/${video.id}`}
                                                date={video.date} />
                                })}
                            </Fragment>
                        </TwoColumnTable>
                        <Pagination
                            firstItem={null}
                            lastItem={null}
                            defaultActivePage={currentPage} totalPages={totalPage}
                            onPageChange={pageChangHandler}
                        />
                    </Fragment>
                }
            </Grid.Column>
        </Grid>
    );
};

export default observer(SundayWorship);