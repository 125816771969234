export interface IJuanUser {
    id: string;
	displayName: string;
	role: string;
	email:string;
	emailConfirmed :boolean;
	phoneNumber :string;		
	bio:string;
	birthday?: Date;
	description :string;
	url:string;
}

export class JuanUserFormValues {
	// userName: string='';  //이름
	displayName: string='';
	role: string='';
	// email:string='';
	// phoneNumber :string='';		
	// bio:string='';
	// birthday?: Date = undefined;
	description :string='';
}


export interface IJuanUserResouceModel {
    data: IJuanUser[];
    count: number;
    currentPage: number;
    totalPage: number;
    pageSize: number;
}
