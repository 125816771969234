export interface IBeliever {
    id: string;
    name: string;
    status: string;
    phone: string;
    guider: string;
    newComerId:string;
    kakaotalkId: string;
    description: string;
    birthday: Date;
    url:string;
}

export class NewBelieverFormValues {
    id?: string = undefined;
    name: string = '';
    status: string = '';
    phone: string = '';
    kakaotalkId: string = '';
    description: string = '';
    birthday?: Date = undefined;
    url:string='';
    guider: string = '';
    newComerId:string='';
}


export class BelieverFormValues {
    id?: string = undefined;
    name: string = '';
    status: string = '';    
    phone: string = '';
    kakaotalkId: string = '';
    description: string = '';
    birthday?: Date = undefined;
    url:string='';
}

export interface IBelieverResouceModel {
    data: IBeliever[];
    count: number;
    currentPage: number;
    totalPage: number;
    pageSize: number;
}

export class AssignForm {
    isLeader:boolean=false;
    organisationName :string ='';
}