export interface IVideo {
    id: string;
    title: string;
    category: string;
    isMain: boolean;
    description: string;
    youtubeVideoId: string;
    sourceUrl: string;
    date: Date;
}

export class VideoFormValues {
    id?: string = undefined;
    title: string = '';
    category: string = '';
    isMain: boolean = false;
    description: string = '';
    youtubeVideoId: string = '';
    sourceUrl: string = '';
    date?: Date = undefined;
}

export interface IVideoResouceModel {
    data: IVideo[];
    count: number;
    currentPage: number;
    totalPage: number;
    pageSize: number;
}

