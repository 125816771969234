import React, { Fragment } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Menu, Dropdown } from 'semantic-ui-react';
import * as Styles from '../styles';

interface IProp {
    pathName:string,
    children:JSX.Element
}

const AdminManager = ({pathName, children}:IProp) => {
    return (
        <Fragment>
            <Menu.Item name="Organisation"
                style={(pathName === '/organisations' || pathName === '/createOrganisation'
                || pathName === '/createOrganisationTree'
                || pathName === '/organisationTree'	|| pathName.startsWith('/manageOrganisation')
                ) ? Styles.activeMenuItemStyle : Styles.menuItemStyle}>
                <Dropdown pointing="top left" text='교회' >
                    <Dropdown.Menu>								
                        <Dropdown.Item as={Link} to="/organisationTree" text="폴더 뷰" />
                        <Dropdown.Item as={Link} to="/organisations" text="목록" />
                        <Dropdown.Item as={Link} to="/createOrganisationTree" text="만들기" />
                    </Dropdown.Menu>
                </Dropdown>
            </Menu.Item>

            <Menu.Item name='사용자' as={NavLink} to='/juanUsers' 
            style={pathName === '/juanUsers' ? Styles.activeMenuItemStyle : Styles.menuItemStyle} />

            <Menu.Item name="NewComer"
                style={(pathName === '/createNewComer'
                    || pathName.startsWith('/newComers')
                    || pathName =='/newBelievers'
                    || pathName.startsWith('/manageNewComer')
                    || pathName.startsWith('/createNewBeliever')
                    || pathName.startsWith('/manageNewBeliever')
                ) ? Styles.activeMenuItemStyle : Styles.menuItemStyle}>						
                <Dropdown pointing="top left" text='새가족' >
                    <Dropdown.Menu>								
                        <Dropdown.Item as={Link} to="/newBelievers" text="새가족 목록" />
                        <Dropdown.Item as={Link} to="/newComers" text="새가족방 목록" />
                        <Dropdown.Item as={Link} to="/createNewComer" text="새가족방 만들기" />
                    </Dropdown.Menu>
                </Dropdown>
            </Menu.Item>

            <Menu.Item name="Believer"
                style={(pathName === '/believers' || pathName === '/createBeliever'
                    || pathName === '/believerCards'
                    || pathName === '/believerTree'
                    || pathName.startsWith('/assignOrgForm')
                    || pathName.startsWith('/manageBeliever')                    
                ) ? Styles.activeMenuItemStyle : Styles.menuItemStyle}>
                <Dropdown pointing="top left" text='주안 교인' >
                    <Dropdown.Menu>								
                        <Dropdown.Item as={Link} to="/believerTree" text="폴더뷰" />
                        <Dropdown.Item as={Link} to="/believers" text="목록" />
                        <Dropdown.Item as={Link} to="/createBeliever" text="만들기" />
                    </Dropdown.Menu>
                </Dropdown>
            </Menu.Item>

            <Menu.Item name="Gallery"
                style={(pathName === '/createGallery'
                    || pathName ==='/galleryDashboard'                    
                    || pathName ==='/galleryTree'
                    || pathName.startsWith('/manageGallery')
                ) ? Styles.activeMenuItemStyle : Styles.menuItemStyle}>						
                <Dropdown pointing="top left" text='사진첩' >
                    <Dropdown.Menu>								
                        <Dropdown.Item as={Link} to="/galleryTree" text="폴더 뷰" />
                        <Dropdown.Item as={Link} to="/galleryDashboard" text="목록" />									
                        <Dropdown.Item as={Link} to="/createGallery" text="만들기" />
                    </Dropdown.Menu>
                </Dropdown>
            </Menu.Item>

            {children}
    </Fragment>
    );
};

export default AdminManager;


/* <Dropdown.Item as={Link} to="/believerCards" text="카드 뷰" />  --- mobile view */
