import React, { useState, useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { RouteComponentProps } from 'react-router-dom';
import { Segment,  Button, Grid, Form as SemanticForm } from 'semantic-ui-react';
import { v4 as uuid } from 'uuid';
import 'suneditor/dist/css/suneditor.min.css';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import TextInputField from '../../../app/common/formikForm/TextInputField';
import SelectInputField from '../../../app/common/formikForm/SelectInputField';
import DateInputField from '../../../app/common/formikForm/DateInputField';
import { HelpFormValues } from '../../../app/models/help';

const validationSchema = Yup.object({
    title:Yup.string().required('title is required').max(100, 'max length is 100 characters'),
    category:Yup.string().required('category is required'),
    date:Yup.string().required('date is required').nullable()
});

interface iFormParams {
    id: string;
};

const helpCategory = [
    {  text: '교회 웹사이트', value:'app' },
    {  text: '미디어', value: 'media' }
];

const HelpForm: React.FC<RouteComponentProps<iFormParams>> = ({ match, history }) => {

    const rootStore = useContext(RootStoreContext);
 
    const { createHelp, editHelp, submitting, loadHelp } = rootStore.helpStore;

    const [image, setImage] = useState<Blob | null>(null);

    const [help, setHelp] = useState(new HelpFormValues());

    useEffect(()=>{        
        if (match.params.id) {
            loadHelp(match.params.id)
                .then((help) => {
                    help.date = new Date(help.date);
                    setHelp(help);
                })
                .finally();
        }
    },[match.params.id, loadHelp]);

    const handleFormSubmit =(help:any)=>{
        let formData = new FormData();

        formData.append('Category', help.category);
        formData.append('Title', help.title);
        formData.append('Image', image!);

        var helpDate = new Date(help.date);
        formData.append('Date', helpDate.toLocaleDateString("en-AU"));

        if (!help.id) {
            var id = uuid();
            formData.append('Id', id);
            createHelp(formData);
        } else {
            formData.append('Id', help.id);
            editHelp(formData, help.id);
        }
    }

    const fileSelectedHandler = (e: any) => {
        setImage(e.target.files[0]);
    }

    return (<Grid style={{ marginTop: '15px' }}>
        <Grid.Column width={10}>
            <Segment clearing>
                    <Formik enableReinitialize validationSchema = {validationSchema}
                    onSubmit={values=> handleFormSubmit(values)}
                    initialValues={help}>
                    {( { values, handleSubmit, isValid, isSubmitting, dirty})=>(
                        <Form className='ui form' onSubmit={handleSubmit} autoComplete ='off'>
                            <SelectInputField options={helpCategory} name='category' placeholder='분류' />                            
                            <TextInputField type='text' name="title" placeholder="제목"/>   
                            <DateInputField placeholderText='날짜' name ='date'                                 
                                showTimeSelect={false} timeCaption='time' dateFormat='dd MMM yyyy' />

                            <SemanticForm.Field>
                                {values.filePath ? <p>{values.filePath}</p> : 
                                    <input type="file" onChange={fileSelectedHandler} 
                                    accept =".pdf .docx"/> }
                            </SemanticForm.Field>

                                <Button
                                    floated="right"
                                    loading={submitting}
                                    disabled={!isValid || isSubmitting }
                                    positive
                                    type="submit"
                                    content="Save" />
                                <Button
                                    onClick={() => history.push('/helps')}
                                    floated="right"
                                    type="button"
                                    content="Cancel" />
                        </Form>
                    )}
                </Formik>
            </Segment>
        </Grid.Column>
    </Grid>);
};

export default observer(HelpForm);
