export interface IUser {
	userName: string;
	displayName: string;
	token: string;
	image?: string;
	role: string;
}

export interface IUserFormValues {
	email: string;
	password: string;
	displayName?: string;
	userName?: string;
}


export interface IPassword {
	currentPassword: string;
	newPassword: string;
    confirmPassword: string;
}

export class ChangePasswordFormValues {
	currentPassword:string ='';
	newPassword: string='';
    confirmPassword: string='';
}

export interface IApprover {
	id: string;
	userName: string;
	displayName: string;
	image: string;
	role: string;
}