import React from "react";
import { Table } from "semantic-ui-react";

interface IProps {
    title:string;
    children:JSX.Element
}

const TwoColumnTable: React.FC<IProps> = ({ children, title }) => {
    return (
        <Table celled>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>{title}</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {children}
            </Table.Body>
        </Table>
    );
}

export default TwoColumnTable;

// <Table.Body>
// </Table.Body>