import React, { Fragment } from 'react';
import BulletinListItem from './BulletinListItem';
import { IBulletin } from '../../../app/models/bulletin';

interface IProps{
    bulletins: IBulletin[]
}

const BulletinList = ({bulletins}: IProps) => {

    return (
        <Fragment>
            {bulletins.map((bulletin) => {
                return <BulletinListItem key={bulletin.id} bulletin={bulletin} />
            })}
        </Fragment>
    );
};

export default BulletinList;