import axios, { AxiosResponse } from 'axios';
//it looks strange but it is accurate.
import { history } from '../..';
import { toast } from 'react-toastify';
import { IPassword, IUser, IUserFormValues } from '../models/user';
import { IProfile, IPhoto, IProfileDetailResouceModel } from '../models/profile';
import { IGroup, IGroupResouceModel } from '../models/group';
import { IReport, IReportResouceModel } from '../models/report';
import { IApprovalResouceModel } from '../models/approval';
import { IBulletin, IBulletinPhoto, IBulletinResouceModel } from '../models/bulletin';
import { IVideo, IVideoResouceModel } from '../models/video';
import { IOrganisation } from '../models/organisation';
import { IGallery, IGalleryPhoto, IGalleryResouceModel } from '../models/gallery';
import { IPrayer, IPrayerResouceModel } from '../models/prayer';
import { IHelp, IHelpResouceModel } from '../models/help';
import { IJuanUser, IJuanUserResouceModel } from '../models/juanUser';
import { IBeliever, IBelieverResouceModel } from '../models/believer';
import { INewComer, INewComerResouceModel } from '../models/newComer';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(
	(config) => {
		const token = window.localStorage.getItem('yb_app_jwt');
		if (token) config.headers.Authorization = `Bearer ${token}`;
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

axios.interceptors.response.use(undefined, (error) => {
	if (error.message === 'Network Error' && !error.response) {
		toast.error('Network error - make sure API is running!');
	} else {

		const { status, data, config, headers } = error.response;
		if (status === 400 || status === 401) {
			error.response.statusText = data;
		}

		// else if (status === 401 && headers['www-authenticate'] === 'Bearer error="invalid_token", error_description="The token is expired"') {
		// 	window.localStorage.removeItem('yb_app_jwt');
		// 	history.push('/')
		// 	toast.info('Your session has expired, please login again')
		// }

		else if (status === 404) {
			history.push('/notfound');
		}
		// else if (status === 400 && config.method === 'get' && data.errors.hasOwnProperty('id')) {
		// 	history.push('/notfound');
		// }
		else if (status === 500) {
			toast.error('Server error - check the terminal for more info');
		}
		throw error.response;
	}
});

const sleep = (ms: number) => (response: AxiosResponse) =>
	new Promise<AxiosResponse>((resolve) => setTimeout(() => resolve(response), ms));

const responseBody = (response: AxiosResponse) => {
	if (response && response.data) return response.data;
	return null;
};

const requests = {
	get: (url: string) => {
		return axios.get(url).then(responseBody)
	},
	post: (url: string, body: {}) => axios.post(url, body).then(responseBody),
	put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
	del: (url: string) => axios.delete(url).then(sleep(10)).then(responseBody),
	postForm: (url: string, file: Blob) => {
		let formData = new FormData();
		formData.append('File', file);
		return axios
			.post(url, formData, {
				headers: { 'Content-type': 'multipart/form-data' }
			})
			.then(responseBody);
	},
	putFormData: (url: string, formData: FormData) => {
		return axios
			.put(url, formData, {
				headers: { 'Content-type': 'multipart/form-data' }
			})
			.then(responseBody);
	},
	postFormData: (url: string, formData: FormData) => {
		return axios
			.post(url, formData, {
				headers: { 'Content-type': 'multipart/form-data' }
			})
			.then(responseBody);
	}
};

const Home = {
	mainBanner :() =>requests.get('/home')
}

const Service = {
	westRyde :() =>requests.get('/service/westryde'),
	city :() =>requests.get('/service/city')
}

const Activities = {
	delete: (id: string) => requests.del(`/activities/${id}`),
};

const Approvals = {
	list: (params: URLSearchParams): Promise<IApprovalResouceModel> =>
		axios.get('/approvals', { params: params }).then(responseBody),
	approve: (id: string) => requests.post(`/approvals/${id}/approve`, {}),
	decline: (id: string) => requests.post(`/approvals/${id}/decline`, {}),
};

const Helps = {
	create: (formData: FormData): Promise<IHelp> => requests.postFormData('/helps', formData),
	update: (formData: FormData, id: string): Promise<IHelp> => requests.putFormData(`/helps/${id}`, formData),
	list: (params: URLSearchParams): Promise<IHelpResouceModel> =>
		axios.get('/helps', { params: params }).then(responseBody),
	details: (id: string) => requests.get(`/helps/${id}`),
	delete: (id: string) => requests.del(`/helps/${id}`),
};

const Believers = {
	create: (formData: FormData): Promise<IBeliever> => requests.postFormData('/believers', formData),
	update: (formData: FormData, id: string): Promise<IBeliever> => requests.putFormData(`/believers/${id}`, formData),
	assign: (formData: FormData) => requests.putFormData(`/believers/assign`, formData),
	unassign: (formData: FormData) => requests.putFormData(`/believers/unassign`, formData),
	promoteById: (formData: FormData, id: string) => requests.put(`/believers/promote/${id}`, formData),	
	list: (params: URLSearchParams): Promise<IBelieverResouceModel> =>
		axios.get('/believers', { params: params }).then(responseBody),
	detailsWithNewComer: (id: string) => requests.get(`/believers/${id}/newComer`),
	details: (id: string) => requests.get(`/believers/${id}`),
	delete: (id: string) => requests.del(`/believers/${id}`),
};


const Bulletins = {
	create: (formData: FormData): Promise<IBulletin> => requests.postFormData('/bulletins', formData),
	update: (formData: FormData, id: string): Promise<IBulletin> => requests.putFormData(`/bulletins/${id}`, formData),

	list: (params: URLSearchParams): Promise<IBulletinResouceModel> =>
		axios.get('/bulletins', { params: params }).then(responseBody),
	// details: (id: string) => requests.get(`/bulletins/${id}`),
	details: (id: string, includePhoto: boolean) => requests.get(`/bulletins/${id}?includePhoto=${includePhoto}`),
	uploadPhoto: (formData: FormData): Promise<IBulletinPhoto> => requests.postFormData(`/bulletins/photo`, formData),
	deletePhoto: (id: string) => requests.del(`/bulletins/photo/${id}`),
	delete: (id: string) => requests.del(`/bulletins/${id}`),
};

const Galleries = {
	create: (gallery: IGallery) => requests.post('/galleries', gallery),
	update: (gallery: IGallery) => requests.put(`/galleries/${gallery.id}`, gallery),
	list: (params: URLSearchParams): Promise<IGalleryResouceModel> =>
		axios.get('/galleries', { params: params }).then(responseBody),
	details: (id: string, includePhoto: boolean) => requests.get(`/galleries/${id}?includePhoto=${includePhoto}`),
	uploadPhoto: (formData: FormData): Promise<IGalleryPhoto> => requests.postFormData(`/galleries/photo`, formData),
	deletePhoto: (id: string) => requests.del(`/galleries/photo/${id}`),
	delete: (id: string) => requests.del(`/galleries/${id}`),
};

const Prayers = {
	create: (prayer: IPrayer) => requests.post('/prayers', prayer),
	update: (prayer: IPrayer) => requests.put(`/prayers/${prayer.id}`, prayer),
	list: (params: URLSearchParams): Promise<IPrayerResouceModel> =>
		axios.get('/prayers', { params: params }).then(responseBody),
	details: (id: string) => requests.get(`/prayers/${id}`),
	delete: (id: string) => requests.del(`/prayers/${id}`),
};

const JuanUsers = {
	create: (juanUser: IJuanUser) => requests.post('/juanUsers', juanUser),
	update: (juanUser: IJuanUser) => requests.put(`/juanUsers/${juanUser.id}`, juanUser),
	list: (params: URLSearchParams): Promise<IJuanUserResouceModel> =>
		axios.get('/juanUsers', { params: params }).then(responseBody),
	details: (id: string) => requests.get(`/juanUsers/${id}`),
	delete: (id: string) => requests.del(`/juanUsers/${id}`),
};


const Groups = {
	list: (params: URLSearchParams): Promise<IGroupResouceModel> =>
		axios.get('/groups', { params: params }).then(responseBody),
	create: (group: IGroup) => requests.post('/groups', group),
	update: (group: IGroup) => requests.put(`/groups/${group.id}`, group),
	details: (id: string) => requests.get(`/groups/${id}`),
	attend: (id: string) => requests.post(`/groups/${id}/attend`, {}),
	unattend: (id: string) => requests.del(`/groups/${id}/attend`)
};

const NewComers = {
	create: (formData: FormData): Promise<INewComer> => requests.postFormData('/newComers', formData),
	update: (formData: FormData, id: string): Promise<INewComer> => requests.putFormData(`/newComers/${id}`, formData),
	list: (params: URLSearchParams): Promise<INewComerResouceModel> =>
		axios.get('/newComers', { params: params }).then(responseBody),
	details: (id: string, includeBelievers:boolean) => requests.get(`/newComers/${id}?includeBelievers=${includeBelievers}`),
	delete: (id: string) => requests.del(`/newComers/${id}`),
};

const Organisations = {
	// list: (params: URLSearchParams): Promise<IOrganisationResouceModel> =>
	// 	axios.get('/organisations', { params: params }).then(responseBody),
	list: (currentPage: number, pageSize: number, category:string) =>
		requests.get(`/organisations?currentPage=${currentPage}&pageSize=${pageSize}&category=${category}`),
	options: () => requests.get(`/organisations/options`),
	prayerOptions: () => requests.get(`/organisations/prayerOptions`),	
	listAll: (isActive: boolean) => requests.get(`/organisations/all?isActive=${isActive}`),
	create: (organisation: IOrganisation) => requests.post('/organisations', organisation),
	update: (organisation: IOrganisation) => requests.put(`/organisations/${organisation.id}`, organisation),
	treeView: () => requests.get(`/organisations/treeView`),
	details: (id: string) => requests.get(`/organisations/${id}`),
	delete: (id: string) => requests.del(`/organisations/${id}`)
};

const Reports = {
	list: (params: URLSearchParams): Promise<IReportResouceModel> =>
		axios.get('/reports', { params: params }).then(responseBody),
	create: (report: IReport) => requests.post('/reports', report),
	update: (report: IReport) => requests.put(`/reports/${report.id}`, report),
	details: (id: string) => requests.get(`/reports/${id}/detail`)
};

const Profiles = {
	get: (userName: string): Promise<IProfileDetailResouceModel> => requests.get(`/profiles/${userName}`),
	uploadPhoto: (photo: Blob): Promise<IPhoto> => requests.postForm(`/profiles/photos`, photo),
	setMainPhoto: (id: string) => requests.post(`/profiles/photos/${id}/setMain`, {}),
	deletePhoto: (id: string) => requests.del(`/profiles/photos/${id}`),
	follow: (userName: string) => requests.post(`/profiles/${userName}/follow`, {}),
	unfollow: (userName: string) => requests.del(`/profiles/${userName}/follow`),
	listFollowings: (userName: string, predicate: string) =>
		requests.get(`/profiles/${userName}/follow?predicate=${predicate}`),
	updateProfile: (profile: Partial<IProfile>) => requests.put(`/profiles`, profile),
	listActivities: (username: string, predicate: string) =>
		requests.get(`/profiles/${username}/activities?predicate=${predicate}`)
};

const User = {
	currentUser: (): Promise<IUser> => requests.get('/user'),
	login: (user: IUserFormValues): Promise<IUser> => requests.post(`/user/login`, user),
	register: (user: IUserFormValues): Promise<IUser> => requests.post(`/user/register`, user),
	verifyEmail :(token:string, email:string) : Promise<void> =>requests.post('/user/verifyEmail', {token, email}),
	resendEmailVerification :(email:string) : Promise<void> =>requests.get(`/user/resendEmailVerification?email=${email}`),
	changePassword: (password: IPassword): Promise<IUser> => requests.post(`/user/changePassword`, password),
	fbLogin: (accessToken: string) => requests.post(`/user/facebook`, { accessToken })
};

const Videos = {
	create: (video: IVideo) => requests.post('/videos', video),
	list: (params: URLSearchParams): Promise<IVideoResouceModel> =>
		axios.get('/videos', { params: params }).then(responseBody),
	update: (video: IVideo) => requests.put(`/videos/${video.id}`, video),
	details: (id: string) => requests.get(`/videos/${id}`),
	delete: (id: string) => requests.del(`/videos/${id}`),
};

export default {
	Activities,
	Approvals,
	Bulletins,
	Believers,
	Groups,
	Home,
	Helps,
	Organisations,
	Galleries,
	Prayers,
	Profiles,
	Reports,
	User,
	JuanUsers,
	Service,
	Videos,
	NewComers
};
