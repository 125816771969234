import React from 'react';
import { Message } from 'semantic-ui-react';

interface IProps {
    content: string;
}

const BasicErrorMessage: React.FC<IProps> = ({content}) => {
    if (!content)
        return null;
    return (
        <Message error visible>
            {content}
        </Message>
    );
};

export default BasicErrorMessage;

//<Message.Header>{error && error.statusText}</Message.Header>
// {error.data && Object.keys(error.data.errors).length > 0 && (
//     <Message.List>
//         {Object.values(error.data.errors).flat().map((err, i) => {
//             let text = [err];
//             return <Message.Item key={i}>{text}</Message.Item>
//         })}
//     </Message.List>
// )}
