import { observable, computed, action, runInAction } from 'mobx';
import { IPassword, IUser, IUserFormValues } from '../models/user';
import agent from '../api/agent';
import { RootStore } from './rootStore';
import { history } from '../..';
import { toast } from 'react-toastify';

export default class UserStore {
	rootStore: RootStore;
	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
	}

	@observable user: IUser | null = null;
	@observable loading = false;
    @observable submitting = false;
	@observable errorMessage: string = '';

	@computed get isLoggedIn() {
		return !!this.user;
	}

	@action login = async (values: IUserFormValues) => {
		try {
			const user = await agent.User.login(values);
			runInAction(() => {
				this.user = user;
				this.rootStore.commonStore.setToken(user.token);
				this.rootStore.modalStore.closeModal();
				// history.push(`/profile/${user.userName}`);
			});
		} catch (error) {
			throw error;
		}
	};

	@action loginMobile = async (values: IUserFormValues) => {
		try {
			const user = await agent.User.login(values);
			runInAction(() => {
				this.user = user;
				this.rootStore.commonStore.setToken(user.token);
				history.push(`/home`);
			});
		} catch (error) {
			throw error;
		}
	};

	@action register = async (values: IUserFormValues) => {
		try {
			await agent.User.register(values);
			// this.rootStore.commonStore.setToken(user.token);
			this.rootStore.modalStore.closeModal();
			history.push(`/user/registerSuccess?email=${values.email}`);
		} catch (error) {
			throw error;
		}
	}

	@action updatePassword = async (values: IPassword) => {
		try {
			await agent.User.changePassword(values);
			runInAction('creating organisation', () => {
                this.submitting = false;
                toast.info('password is updated');
            });
		} catch (error) {
            runInAction('create organisations error ', () => {
                this.submitting = false;
				console.log(error);
                // this.errorMessage =error.statusText;
            });
            toast.error('Problem submitting data');
		}
	}

	@action getUser = async () => {
		try {
			const user = await agent.User.currentUser();
			runInAction(() => {
				this.user = user;
				this.rootStore.commonStore.setToken(user.token);
			});
		}
		catch (error) {
			console.log(error);
		}
	}

	@action logout = () => {
		this.rootStore.commonStore.setToken(null);
		this.user = null;
		history.push('/home');
	}

	@action fbLogin = async (response: any) => {
		this.loading = true;
		try {
			const user = await agent.User.fbLogin(response.accessToken);
			console.log(user);
			runInAction(() => {
				this.user = user;
				this.rootStore.commonStore.setToken(user.token);
				this.rootStore.modalStore.closeModal();
				this.loading = false;
				//history.push(`/profile/${user.userName}`);
			});

		} catch (error) {
			this.loading = false;
			throw error;
		}
	}
}

// bob@test.comPa$$w0rd