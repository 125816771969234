import React, { Fragment } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Menu, Dropdown } from 'semantic-ui-react';
import * as Styles from '../styles'
import logo from '../../../assets/images/logo.png';

interface IProp {
    pathName:string,
    children:JSX.Element,
    menuItemClick: ()=>void;
}

const AdminManagerMobile = ({pathName, menuItemClick, children}:IProp) => {
    return (
        <Fragment>
            <Menu.Item header as={NavLink} exact to="/" style={Styles.logoStyleMobile} onClick={menuItemClick} >
                <img src={logo} alt="logo" style={{ margin: '0px', width: '110px' }} />
            </Menu.Item>

            <Menu.Item name="새가족"
                style={(pathName === '/newBelieverCards' 
                || pathName === '/createNewComerMobile'
                || pathName.startsWith('/newComersMobile')
                || pathName.startsWith('/manageNewComerMobile')
                || pathName.startsWith('/createNewBeliever')
                || pathName.startsWith('/manageNewBeliever'))            
                ? Styles.activeMenuItemStyle : Styles.menuItemStyle}>                                    
                <Dropdown pointing="top left" text='새가족' style={{ width: '120px' }}>
                    <Dropdown.Menu>
                        <Dropdown.Item text="새가족 목록" as={Link} to="/newBelieverCards" onClick={menuItemClick}
                            style={pathName === '/newBelieverCards' ? Styles.activeSubMenuItemStyle : Styles.menuItemStyle} />
                        <Dropdown.Item text="새가족방 목록" as={Link} to="/newComersMobile" onClick={menuItemClick}
                            style={(pathName.startsWith('/newComersMobile')
                                    || pathName.startsWith('/manageNewComerMobile')
                                    || pathName.startsWith('/createNewBeliever')
                                    || pathName.startsWith('/manageNewBeliever')                
                            )? Styles.activeSubMenuItemStyle : Styles.menuItemStyle} />
                        <Dropdown.Item text="새가족방 만들기" as={Link} to="/createNewComerMobile" onClick={menuItemClick}
                            style={pathName === '/createNewComerMobile' ? Styles.activeSubMenuItemStyle : Styles.menuItemStyle} />
                    </Dropdown.Menu>
                </Dropdown>
            </Menu.Item>

            <Menu.Item name="Believer"
                style={(pathName === '/believers' || pathName === '/createBeliever'
                    || pathName === '/believerCards'
                    || pathName === '/believerTree'
                    || pathName.startsWith('/assignOrgForm')
                    || pathName.startsWith('/manageBeliever')                    
                ) ? Styles.activeMenuItemStyle : Styles.menuItemStyle}>
                <Dropdown pointing="top left" text='주안 교인' >
                    <Dropdown.Menu>								
                        <Dropdown.Item as={Link} to="/believerTree" text="폴더뷰" />
                        <Dropdown.Item as={Link} to="/believers" text="목록" />
                        <Dropdown.Item as={Link} to="/createBeliever" text="만들기" />
                    </Dropdown.Menu>
                </Dropdown>
            </Menu.Item>
            
        {children}
    </Fragment>
    );
};

export default AdminManagerMobile;