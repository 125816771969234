import React from "react";
import GoogleMapReact from 'google-map-react';
import { Icon } from "semantic-ui-react";

interface IProps {
    latitude: number,
    longitude: number,
    zoom: number,
    height: number
}

// const Marker = ({lat:any,lng:any}) => {
//   const test ={lat,lng}
//   return   <Icon name='marker' size='big' color='red' />
// }  


const JuanMap: React.FC<IProps> = ({ latitude, longitude, zoom, height }) => {

  const renderMarkers = (map:any, maps:any) => {
    let marker = new maps.Marker({
      position: { lat: latitude, lng: longitude },
      map,
      title: 'Sydjney Juan Church'
      });
    return marker;
  };
  
  const defaultProps = {
    center: {
      lat: latitude,
      lng: longitude
    },
    zoom: zoom
  };

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: height, width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyDoCRN6aizONScKTF97q8iDLDFzwrWtZJg" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
      >
      </GoogleMapReact>
    </div>
  );
}

export default JuanMap;



