import React from 'react';
import { List, Image, Popup } from 'semantic-ui-react';
import { IMember } from '../../../app/models/group';

interface IProps {
    members: IMember[];
}

const GroupListItemMembers: React.FC<IProps> = ({ members }) => {
    return (
        <List horizontal>
            {members.map((member) => (
                <List.Item key={member.userName}>
                    <Popup
                        header={member.displayName}
                        trigger={
                            <Image
                                size="mini"
                                circular
                                src={member.image || '/assets/user.png'}
                                bordered />
                        } />
                </List.Item>
            ))}
        </List>
    );
};

export default GroupListItemMembers;
