import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Grid } from 'semantic-ui-react';
import { RootStoreContext } from '../../../app/stores/rootStore';
import NewComerList from './NewComerList';
import { observer } from 'mobx-react-lite';
import { Pagination } from 'semantic-ui-react'
import ListItemPlaceHolder from '../../../app/common/PlaceHolder/ListItemPlaceHolder';
import { isMobilePath } from '../../../app/common/constants/paths';
import { RouteComponentProps } from 'react-router';

const NewComerDashboard = ({ match, history }:RouteComponentProps<any>) => {
    const rootStore = useContext(RootStoreContext);
    const { getNewComers, loadNewComers, currentPage, setCurrentPage, totalPage, loadingInitial } = rootStore.newComerStore;
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if (isMobilePath(match.path)){
            setIsMobile(true);
        }

        loadNewComers();
    }, []);

    const pageChangHandler = (event: React.MouseEvent<HTMLAnchorElement>, data: any) => {
        setCurrentPage(data.activePage);
        loadNewComers();
    }

    return (
        <Grid style={{ marginTop: '15px' }}>
            <Grid.Column width={isMobile ? 16 : 11}>
                {
                    loadingInitial ? <ListItemPlaceHolder /> :
                        (
                            <Fragment>
                                <NewComerList newComers={getNewComers} isMobile={isMobile} />                                
                                <Pagination
                                    firstItem={null}
                                    lastItem={null}
                                    defaultActivePage={currentPage} totalPages={totalPage}
                                    onPageChange={pageChangHandler}
                                />
                            </Fragment>
                        )
                }
            </Grid.Column>
            {
                (!isMobile) && 
                (
                    <Grid.Column width={5}>
                    </Grid.Column>
                )
            }
        </Grid>
    );
};

export default observer(NewComerDashboard);


