import React, { useState, useContext, useEffect, useRef, Fragment } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { RouteComponentProps } from 'react-router-dom';
import { Segment,  Button, Grid, Form as SemanticForm, Item, List, Icon, Label, Table } from 'semantic-ui-react';
import 'suneditor/dist/css/suneditor.min.css';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { AssignForm } from '../../../app/models/believer';
import Kakaotalk from '../../../assets/images/kakaotalk.png';
import { ImagePaths } from '../../../app/common/constants/imagePaths';
import { format } from 'date-fns';
import SearchField from '../../../app/common/search/SearchField';
import CheckBoxField from '../../../app/common/formikForm/CheckBoxField';
import ConfirmationModal from '../../../app/common/modals/ConfirmationModal';
import BasicErrorMessage from '../../../app/common/form/BasicErrorMessage';

const validationSchema = Yup.object({
    name:Yup.string().required('name is required').max(50, 'max length is 100 characters'),
    phone:Yup.string().required('phone is required').max(100, 'max length is 100 characters'),
    description:Yup.string().required('description is required').max(2000, 'max length is 2000 characters'),
    guider:Yup.string().max(50, 'max length is 100 characters')
});

interface iFormParams {
    believerId: string;
};

const AssignOrgForm: React.FC<RouteComponentProps<iFormParams>> = ({ match, history }) => {

    const rootStore = useContext(RootStoreContext);
    const {  submitting, loadBeliever, believer, assign, unassign, errorMessage, getBelieverOrganisations } = rootStore.believerStore;
    const {   getDropDownlOrganisations, loadDropDownlOrganisations } = rootStore.organisationStore;
    const organisationRef = useRef<any | null>(null);

    const { openModal } = rootStore.modalStore;

    const [isLeader, setIsLeader] = useState(false);
    const [assignForm, setAssignForm] = useState(new AssignForm());

    useEffect(()=>{   
        if (match.params.believerId) {
            loadBeliever(match.params.believerId)
                .then((believer) => {
                    loadDropDownlOrganisations();
                    if (believer.birthday){
                        believer.birthday = new Date(believer.birthday);
                    }
                })
                .finally();
        }
    },[]);

    const handleFormSubmit =(assignForm:any)=>{
        if (organisationRef && organisationRef &&  organisationRef.current ){
            let organisationName = organisationRef.current.state!.value;

            var item = getDropDownlOrganisations.find(item => item.name === organisationName);

            if (item){
                let formData = new FormData();
                formData.append('believerId', believer!.id);
                formData.append('isLeader', isLeader.toString());
                formData.append('organisationId', item.id);
                
                assign(believer!.id, formData);   
            }
        }
    }

    const UnassignHandler = (id :string) =>{
        let formData = new FormData();
        formData.append('believerId', believer!.id);
        formData.append('organisationId', id);        
        unassign( believer!.id, formData);
    }

    const handleCheckBoxClick = (event: React.ChangeEvent<HTMLInputElement>)=> {
        if (event.target.name ==='isLeader'){
            setIsLeader(event.target.checked)
        }
    }

return (<Grid style={{ marginTop: '15px' }}>
        <Grid.Column width={8}>
            <Segment.Group>
                {
                    believer && 
                    (
                    <Segment>
                            <SemanticForm.Field>
                                <BasicErrorMessage content= {errorMessage} />       
                            </SemanticForm.Field>   

                            <Item.Group>
                            <Item>
        
                            <Item.Image avatar size="tiny" src={believer.url || ImagePaths.defaultUser} />
                                <Item.Content>
                                    <Item.Header>
                                        {believer.name} 
                                    </Item.Header>
                                    <Item.Description>                                 
                                        <List horizontal link>
                                            <List.Item><Icon name='phone' />{believer.phone}</List.Item>
        
                                            {
                                                believer.kakaotalkId && 
                                                (
                                                    <List.Item>
                                                        <img src={Kakaotalk} style={{ marginRight:'8px',  width: '17px' }} />   
                                                        {believer.kakaotalkId}
                                                    </List.Item>
                                                )
                                            }
                                            
                                            {
                                                believer.birthday && 
                                                (<List.Item>                    
                                                    <Icon name='birthday' />
                                                        {format(new Date(believer.birthday),'dd MMM yyyy')}
                                                </List.Item>
                                                )
                                            }
                                        </List>              
                                    </Item.Description>
                                </Item.Content>
                                </Item>
                            </Item.Group>
                        </Segment>
                    )
                }
                <Segment style={{marginBottom:30}}>
                    <Formik enableReinitialize validationSchema = {validationSchema}
                    onSubmit={values=> handleFormSubmit(values)}
                    initialValues={assignForm}>
                    {( { values, handleSubmit, isValid, isSubmitting, dirty})=>(
                        <Form className='ui form' onSubmit={handleSubmit} autoComplete ='off'>
                              <Label><Icon name='folder open outline' size='big' /> Search and Save Church</Label>
                            {    ( getDropDownlOrganisations && getDropDownlOrganisations.length > 0) 
                                    && (
                                    <Fragment>
                                    <SemanticForm.Field>
                                        <SearchField 
                                            source = {getDropDownlOrganisations}
                                            intialSearchValue =''
                                            forwardedRef = {organisationRef}
                                            name ='organisationName'
                                        />       
                                    </SemanticForm.Field>
                                    <CheckBoxField text='IsLeader' name='isLeader'                                 
                                        onChange={(e)=>handleCheckBoxClick(e)}
                                        checked={isLeader===true} disabled={false}/>                               
                                    </Fragment>) }
                            <Button
                                onClick={(e)=>handleFormSubmit(e)}
                                floated="right"
                                loading={submitting}
                                disabled={submitting }
                                positive
                                type="submit"
                                content="Save" />
                            <Button
                                onClick={() => history.push('/believers')}
                                floated="right"
                                type="button"
                                content="Cancel" />
                        </Form>
                    )}
                    </Formik>
                </Segment>
            </Segment.Group>
        </Grid.Column>
        <Grid.Column width={8}>
            <Table celled>
                <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>섬기는 교회, 부서</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
                </Table.Header>
                <Table.Body>
                {
                    getBelieverOrganisations.map(organisation=>(
                        <Table.Row>
                            <Table.Cell>{organisation.name}</Table.Cell>
                            <Table.Cell>
                                <Button floated="right" content="Delete" color="red"
                                    onClick={() => openModal(<ConfirmationModal
                                        id={organisation.id}
                                        subject={'Delete'+ organisation.name}
                                        message={'Are you sure you want to unassign it?'}
                                        confirmById={UnassignHandler}
                                    />)}
                                />
                            </Table.Cell>
                        </Table.Row>
                    ))
                }
                </Table.Body>
            </Table>
        </Grid.Column>
    </Grid>);
};

export default observer(AssignOrgForm);


/* <Table.Row>
<Table.Cell>
    <Label ribbon>Leader</Label>
</Table.Cell>
<Table.Cell>Cell</Table.Cell>
<Table.Cell>Cell</Table.Cell>
</Table.Row> */
