import { RootStore } from './rootStore';
import { observable, action, computed, runInAction } from 'mobx';
import agent from '../api/agent';
//import { history } from '../..';
import { toast } from 'react-toastify';
import { INewComer } from '../models/newComer';
import { IBeliever } from '../models/believer';

export default class NewComerStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @observable loadingInitial = true;
    @observable submitting = false;
    @observable errorMessage: string = '';

    @observable newComer: INewComer | null = null;
    @observable believerRegistry = new Map();
    @observable newComerRegistry = new Map();
    @observable deleteErrorMessage: string ='';

    // list
    @observable newComerCount = 0;
    @observable currentPage = 1;
    @observable totalPage = 1;
    @observable pageSize: number = 7;

    @computed get getNewComers() {
        if (this.newComerRegistry.size >= 1) {
            return Array.from(this.newComerRegistry.values());
        }
        return [];
    }

    @computed get getBelievers() {
        if (this.believerRegistry.size >= 1) {
            return Array.from(this.believerRegistry.values());
        }
        return [];
    }

    @action setPageSize = (pageSize: number) => {
        this.pageSize = pageSize;
    }

    @action setCurrentPage = (page: number) => {
        this.currentPage = page;
    }

    @action createNewComer = async (formData: FormData) => {
        try {
            runInAction('submitting', () => {
                this.submitting = true;
            });

            var newComer = await agent.NewComers.create(formData);

            runInAction('creating newComer', () => {
                this.newComerRegistry.set(newComer.id, newComer);
                this.submitting = false;
                toast.info(newComer.name + 'is created.');
            });
        } catch (error) {
            runInAction('Problem submitting data', () => {
                this.submitting = false;
            });
            toast.error('Problem submitting data');
        }
    };

    @action editNewComer = async (formData: FormData, id: string) => {
        this.submitting = true;
        try {
            var newComer = await agent.NewComers.update(formData, id);

            runInAction('editing bulletin', () => {
                this.newComerRegistry.set(newComer.id, newComer);
                this.submitting = false;
            });
        } catch (error) {
            runInAction('Problem submitting data ', () => {
                this.submitting = false;
            });
            toast.error('Problem submitting data');
        }
    };

    @action loadNewComers = async () => {
        try {
            const params = new URLSearchParams();
            params.append('currentPage', `${this.currentPage}`);
            params.append('pageSize', `${this.pageSize}`);
            let newComersResourceModel = await agent.NewComers.list(params);

            runInAction('loading newComers', () => {
                if (newComersResourceModel) {
                    this.newComerRegistry.clear();
                    const { currentPage, count, totalPage, data } = newComersResourceModel;
                    data.forEach((newComer: any) => {
                        this.newComerRegistry.set(newComer.id, newComer);
                    });
                    this.currentPage = currentPage;
                    this.totalPage = totalPage;
                    this.newComerCount = count;
                    this.loadingInitial = false;
                }
            });
        } catch (error) {
            runInAction('load newComers error', () => {
                this.loadingInitial = false;
            });
            throw error;
        }
    };

    @action loadNewComer = async (id: string) => {
        try {
            let resourceModel = await agent.NewComers.details(id, false);

            runInAction('loading newComer', () => {
                if (resourceModel){
                    const { newComer } = resourceModel;
                    this.loadingInitial = false;
                    this.errorMessage ='';
                    this.deleteErrorMessage='';    
                    this.newComer = newComer;
                }
            });
            return resourceModel.newComer;

        } catch (error) {
            runInAction('load newComer error', () => {
                this.loadingInitial = false;
            });
            throw error;
        }
    };

    @action loadNewComerDetail = async (id: string) => {
        try {
            let newComerResourceModel = await agent.NewComers.details(id, true);
            
            runInAction('loading newComer', () => {
                if (newComerResourceModel){
                    const { believers, newComer } = newComerResourceModel;

                    this.believerRegistry.clear();
                    this.loadingInitial = false;
                    this.errorMessage ='';
                    this.deleteErrorMessage='';    
                    this.newComer = newComer;
                    believers.forEach((believer: IBeliever) => {
                        this.believerRegistry.set(believer.id, believer);
                    });                    
                }
            });

        } catch (error) {
            runInAction('load newComer error', () => {
                this.loadingInitial = false;
            });
            throw error;
        }
    };

    @action deleteById = async (id: string) => {
        try {
            await agent.NewComers.delete(id);
            runInAction('deleting newComer', () => {
                this.newComerRegistry.delete(id);
            });
        } catch (error) {
            runInAction('delete newComer error', () => {
            });
            console.log(error);
        }
    };
}