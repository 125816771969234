import React, { Fragment, useContext, useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import { Pagination } from 'semantic-ui-react'
import OrganisationListItemPlaceHolder from './OrganisationListItemPlaceHolder';
import OrganisationList from './OrganisationList';
import BasicErrorMessage from '../../../app/common/form/BasicErrorMessage';
import { OrganisationCategories } from '../../../app/common/constants/categories';
import ListFilter from '../../../app/common/filter/ListFilter';

var menuItems=[OrganisationCategories.Active, OrganisationCategories.InActive ];


const OrganisationDashboard: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const { currentPage, setCurrentPage, totalPage, 
        loadingInitial, loadOrganisations, deleteErrorMessage,
        getOrganisations, setCategory, category } = rootStore.organisationStore;

    useEffect(() => {
        loadOrganisations();
    }, []);

    const pageChangHandler = (event: React.MouseEvent<HTMLAnchorElement>, data: any) => {
        setCurrentPage(data.activePage);
        loadOrganisations();
    }

    return (
        <Grid style={{ marginTop: '15px' }}>
            <Grid.Column width={10}>
                {
                    loadingInitial ? <OrganisationListItemPlaceHolder /> :
                        (
                            <Fragment>
                                <BasicErrorMessage content= {deleteErrorMessage} />       
                                <OrganisationList organisations={getOrganisations}/>
                                <Pagination
                                    firstItem={null}
                                    lastItem={null}
                                    activePage={currentPage} totalPages={totalPage}
                                    onPageChange={pageChangHandler}
                                />
                            </Fragment>
                        )
                }
            </Grid.Column>
            <Grid.Column width={6}>
                <ListFilter menuItems={menuItems} currentCategory={category}
                    selectMenuItem={setCategory} name ='타입'/>

            </Grid.Column>
        </Grid>
    );
};

export default observer(OrganisationDashboard);


