import { Form, Formik } from 'formik'
import React, { useContext } from 'react';
import { Button, Grid, Header, Segment, Form as SemanticForm } from 'semantic-ui-react';
import TextInputField from '../../app/common/formikForm/TextInputField';
import * as Yup from 'yup';
import { RootStoreContext } from '../../app/stores/rootStore';
import { ChangePasswordFormValues, IPassword } from '../../app/models/user';
import { observer } from 'mobx-react-lite';
import BasicErrorMessage from '../../app/common/form/BasicErrorMessage';

const validationObject =Yup.object({
	currentPassword :Yup.string().required('The currentPassword is required.'),
	newPassword: Yup.string().required('The Password is required'),
	confirmPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'The Confirm Password do not match')
})

const ChangePassword = () => {
    const rootStore = useContext(RootStoreContext);
	const { updatePassword, errorMessage } = rootStore.userStore;

	const handleFormSubmit = (password: any) => {
		updatePassword(password);
	}

    return (<Grid style={{ marginTop: '15px' }}>
        <Grid.Column width={10}>
			<Header dividing size="large" content="Account" />
			<Header color="teal" sub content="Change Password" />
            <Segment clearing>
                    <Formik enableReinitialize validationSchema = {validationObject}
                    onSubmit={values=> handleFormSubmit(values)}
                    initialValues={ChangePasswordFormValues}>
                    {( { values, handleSubmit, isValid, isSubmitting, dirty})=>(
                        <Form className='ui form' onSubmit={handleSubmit} autoComplete ='off'>
							<SemanticForm.Field>
								<BasicErrorMessage content= {errorMessage} />       
							</SemanticForm.Field>   
							<TextInputField name="currentPassword" type="password" placeholder="Current password" />
							<TextInputField name="newPassword" type="password" placeholder="New password" />
							<TextInputField name="confirmPassword" type="password" placeholder="Confirm password" />
							<Button
								floated="right"
								// loading={submitting}
								disabled={!isValid || isSubmitting }
								positive
								type="submit"
								content="Save" />
                        </Form>
                    )}
                </Formik>
            </Segment>
        </Grid.Column>
    </Grid>);
}

export default observer(ChangePassword);
