import React from 'react';
import { useField } from 'formik';
import { Form, Label, Select } from 'semantic-ui-react';

interface IProps {
    placeholder: string;
    name:string;
    options:any;
    label?:string;
}

export default function SelectInputField(props: IProps){
    const [field, meta, helpers] = useField(props.name)
    return (
        <Form.Field error ={meta.touched && !!meta.error}>
            <label>{props.label}</label>
            <Select clearable 
                options = {props.options}
                value= {field.value || null}
                onChange={(e,d)=>helpers.setValue(d.value)}
                onBlur={()=>helpers.setTouched(true)}
                placeholder={props.placeholder}
                />
            {meta.touched && meta.error ? (
                <Label basic color='red'>{meta.error}</Label>
            ) : null}
        </Form.Field>
    )
}
// return (
//         <Form.Field error={touched && !!error} width={width}>
//             <Select value={input.value} onChange={(e, data) => input.onChange(data.value)}
//                 placeholder={placeholder} options={options} />
//             {touched && error && (
//                 <Label basic color='red'>{error}</Label>
//             )}
//         </Form.Field>
//     )
// }

