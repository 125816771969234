import React, { useState, useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { RouteComponentProps } from 'react-router-dom';
import { Segment,  Button, Grid, Form as SemanticForm } from 'semantic-ui-react';
import { v4 as uuid } from 'uuid';
import 'suneditor/dist/css/suneditor.min.css';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import TextInputField from '../../../app/common/formikForm/TextInputField';
import DateInputField from '../../../app/common/formikForm/DateInputField';
import { NewComerFormValues } from '../../../app/models/newComer';
import TextAreaField from '../../../app/common/formikForm/TextAreaField';
import { isMobilePath } from '../../../app/common/constants/paths';

const validationSchema = Yup.object({
    date:Yup.string().required('Date is required').nullable(),
    name:Yup.string().required('Name is required'),
    week1:Yup.string().nullable().notRequired().max(2000, 'Week1 - max length is 2000 characters'),
    week2:Yup.string().nullable().notRequired().max(2000, 'Week2 - max length is 2000 characters'),
    week3:Yup.string().nullable().notRequired().max(2000, 'Week3 - max length is 2000 characters'),
    week4:Yup.string().nullable().notRequired().max(2000, 'Week4 - max length is 2000 characters'),
    week5:Yup.string().nullable().notRequired().max(2000, 'Week5 - max length is 2000 characters')
});

interface iFormParams {
    id: string;
};


const NewComerForm: React.FC<RouteComponentProps<iFormParams>> = ({ match, history }) => {

    const rootStore = useContext(RootStoreContext);
 
    const { createNewComer, editNewComer, submitting, loadNewComer } = rootStore.newComerStore;

    const [newComer, setNewComer] = useState(new NewComerFormValues());
    const [isMobile, setIsMobile] = useState(false);

    useEffect(()=>{        
        if (isMobilePath(match.path)){
            setIsMobile(true);
        }

        if (match.params.id) {
            loadNewComer(match.params.id)
                .then((newComer) => {
                    newComer.date = new Date(newComer.date);
                    setNewComer(newComer);
                })
                .finally();
        }
    },[]);

    const handleFormSubmit =(newComer:any)=>{
        let formData = new FormData();

        formData.append('name', newComer.name);
        var newComerDate = new Date(newComer.date);
        formData.append('Date', newComerDate.toLocaleDateString("en-AU"));

        formData.append('Week1', newComer.week1 ?? '');
        formData.append('Week2', newComer.week2 ?? '');
        formData.append('Week3', newComer.week3 ?? '');
        formData.append('Week4', newComer.week4 ?? '');
        formData.append('Week5', newComer.week5 ?? '');

        if (!newComer.id) {
            var id = uuid();
            formData.append('Id', id);
            createNewComer(formData).then(()=>{
                if(isMobile){
                    history.push(`/newComersMobile`);
                }else{
                    history.push(`/newComers`);
                }
            })
        } else {
            formData.append('Id', newComer.id);
            editNewComer(formData, newComer.id).then(()=>{
                if(isMobile){
                    history.push(`/newComersMobile`);
                }else{
                    history.push(`/newComers`);
                }                
            });
        }
    }

    return (<Grid style={{ marginTop: '15px' }}>
        <Grid.Column width={isMobile ? 16 : 10}>
            <Segment clearing>
                    <Formik enableReinitialize validationSchema = {validationSchema}
                    onSubmit={values=> handleFormSubmit(values)}
                    initialValues={newComer}>
                    {( { values, handleSubmit, isValid, isSubmitting, dirty})=>(
                        <Form className='ui form' onSubmit={handleSubmit} autoComplete ='off'>
                            <TextInputField type='text' name="name" placeholder="제목"/>   
                            <DateInputField placeholderText='날짜' name ='date'                                 
                                showTimeSelect={false} timeCaption='time' dateFormat='dd MMM yyyy' />

                            <TextAreaField type='textarea' name="week1" placeholder="week1 노트"/>                            
                            <TextAreaField type='textarea' name="week2" placeholder="week2 노트"/>                            
                            <TextAreaField type='textarea' name="week3" placeholder="week3 노트"/>                            
                            <TextAreaField type='textarea' name="week4" placeholder="week4 노트"/>                            
                            <TextAreaField type='textarea' name="week5" placeholder="week5 노트"/>                            

                                <Button
                                    floated="right"
                                    loading={submitting}
                                    disabled={!isValid || isSubmitting }
                                    positive
                                    type="submit"
                                    content="Save" />
                                <Button
                                    onClick={() => isMobile ? history.push('/newComersMobile') :  history.push('/newComers')}
                                    floated="right"
                                    type="button"
                                    content="Cancel" />
                        </Form>
                    )}
                </Formik>
            </Segment>
        </Grid.Column>
    </Grid>);
};

export default observer(NewComerForm);
