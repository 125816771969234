export interface IOrganisation {
    id: string;
    name: string;
    isActive: boolean;
    description: string;
    parentName: string;
    parentId: string;
}

export class OrganisationIntialTreeItemValue {
    id: string= '';
    name: string= ''
    isActive: boolean=false;
    description: string='';
    parentName: string='';
    parentId: string='';
}

export class OrganisationFormValues {
    id?: string = undefined;
    name: string = '';
    isActive: boolean=true;
    description: any = '';
    parentId: string = '';
    parentName:string='';
}

export interface IOrganisationTreeView {
    parents : IOrganisation[];
    children : IOrganisation[];
}

export interface IOrganisationResouceModel {
    data: IOrganisation[];
    count: number;
    currentPage: number;
    totalPage: number;
    pageSize: number;
}