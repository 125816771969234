import React from 'react';
import { AxiosResponse } from 'axios';
import { Message } from 'semantic-ui-react';

interface IProps {
    error: AxiosResponse;
    text?: string;
}

const ErrorMessage: React.FC<IProps> = ({ error, text }) => {
    if (!error)
        return null;
    return (
        <Message error>
            {error && error.statusText}
            {text && <Message.Content content={text} />}
        </Message>
    );
};

export default ErrorMessage;

//<Message.Header>{error && error.statusText}</Message.Header>
// {error.data && Object.keys(error.data.errors).length > 0 && (
//     <Message.List>
//         {Object.values(error.data.errors).flat().map((err, i) => {
//             let text = [err];
//             return <Message.Item key={i}>{text}</Message.Item>
//         })}
//     </Message.List>
// )}
