import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Grid, Icon, Label, List } from 'semantic-ui-react';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import * as Styles from './styles';
import TreeViewPlaceHolder from '../../../app/common/PlaceHolder/TreeViewPlaceHolder';
import TreeItem from '../../../app/common/tree/TreeItem';
import { IOrganisation } from '../../../app/models/organisation';
import GalleryTreeDetail from './GalleryTreeDetail';
import { SortOrder } from '../../../app/common/constants/sortOrder';

const GalleryTree: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const { loadingInitial, loadTreeView, getparents,
        getchildren, setOrganisationTreeItem, idOnTree, cancel } = rootStore.organisationStore;

    const [treeItemSize, setTreeItemSize] = useState(3);
    const [currentOrganisationId, setCurrentOrganisationId] = useState('');

    const { loadGalleries,getGalleries,initGallery,
        currentPage, setCurrentPage, totalPage} = rootStore.galleryStore;

    const pageChangHandler = (event: React.MouseEvent<HTMLAnchorElement>, data: any) => {
        setCurrentPage(data.activePage);
        loadGalleries(currentOrganisationId,SortOrder.NameAsc, '');
    }
        
    useEffect(() => {
        cancel();
        loadTreeView();
        initGallery();
    }, []);

    const zoomIn=()=>{
        if (treeItemSize < 8){
            let zoomedInSize =treeItemSize + 1;
            setTreeItemSize(zoomedInSize);    
        }
    }

    const zoomOut=()=>{
        if (treeItemSize > 1){
            let zoomOutSize =treeItemSize -1;
            setTreeItemSize(zoomOutSize);    
        }
    }

    const selectTreeItemHandler = (organisation:IOrganisation, isBottom:boolean, parentName:string)=>{
        loadGalleries(organisation.id, SortOrder.NameAsc,'');
        setOrganisationTreeItem(organisation, isBottom, parentName);
        setCurrentOrganisationId(organisation.id);
    }

    return (
        <Grid style={{ marginTop: '10px' }}>
            <Grid.Column width={8}>
                {
                    loadingInitial ? <TreeViewPlaceHolder /> :
                        (
                            getparents.length===0 ?
                            (<span>최상위 교회가 없습니다.</span>):
                            (                            
                            <div>
                                <Fragment>
                                    <span style={{cursor:'pointer', paddingRight:'10px' }} onClick={zoomIn}>
                                        <Label size='medium'>
                                            <Icon  name='plus' />
                                            확대
                                        </Label>
                                    </span>
                                    <span style={{cursor:'pointer'}} onClick={zoomOut}>
                                        <Label size='medium'>
                                            <Icon name='minus'/>
                                            축소
                                        </Label>
                                    </span>
                                </Fragment>
                                <List style={Styles.tree}>
                                    {getparents.map((organisation) => {
                                        return <TreeItem key={organisation.id} 
                                            idOnTree={idOnTree}
                                            children={getchildren} 
                                            selectTreeItem={selectTreeItemHandler}
                                            treeItemSize ={treeItemSize}
                                            organisation={organisation} parentName='' 
                                        />
                                    })}
                                </List>
                            </div>)
                        )
                }
            </Grid.Column>
            <Grid.Column width={8}>
                {
                    currentOrganisationId 
                    && (
                        <GalleryTreeDetail 
                            currentPage={currentPage}
                            totalPage={totalPage}
                            pageChangHandler ={pageChangHandler}
                            galleries={getGalleries} />
                    )
                }
            </Grid.Column>
        </Grid>
    );
};

export default observer(GalleryTree);

// https://react.semantic-ui.com/elements/icon/


