import { format } from 'date-fns';
import React, { Fragment } from 'react'
import { Icon, Item, List, Pagination, Segment } from 'semantic-ui-react'
import { ImagePaths } from '../../../app/common/constants/imagePaths';
import TwoColumnTable from '../../../app/common/tables/TwoColumnTable';
import Kakaotalk from '../../../assets/images/kakaotalk.png';

interface IProps {
    believers : any[],
    currentPage:number,
    totalPage:number,
    pageChangHandler:(event: React.MouseEvent<HTMLAnchorElement>, data: any) =>void
}

const BelieverTreeDetail:React.FC<IProps> = ({believers, currentPage, totalPage, pageChangHandler }:IProps) => {

    return(
        <Segment.Group>
            <Segment clearing>
                <TwoColumnTable title='제목'>
                    <Fragment>
                        <Item.Group>
                        {believers.map((believer) => {
                            return (<Item>
                                <Item.Image avatar size="tiny" src={believer.url || ImagePaths.defaultUser} />
                                <Item.Content>
                                    <Item.Header>
                                        {believer.name} 
                                    </Item.Header>
                                    <Item.Description>                                 
                                        <List horizontal link>
                                            <List.Item><Icon name='phone' />{believer.phone}</List.Item>        
                                            {
                                                believer.kakaotalkId && 
                                                (
                                                    <List.Item>
                                                        <img src={Kakaotalk} style={{ marginRight:'8px',  width: '17px' }} />   
                                                        {believer.kakaotalkId}
                                                    </List.Item>
                                                )
                                            }                                            
                                            {
                                                believer.birthday && 
                                                (<List.Item>                    
                                                    <Icon name='birthday' />
                                                        {format(new Date(believer.birthday),'dd MMM yyyy')}
                                                </List.Item>
                                                )
                                            }
                                        </List>              
                                    </Item.Description>
                                </Item.Content>
                            </Item>)
                        })}
                        </Item.Group>
                    </Fragment>
                </TwoColumnTable>
                <Pagination
                    firstItem={null}
                    lastItem={null}
                    defaultActivePage={currentPage} totalPages={totalPage}
                    onPageChange={pageChangHandler}
                />
            </Segment>
        </Segment.Group>
    )
}

export default BelieverTreeDetail;


//  <Statistic.Group width={2}>
// <Statistic label='사진 ' value ={} size='small' />
// <Statistic label='기도 ' value ={} size='small'/>
// </Statistic.Group> 

