import React, { useContext, useEffect, useState } from 'react';
import { Grid, Button } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { NavLink } from 'react-router-dom';
import GroupListItemPlaceholder from '../../group/dashboard/GroupListItemPlaceholder';
import InfiniteScroll from 'react-infinite-scroller';
import ReportList from './ReportList';
import ReportFilters from './ReportFilters';

const ReportDashboard: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const { loadReports, loadingInitial, setPage, page, totalPages } = rootStore.reportStore;

    const [loadingNext, setLoadingNext] = useState(false);

    const handleGetNext = () => {
        setLoadingNext(true);
        setPage(page + 1);
        loadReports().then(() => setLoadingNext(false));
    };

    useEffect(() => {
        loadReports();
    }, [loadReports]);

    return (
        <Grid>
            <Grid.Column width={10}>
                {loadingInitial ? (
                    <GroupListItemPlaceholder />
                ) : (
                        <InfiniteScroll
                            pageStart={0}
                            loadMore={handleGetNext}
                            hasMore={!loadingNext && page + 1 < totalPages}
                            initialLoad={false}>
                            <ReportList />
                        </InfiniteScroll>
                    )}
            </Grid.Column>
            <Grid.Column width={6}>
                <Button as={NavLink} to="/createReport" primary content="Create a report" />
                <ReportFilters />
            </Grid.Column>
        </Grid>
    );
};

export default observer(ReportDashboard);
