export interface IBulletin {
    id: string;
    category: string;
    date: Date;
    title: string;
    filePath: string;
    photos: IBulletinPhoto;
}

export interface IBulletinPhoto {
    id: string;
    url: string;
    publicId: string;
    title: string;
    order: number;
}

export class BulletinPhotoFormValues {
    id?: string = undefined;
    url: string = '';
    publicId: string = '';
    title: string = '';
    order: number = 0;
}

export class BulletinFormValues {
    id?: string = undefined;
    category: string = '';
    date?: Date = undefined;
    title: string = '';
    image: Blob | null = null;
    filePath: string = '';
}


export interface IBulletinResouceModel {
    data: IBulletin[];
    count: number;
    currentPage: number;
    totalPage: number;
    pageSize: number;
}