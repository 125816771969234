import React, { useContext, Fragment, useState } from 'react';
import {
	Menu, Container, Icon, Sidebar, Segment, Dropdown, Image, Button } from 'semantic-ui-react';
import { RootStoreContext } from '../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import NoRoleMobile from './role/NoRoleMobile';
import * as ROLES from '../../app/common/constants/roles';
import NewComerManagerMobile from './role/NewComerManagerMobile';
import { Link } from 'react-router-dom';
import AdminManagerMobile from './role/AdminManagerMobile';

interface IProps {
	pathName: string;
	children: any;
}

const NavBarMobile: React.FC<IProps> = ({ pathName, children }) => {
	const rootStore = useContext(RootStoreContext);
	const { user, logout } = rootStore.userStore;
	const [sidebarOpened, setSidebarOpened] = useState(false);

	const handleSidebarHide = () => setSidebarOpened(false);
	const sideBarIconClicked = () => setSidebarOpened(true);

	const menuItemClickedHandler = () => {
		setSidebarOpened(false);
	}

	return (
		<Fragment>
			<Sidebar.Pushable>
				<Sidebar as={Menu}
					animation='overlay'
					onHide={handleSidebarHide}
					vertical
					visible={sidebarOpened}>
					{( (!user || ( user && (user.role === ROLES.ROLE_MEMBER 
											|| user.role === ROLES.ROLE_APP_MANAGER											
											))
						|| ( user && !user.role)
					) && (
						<NoRoleMobile pathName={pathName} menuItemClick={menuItemClickedHandler}>
							<Fragment></Fragment>
						</NoRoleMobile>
					))}
					{(user && user.role === ROLES.ROLE_ADMIN) && (
						<AdminManagerMobile pathName={pathName} menuItemClick={menuItemClickedHandler}>
							<Fragment></Fragment>
						</AdminManagerMobile>
					)}
					{(user && user.role === ROLES.NEW_BELIEVER_MANAGER) && (
						<NewComerManagerMobile pathName={pathName} menuItemClick={menuItemClickedHandler}>
							<Fragment></Fragment>
						</NewComerManagerMobile>
					)}
				</Sidebar>
				<Sidebar.Pusher dimmed={sidebarOpened}>
					<Segment
						textAlign='center'
						style={{ minHeight: '500px' }}
						vertical>
						<Container>
							<Menu pointing secondary size='large'>
								<Menu.Item onClick={sideBarIconClicked}>
									<Icon name='sidebar' size="large" />
								</Menu.Item>
								{ (!user && (pathName ==='/'  || pathName ==='/home' )) && (
									<Menu.Item position="right">
										<Button as={Link} to='/login' 
											basic color='blue' content='Login'size='mini'/>
									</Menu.Item>
								)}

								{user && (
									<Menu.Item position="right">
										<Image avatar spaced="right" src={user.image || '/assets/user.png'} />
										<Dropdown pointing="top left" text={user.displayName}>
											<Dropdown.Menu>
												<Dropdown.Item onClick={logout} text="Logout" icon="power" />
											</Dropdown.Menu>
										</Dropdown>
									</Menu.Item>
								)}
							</Menu>
						</Container>
						{children}
					</Segment>
				</Sidebar.Pusher>
			</Sidebar.Pushable>
		</Fragment >
	);
};

export default observer(NavBarMobile);


/*

<Dropdown.Item as={Link} to={`/profile/${user.userName}`} text="My profile" icon="user" />

	<Menu.Item name="Chatswood" as={NavLink} to="/chatswood" onClick={menuItemClicked} />
<Dropdown.Item as={Link} to="/video_intro" text="소개" onClick={menuItemClicked} /> */
