import React, { useContext, Fragment } from 'react';
import { observer } from 'mobx-react-lite';
import GroupListItem from './GroupListItem';
import { RootStoreContext } from '../../../app/stores/rootStore';

const GroupList: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const { getGroupByTitle } = rootStore.groupStore;

    return (
        <Fragment>
            {getGroupByTitle.map((group) => {
                return <GroupListItem key={group.id} group={group} />
            })}
        </Fragment>
    );
};

export default observer(GroupList);
