import React, { Fragment } from 'react';
import { observer } from 'mobx-react-lite';
import GalleryListItem from './GalleryListItem';
import { IGallery } from '../../../app/models/gallery';

const GalleryList: React.FC<{ galleries : IGallery[] }> = ({galleries}) => {

    return (
        <Fragment>
            {galleries.map((gallery) => {
                return <GalleryListItem key={gallery.id} gallery={gallery} />
            })}
        </Fragment>
    );
};

export default observer(GalleryList);