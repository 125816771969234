import React, { Fragment } from 'react';
import { Item, Button, Segment, List, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { IBeliever } from '../../../app/models/believer';
import ConfirmationModal from '../../../app/common/modals/ConfirmationModal';
import { ImagePaths } from '../../../app/common/constants/imagePaths';
import Kakaotalk from '../../../assets/images/kakaotalk.png';
import {format} from 'date-fns';
import { BelieverStatus } from '../../../app/common/constants/believerStatus';

interface IProps {
    believer: IBeliever,
    openModal:(modal:any) =>void,
    deleteById:(id: string) =>void,
    promoteById:(id: string, newComerId:string) =>void,
    isMobile:boolean
}

const NewBelieverListItem: React.FC<IProps> = ({believer, openModal, deleteById, promoteById, isMobile}) => {
    const confirmHandler=(id:string)=>{
        promoteById(believer.id, believer.newComerId);
    }

    return (
        <Segment.Group>
            <Segment>
                <Item.Group>
                    <Item>
                        <Item.Image avatar size="tiny" src={believer.url || ImagePaths.defaultUser} />
                        <Item.Content>
                            <Item.Header>
                                {believer.name} 
                            </Item.Header>
                            <Item.Description>                                 
                                <List horizontal link>
                                    <List.Item><Icon name='phone' />{believer.phone}</List.Item>

                                    {
                                        believer.kakaotalkId && 
                                        (
                                            <List.Item>
                                                <img src={Kakaotalk} style={{ marginRight:'8px',  width: '17px' }} />   
                                                {believer.kakaotalkId}
                                            </List.Item>
                                        )
                                    }
                                    
                                    {
                                        believer.birthday && 
                                        (<List.Item>                    
                                            <Icon name='birthday' />
                                                {format(new Date(believer.birthday),'dd MMM yyyy')}
                                        </List.Item>
                                        )
                                    }
                                </List>              
                            </Item.Description>
                        </Item.Content>
                    </Item>
                </Item.Group>
            </Segment>
            <Segment clearing>                
                <Fragment>
                    {
                        believer.status === BelieverStatus.Regular ? 
                        (<span>주안 교인</span>)
                        :(
                            <Fragment>
                                <Button floated="right" content="Delete" color="red"
                                    onClick={() => openModal(<ConfirmationModal
                                        id={believer.id}
                                        subject={'Delete '+ believer.name}
                                        message={'Are you sure you want to delete it?'}
                                        confirmById={deleteById}
                                    />)}
                                />

                                {
                                    isMobile ? 
                                    <Button as={Link} to={`/manageNewBelieverMobile/${believer.newComerId}/${believer.id}`}                     
                                    floated="right" content="Edit" color="blue" />
                                    :
                                    <Button as={Link} to={`/manageNewBeliever/${believer.newComerId}/${believer.id}`}                     
                                    floated="right" content="Edit" color="blue" />
                                }

                                <Button floated="right" content="Promote" color="green"
                                    onClick={() => openModal(<ConfirmationModal
                                        id={believer.id}
                                        subject={'Promote '+ believer.name}
                                        message={'Are you sure you want to promote it?'}
                                        confirmById={confirmHandler}
                                    />)}
                                />
                            </Fragment>
                        )
                    }
                </Fragment>                
            </Segment>
        </Segment.Group>
    );
};

export default NewBelieverListItem;