import React, { Fragment } from 'react';
import { Item, Button, Segment, List, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { IBeliever } from '../../../app/models/believer';
import ConfirmationModal from '../../../app/common/modals/ConfirmationModal';
import { ImagePaths } from '../../../app/common/constants/imagePaths';
import Kakaotalk from '../../../assets/images/kakaotalk.png';
import {format} from 'date-fns';

interface IProps {
    believer: IBeliever,
    openModal:(modal:any) =>void,
    deleteById:(id: string) =>void
}


const BelieverListItem: React.FC<IProps> = ({believer, openModal, deleteById}) => {
    return (
        <Segment.Group>
            <Segment>
                <Item.Group>
                    <Item>
                        <Item.Image avatar size="tiny" src={believer.url || ImagePaths.defaultUser} />
                        <Item.Content>
                            <Item.Header>
                                {believer.name} 
                            </Item.Header>
                            <Item.Description>                                 
                                <List horizontal link>
                                    <List.Item><Icon name='phone' />{believer.phone}</List.Item>

                                    {
                                        believer.kakaotalkId && 
                                        (
                                            <List.Item>
                                                <img src={Kakaotalk} style={{ marginRight:'8px',  width: '17px' }} />   
                                                {believer.kakaotalkId}
                                            </List.Item>
                                        )
                                    }
                                    
                                    {
                                        believer.birthday && 
                                        (<List.Item>                    
                                            <Icon name='birthday' />
                                                {format(new Date(believer.birthday),'dd MMM yyyy')}
                                        </List.Item>
                                        )
                                    }
                                </List>              
                            </Item.Description>
                        </Item.Content>
                    </Item>
                </Item.Group>
            </Segment>
            <Segment clearing>
                <Fragment>
                        <Button floated="right" content="Delete" color="red"
                            onClick={() => openModal(<ConfirmationModal
                                id={believer.id}
                                subject={'Delete'+ believer.name}
                                message={'Are you sure you want to delete it?'}
                                confirmById={deleteById}
                            />)}
                        />
                        <Button as={Link} to={`/manageBeliever/${believer.id}`} floated="right" content="Edit" color="blue" />
                        <Button as={Link} to={`/assignOrgForm/${believer.id}`} floated="right" content="Edit 교회" 
                            color="green" />
                    </Fragment>                
            </Segment>
        </Segment.Group>
    );
};

export default BelieverListItem;