import React, { Fragment, useContext, useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import { RootStoreContext } from '../../../app/stores/rootStore';
import HelpList from './HelpList';
import { observer } from 'mobx-react-lite';
import { Pagination } from 'semantic-ui-react'
import ListItemPlaceHolder from '../../../app/common/PlaceHolder/ListItemPlaceHolder';

const HelpDashboard: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const { getHelps, loadHelps, currentPage, setCurrentPage, totalPage, loadingInitial } = rootStore.helpStore;

    useEffect(() => {
        loadHelps();
    }, [loadHelps]);

    const pageChangHandler = (event: React.MouseEvent<HTMLAnchorElement>, data: any) => {
        setCurrentPage(data.activePage);
        loadHelps();
    }

    return (
        <Grid style={{ marginTop: '15px' }}>
            <Grid.Column width={10}>
                {
                    loadingInitial ? <ListItemPlaceHolder /> :
                        (
                            <Fragment>
                                <HelpList helps={getHelps} />
                                <Pagination
                                    firstItem={null}
                                    lastItem={null}
                                    defaultActivePage={currentPage} totalPages={totalPage}
                                    onPageChange={pageChangHandler}
                                />
                            </Fragment>
                        )
                }
            </Grid.Column>
            <Grid.Column width={6}>
            </Grid.Column>
        </Grid>
    );
};

export default observer(HelpDashboard);


