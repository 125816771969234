import React, { useState, useContext, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { RouteComponentProps } from 'react-router-dom';
import { Segment,  Button, Grid, Form as SemanticForm } from 'semantic-ui-react';
import { v4 as uuid } from 'uuid';
import { Formik, Form } from 'formik';
import { GalleryFormValues } from '../../../app/models/gallery';
import TextInputField from '../../../app/common/formikForm/TextInputField';
import DateInputField from '../../../app/common/formikForm/DateInputField';
import TextAreaField from '../../../app/common/formikForm/TextAreaField';
import BasicErrorMessage from '../../../app/common/form/BasicErrorMessage';
import SearchField from '../../../app/common/search/SearchField';

// const validationSchema = Yup.object({
//     title:Yup.string().required('title is required').max(100, 'max length is 100 characters'),
//     date:Yup.string().required('Date is required').nullable()
// })

interface iFormParams {
    id: string;
}

const GalleryForm: React.FC<RouteComponentProps<iFormParams>> = ({ match, history }) => {

    const rootStore = useContext(RootStoreContext);
    const { getDropDownlOrganisations, loadDropDownlOrganisations } =  rootStore.organisationStore;
 
    const { createGallery, editGallery, loadGallery, submitting } = rootStore.galleryStore;
     
    const [gallery, setGallery] = useState(new GalleryFormValues());
    const [intialSearchValue, setIntialSearchValue] = useState<string>('');

    const [searchFormError, setSearchFormError] = useState<string>('');

    const organisationRef = useRef<any | null>(null);

    useEffect(()=>{
        loadDropDownlOrganisations();
    
        if (match.params.id) {
            loadGallery(match.params.id)
                .then((gallery) => {
                    gallery.date = new Date(gallery.date);
                    setGallery(gallery);
                    setIntialSearchValue(gallery.organisation.name); 
                })
                .finally();
        }
    },[match.params.id, loadGallery, loadDropDownlOrganisations]);

    const handleFormSubmit =(gallery:any)=>{
        if (organisationRef && organisationRef.current ){
            let organisationName =organisationRef.current.state!.value;
            let organisation = getDropDownlOrganisations.find(x => x.name === organisationName);        

            if (!gallery.title)
                setSearchFormError('title is required.');   
            else if (!gallery.date)
                setSearchFormError('date is required.');                   
            else if (gallery.title.length > 100)
                setSearchFormError('max length is 100 characters');   
            else if (organisation){
                gallery.organisationId = organisation.id;
                if (!gallery.id) {
                    gallery.id = uuid();
                    createGallery(gallery);

                } else {
                    editGallery(gallery);
                }
            }
            else if (!organisation){
                setSearchFormError('존재하지 않는 교회입니다.');   
            }
        }
    }

    return (<Grid style={{ marginTop: '15px' }}>
        <Grid.Column width={10}>
        {
                getDropDownlOrganisations.length < 1 ? 
                (<p>사진첩을 나눌 수 있는 교회를 만드세요.</p>) : 
                (<Segment clearing>
                <Formik enableReinitialize
                    onSubmit={values=> handleFormSubmit(values)}
                    initialValues={gallery}>
                    {( { handleSubmit, isValid, isSubmitting, dirty })=>(
                        <Form className='ui form' onSubmit={handleSubmit} autoComplete ='off'>
                            <SemanticForm.Field>
                                <BasicErrorMessage content= {searchFormError} />       
                            </SemanticForm.Field>   
                            {( getDropDownlOrganisations && getDropDownlOrganisations.length > 0 )
                            
                            && ((!match.params.id) || (match.params.id && intialSearchValue))
                            && ( 
                                    <SemanticForm.Field>
                                        <SearchField
                                            source = {getDropDownlOrganisations}
                                            intialSearchValue ={intialSearchValue}
                                            forwardedRef = {organisationRef}
                                            name ='organisationName'
                                        />       
                                    </SemanticForm.Field>) }


                            <TextInputField type='text' name="title" placeholder="제목"/>   
                            <DateInputField placeholderText='날짜' name ='date'                                 
                                showTimeSelect={false} timeCaption='time' 
                                dateFormat='dd MMM yyyy' />
                            <TextAreaField type='textarea' name="description" placeholder=""/>                            

                              <Button
                                    floated="right"
                                    loading={submitting}
                                    // disabled={!isValid || isSubmitting }
                                    positive
                                    type="submit"
                                    content="Save"
                                />
                                <Button
                                    onClick={() => history.push('/galleryDashboard')}
                                    floated="right"
                                    type="button"
                                    content="Cancel"
                                />
                        </Form>
                    )}
                </Formik>
            </Segment>)}
        </Grid.Column>
    </Grid>);
};

export default observer(GalleryForm);



// https://openbase.com/js/suneditor-react
// suneditor setOptions
// http://suneditor.com/sample/html/examples.html


//* Show Date&Time == October 14, 2021 2:30 PM 
//<DateInputField placeholderText='날짜' name ='date' 
//    showTimeSelect timeCaption='time' dateFormat='MMMM d, yyyy h:mm aa'
//    /> */}


// export class GalleryFormValues {
//     id?: string = undefined;
//     title: string = '';
//     date?: Date = undefined;
//     description: any = '';
//     filePath: string = '';
//     image: Blob | null = null;
//     galleryId:string='';
// }
 