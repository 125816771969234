import { RootStore } from './rootStore';
import { observable, action, runInAction, computed, reaction } from 'mobx';
import { IProfile, IPhoto } from '../models/profile';
import agent from '../api/agent';
import { toast } from 'react-toastify';
import { IReport } from '../models/report';

export default class ProfileStore {
	rootStore: RootStore;
	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;

		reaction(
			() => this.activeTab,
			(activeTab) => {
				if (activeTab === 3) {
					console.log('active tab 3');
					// load data
				}
			}
		);
	}

	@observable profile: IProfile | null = null;
	@observable loadingProfile = true;
	@observable uploadingPhoto = false;
	@observable loading = false;
	@observable activeTab: number = 0;

	@action setActiveTab = (activeIndex: number) => {
		this.activeTab = activeIndex;
	};

	@computed get isCurrentUser() {
		if (this.rootStore.userStore.user && this.profile) {
			return this.rootStore.userStore.user.userName === this.profile.userName;
		}
		return false;
	}

	@action loadProfile = async (userName: string) => {
		this.loadingProfile = true;
		try {
			const profileResourceModel = await agent.Profiles.get(userName);
			const { profile } = profileResourceModel;

			runInAction(() => {
				this.profile = profile;
				this.loadingProfile = false;
			});
		} catch (error) {
			runInAction(() => {
				this.loadingProfile = false;
			});
			console.log(error);
		}
	};

	@action updateProfile = async (profile: Partial<IProfile>) => {
		try {
			await agent.Profiles.updateProfile(profile);
			runInAction(() => {
				if (profile.displayName !== this.rootStore.userStore.user!.displayName) {
					this.rootStore.userStore.user!.displayName = profile.displayName!;
				}
				this.profile = { ...this.profile!, ...profile };
			});
		} catch (error) {
			toast.error('Problem updating profile');
		}
	};

	@action addReport = async (report: IReport) => {
		try {
			await agent.Reports.create(report);
			runInAction(() => {

			});

		} catch (error) {
			toast.error('Problem updating profile');
		}
	};


	@action uploadPhoto = async (file: Blob) => {
		this.uploadingPhoto = true;
		try {
			const photo = await agent.Profiles.uploadPhoto(file);
			runInAction(() => {
				if (this.profile) {
					this.profile.photos.push(photo);
					if (photo.isMain && this.rootStore.userStore.user) {
						this.rootStore.userStore.user.image = photo.url;
						this.profile.image = photo.url;
					}
				}
				this.uploadingPhoto = false;
			});
		} catch (error) {
			console.log(error);
			toast.error('Problem uploading photo');
			runInAction(() => {
				this.uploadingPhoto = false;
			});
		}
	};

	@action setMainPhoto = async (photo: IPhoto) => {
		this.loading = true;
		try {
			await agent.Profiles.setMainPhoto(photo.id);
			runInAction(() => {
				this.rootStore.userStore.user!.image = photo.url;
				this.profile!.photos.find((a) => a.isMain)!.isMain = false;
				this.profile!.photos.find((a) => a.id === photo.id)!.isMain = true;
				this.profile!.image = photo.url;
				this.loading = false;
			});
		} catch (error) {
			toast.error('Problem setting photo as main');
			runInAction(() => {
				this.loading = false;
			});
		}
	};

	@action deletePhoto = async (photo: IPhoto) => {
		this.loading = true;
		try {
			await agent.Profiles.deletePhoto(photo.id);
			runInAction(() => {
				this.profile!.photos = this.profile!.photos.filter((a) => a.id !== photo.id);
				this.loading = false;
			});
		} catch (error) {
			toast.error('Problem deleting the photo');
			runInAction(() => {
				this.loading = false;
			});
		}
	};
}
