import React, { useContext, useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import { RouteComponentProps } from 'react-router';
import { RootStoreContext } from '../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import ProfileHeader from './ProfileHeader';
import ProfileContent from './ProfileContent';
import LoadingComponent from '../../app/layout/LoadingComponent';

interface RouteParams {
	userName: string;
}

interface IProps extends RouteComponentProps<RouteParams> { }

const ProfilePage: React.FC<IProps> = ({ match }) => {
	const rootStore = useContext(RootStoreContext);
	const {
		loadingProfile,
		profile,
		loadProfile,
		isCurrentUser,
		loading,
		setActiveTab
	} = rootStore.profileStore;

	useEffect(() => {
		loadProfile(match.params.userName);
	}, [loadProfile, match.params.userName]);

	if (loadingProfile) return <LoadingComponent content="Loading profile..." />;

	return (
		<Grid>
			<Grid.Column width={16}>
				<ProfileHeader
					profile={profile!}
					isCurrentUser={isCurrentUser}
					loading={loading}
				/>
				<ProfileContent setActiveTab={setActiveTab} />
			</Grid.Column>
		</Grid>
	);
};

export default observer(ProfilePage);
