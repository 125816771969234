import React, { Fragment, useContext, useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import { RootStoreContext } from '../../../app/stores/rootStore';
import JuanUserList from './JuanUserList';
import { observer } from 'mobx-react-lite';
import { Pagination } from 'semantic-ui-react'
import ListItemPlaceHolder from '../../../app/common/PlaceHolder/ListItemPlaceHolder';
import ListFilter from '../../../app/common/filter/ListFilter';
import { AllCategory } from '../../../app/common/constants/categories';
import * as Roles from '../../../app/common/constants/roles';

const JuanUserDashboard: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const { getJuanUsers, loadJuanUsers, currentPage, setCurrentPage, totalPage, loadingInitial, category, setCategory } = rootStore.juanUserStore;

    useEffect(() => {
        loadJuanUsers(category);
    }, [loadJuanUsers, category]);

    const pageChangHandler = (event: React.MouseEvent<HTMLAnchorElement>, data: any) => {
        setCurrentPage(data.activePage);
        loadJuanUsers(category);
    }

    var menuItems=[AllCategory, Roles.ROLE_MEMBER,  Roles.NEW_BELIEVER_MANAGER, Roles.ROLE_APP_MANAGER, Roles.ROLE_ADMIN, ];

    return (
        <Grid style={{ marginTop: '15px' }}>
            <Grid.Column width={10}>
                {
                    loadingInitial ? <ListItemPlaceHolder /> :
                        (
                            <Fragment>
                                <JuanUserList juanUsers={getJuanUsers} />
                                <Pagination
                                    firstItem={null}
                                    lastItem={null}
                                    defaultActivePage={currentPage} totalPages={totalPage}
                                    onPageChange={pageChangHandler}
                                />
                            </Fragment>
                        )
                }
            </Grid.Column>
            <Grid.Column width={6}>
                <ListFilter menuItems={menuItems} currentCategory={category}
                    selectMenuItem={setCategory} name='Role'/>

            </Grid.Column>
        </Grid>
    );
};

export default observer(JuanUserDashboard);


